import React, { Component } from 'react';
import { connect } from 'react-redux';
import axios from "axios";
import Cards from '../Cards.js';
import Pagination from "react-js-pagination";
import Chart from "../partials/Chart";
import Dropdown from "../Dropdown";
import ListView from "../ListView";

import {Link, Redirect} from "react-router-dom";
import FormUpload from "../FormUpload";
import Modal from 'react-modal';
import LoadingScreen from '../partials/Loading';
import { fetchStudies } from "../../actions/studies";

import { API } from '../../util/api';

const PER_PAGE = 6;
const modalStyle = {
   width: 25 +"%"
};
const customStyles = {
    content : {
        top                   : '50%',
        left                  : '50%',
        right                 : 'auto',
        bottom                : 'auto',
        marginRight           : '-50%',
        transform             : 'translate(-50%, -50%)'
    }
};

const mapStateToProps = (state) => ({
  user: state.auth.user,
  studies: {
    loading: state.studies.loading,
    loaded: state.studies.loaded,
    list: state.studies.studies
  }
});

class Studies extends Component {

    constructor(props) {
        super(props);

        this.state = {
            released: [],
            pending: [],
            studies: [],
            isLoading: true,
            error: null,
            spacing: "200",
            list: false,
            searchText: "",
            pending_activePage: 1,
            released_activePage: 1,
            activePage: 1,
            paginate: false
        };
    }
    openModal = () => {
        this.setState({ modalIsOpen: true });
    };
    closeModal = () => {
        this.setState({ modalIsOpen: false });
    };
    handlePageChange_pending = (pageNumber) => {
        let pending_activePage = pageNumber;
        this.setState({ pending_activePage }, () => {
            let studies = [...this.state.pend];
            let pend = studies.filter(function (obj) {
                return obj.data_status !== 'Data released for use';
            });
            let startPage = pending_activePage * 2;
            let pending = [];
            pend.forEach((data, i) => {
                if (i > startPage  && i <= startPage + PER_PAGE) {
                    pending.push(data);
                }
            });
            this.setState({ pending }, () => {});
        });
    };
    handlePageChange_released = (pageNumber) => {
        let released_activePage = pageNumber;
        this.setState({ released_activePage }, () => {
            let studies = [...this.state.rel];
            let rel = studies.filter(function (obj) {
                return obj.data_status === 'Data released for use';
            });
            let startPage = released_activePage;
            let released = [];
            rel.forEach((data, i) => {
                if (i > startPage && i <= startPage + PER_PAGE) {
                    released.push(data);
                }
            });
            this.setState({ released }, () => {});
        });
    };
    changeListView = () => {
        this.setState({ list: true });
    };
    changeCardView = () => {
        this.setState({ list: false });
    };
    handleSelectChange = (e) => {
        let studies = this.state.studies;
        let filtered = studies;
        if(e !== null) {
            let searchText = e.value;
            if (searchText) {
                let regex = new RegExp(searchText, 'i');
                filtered = studies.filter(function (obj,i) {
                    if(obj.short_id != null){
                        if (!obj.short_id) {
                            obj.short_id = "";
                        }
                        if (!obj.study_title) {
                            obj.study_title = "";
                        }
                        if (!obj.study_title_short) {
                            obj.study_title_short = "";
                        }
                        if (!obj.program) {
                            obj.program = "";
                        }
                        if (!obj.method_type) {
                            obj.method_type = "";
                        }
                        if (!obj.country) {
                            obj.country = "";
                        }
                        return (
                            obj.data_status.match(regex) ||
                            obj.short_id.match(regex) ||
                            obj.program.match(regex) ||
                            obj.study_title.match(regex) ||
                            obj.study_title_short.match(regex) ||
                            obj.method_type.match(regex) ||
                            obj.country.match(regex)
                        );
                    }
                });
                this.setState({ paginate: false }, () =>{});
            }
        }
        let rel = filtered.filter(function (obj) {
            return obj.data_status === 'Data released for use';
        });
        let pend = filtered.filter(function (obj) {
            return obj.data_status !== 'Data released for use';
        });
        let countries = [];
        let data_s = [];
        let method_types = [];
        filtered.forEach(function (value) {
            if(value.country){
                let country = value.country.split("','");
                country.forEach(function (c) {
                    if (countries.indexOf(c) === -1 && c !== "") {
                        countries.push(c);
                    }
                });
            }
            if(value.method_type) {
                if (method_types.indexOf(value.method_type) === -1) {
                    method_types.push(value.method_type);
                }
            }
            if (data_s.indexOf(value.data_status) === -1) {
                data_s.push(value.data_status);
            }
        });
        this.setState({ method_types, countries, data_s, rel, pend });
        this.setState({ released: rel });
        this.setState({ pending: pend });
    };
    handleChange = (e) => {
        this.setState({isLoading: true});
        let searchText = e.target.value;
        this.setState({searchText: searchText});
        let studies = this.state.studies;
        let filtered = studies;
        filtered = studies.filter(function (obj) {
            let regex = new RegExp(searchText, 'i');
            if (!obj.data_status) {
                obj.data_status = "";
            }
            if (!obj.short_id) {
                obj.short_id = "";
            }
            if (!obj.study_title) {
                obj.study_title = "";
            }
            if (!obj.study_title_short) {
                obj.study_title_short = "";
            }
            if (!obj.program) {
                obj.program = "";
            }
            if (!obj.method_type) {
                obj.method_type = "";
            }
            if (!obj.country) {
                obj.country = "";
            }
            return (
                obj.data_status.match(regex) ||
                obj.short_id.match(regex) ||
                obj.program.match(regex) ||
                obj.study_title.match(regex) ||
                obj.study_title_short.match(regex) ||
                obj.method_type.match(regex) ||
                obj.country.match(regex)
            );
        });
        let rel = filtered.filter(function (obj) {
            return obj.data_status === 'Data released for use';
        });
        let pend = filtered.filter(function (obj) {
            return obj.data_status !== 'Data released for use';
        });
        let countries = [];
        let data_s = [];
        let method_types = [];
        filtered.forEach(function (value) {
            if(value.country){
                let country = value.country.split("','");
                country.forEach(function (c) {
                    if (countries.indexOf(c) === -1 && c !== "") {
                        countries.push(c);
                    }
                });
            }
            if(value.method_type) {
                if (method_types.indexOf(value.method_type) === -1) {
                    method_types.push(value.method_type);
                }
            }
            if (data_s.indexOf(value.data_status) === -1) {
                data_s.push(value.data_status);
            }
        });
        this.setState({ method_types: method_types });
        this.setState({ countries: countries });
        this.setState({ data_s: data_s });
        this.setState({ released: rel });
        this.setState({ pending: pend });
        this.setState({ isLoading: false });
    };
    componentWillUnmount() {
        document.body.classList.remove('studies');
    };
    componentDidMount() {
        document.body.classList.toggle('studies', true);
        this.props.fetchStudies();
        // axios.get(API + "/api/v1/study")
        //     .then((response) => {
        //         let studies = response.data.data;
        //         this.setState({ studies });
        //         studies.forEach((val, i) => {
        //             let ret = [];
        //             let user = "";
        //             axios.get(API +"/api/v1/user/"+ val.user)
        //                 .then((response) => {
        //                     if(response.data.data.first_name !== undefined){
        //                         ret = response.data.data;
        //                         user = ret.first_name +" "+ ret.last_name;
        //                     }else{
        //                         user = "No user listed";
        //                     }
        //                     studies[i].user_name = user;
        //                     this.setState({studies});
        //                 })
        //                 .catch((err) => {
        //                     console.log(err);
        //                 });
        //         });
        //
        //         let rel = studies.filter(function (obj) {
        //             return obj.data_status === 'Data released for use';
        //         });
        //
        //         let pend = studies.filter(function (obj) {
        //             return obj.data_status !== 'Data released for use';
        //         });
        //         this.setState({ pend, rel });
        //         if(this.state.paginate === true){
        //             this.setState({ pending_activePage: 1 }, () => {});
        //             this.setState({ released_activePage: 1 }, () => {});
        //             let startPage = 0;
        //             let pending = [];
        //             let released = [];
        //             pend.forEach((data, i) => {
        //                 if(i >= startPage && i < startPage + PER_PAGE){
        //                     pending.push(data);
        //                 }
        //             });
        //             rel.forEach((data, i) => {
        //                 if(i >= startPage && i < startPage + PER_PAGE){
        //                     released.push(data);
        //                 }
        //             });
        //             this.setState({ released });
        //             this.setState({ pending });
        //         } else {
        //             this.setState({ released: rel });
        //             this.setState({ pending: pend });
        //         }
        //         let countries = [];
        //         let data_s = [];
        //         let method_types = [];
        //         response.data.data.forEach(function (value) {
        //             if(value.country){
        //                 let country = value.country.split("','");
        //                 country.forEach(function (c) {
        //                     if (countries.indexOf(c) === -1 && c !== "") {
        //                         countries.push(c);
        //                     }
        //                 });
        //             }
        //             if (value.method_type) {
        //                 if (method_types.indexOf(value.method_type) === -1) {
        //                     method_types.push(value.method_type);
        //                 }
        //             }
        //             if (data_s.indexOf(value.data_status) === -1) {
        //                 data_s.push(value.data_status);
        //             }
        //         });
        //         this.setState({ method_types: method_types });
        //         this.setState({ countries: countries });
        //         this.setState({ data_s: data_s });
        //         this.setState({ isLoading: false });
        //     })
        //     .catch((err) => {
        //         console.log(err);
        //     });
    };
    componentDidCatch(error, info) {
        this.setState({ error: error });
    }
    render() {
        const { countries, method_types, data_s, released, list, pending, paginate, isLoading, error, rel, pend } = this.state;
        if (error) {
            return <p className="error">{error.message}</p>;
        }

        if (isLoading) return <LoadingScreen interior />;

        if (list !== false) {
            return (
                <div className="studies">
                    <div className="hero-heading-wrapper studies-heading-wrapper">
                        <div className="hero-heading-col">
                            <h1>Studies</h1>
                        </div>
                        <div className="hero-heading-col">
                            <a href={API + "/study/download"} className="add-new-user"> Download</a>
                            <a href={"#"} onClick={this.openModal} className="add-new-user"> Upload</a>
                            <Link to={"/study/edit/add"}
                                  className="button-text-icon add-item icon-left">
                    <span className="plus-wrap">
                    <svg version="1.1" id="Layer_1" x="0px" y="0px" viewBox="0 0 12 12">
                      <g id="Symbols">
                        <g id="buttons_x2F_addItem" transform="translate(-15.000000, -10.000000)">
                          <g id="Group-25" transform="translate(15.000000, 10.000000)">
                            <g id="Path-4">
                              <rect x="5.1" className="st0" width="2" height="12"/>
                            </g>
                            <g id="Path-5">
                              <rect y="5.1" className="st0" width="12" height="2"/>
                            </g>
                          </g>
                        </g>
                      </g>
                      </svg>
                      </span>
                                New Study</Link>
                        </div>
                    </div>
                    {/*<Chart data={released}/>*/}
                    <div className="filter-bar">
                        <Dropdown multi={"false"} data={countries}
                                  type={"Country (" + countries.length + ")"}
                                  parentChange={this.handleSelectChange}/>
                        <Dropdown multi={"false"} data={data_s}
                                  type={"Statuses (" + data_s.length + ")"}
                                  parentChange={this.handleSelectChange}/>
                        <Dropdown multi={"false"} data={method_types}
                                  type={"Method (" + method_types.length + ")"}
                                  parentChange={this.handleSelectChange}/>
                        <input className="search-input"
                               type="text" placeholder="Search"
                               value={this.state.searchText}
                               onChange={this.handleChange}/>
                    </div>
                    <div className="h1 section-heading section-heading-border">{rel.length} Studies
                        released
                        <div className="toggle-view-wrap">
                            <button className="card-toggle-list-view" onClick={this.changeCardView}>
                                <svg width="16px" height="14px" viewBox="0 0 16 14" version="1.1">
                                    <g id="V1-Intake" stroke="none" strokeWidth="1" fill="none"
                                       fillRule="evenodd">
                                        <g id="Intake_1"
                                           transform="translate(-1165.000000, -473.000000)"
                                           fill="#FFFFFF" fillRule="nonzero">
                                            <path
                                                d="M1165,479 L1169,479 L1169,473 L1165,473 L1165,479 Z M1165,487 L1169,487 L1169,481 L1165,481 L1165,487 Z M1171,487 L1175,487 L1175,481 L1171,481 L1171,487 Z M1177,487 L1181,487 L1181,481 L1177,481 L1177,487 Z M1171,479 L1175,479 L1175,473 L1171,473 L1171,479 Z M1177,473 L1177,479 L1181,479 L1181,473 L1177,473 Z"
                                                id="Shape"/>
                                        </g>
                                    </g>
                                </svg>
                            </button>

                            <button className="list-toggle-list-view" onClick={this.changeListView}>
                                <svg width="16px" height="14px" viewBox="0 0 16 14" version="1.1">
                                    <g id="V1-Intake" stroke="none" strokeWidth="1" fill="none"
                                       fillRule="evenodd">
                                        <g id="Intake_1"
                                           transform="translate(-1202.000000, -473.000000)"
                                           fill="#8A95A1" fillRule="nonzero">
                                            <path
                                                d="M1202,483 L1218,483 L1218,481 L1202,481 L1202,483 Z M1202,487 L1218,487 L1218,485 L1202,485 L1202,487 Z M1202,479 L1218,479 L1218,477 L1202,477 L1202,479 Z M1202,473 L1202,475 L1218,475 L1218,473 L1202,473 Z"
                                                id="Shape"/>
                                        </g>
                                    </g>
                                </svg>
                            </button>
                        </div>
                    </div>
                    <ListView data={released} view={"study"} user={this.props.user}/>
                    {paginate ?
                        <Pagination
                            activePage={this.state.released_activePage}
                            itemsCountPerPage={PER_PAGE}
                            totalItemsCount={rel.length}
                            pageRangeDisplayed={rel.length / PER_PAGE}
                            onChange={this.handlePageChange_released}
                        />
                        : null
                    }
                    <div className="h1 section-heading section-heading-border">{pend.length} Studies
                        pending
                    </div>
                    <ListView data={pending} view={"study"} user={this.props.user}/>
                    {paginate ?
                        <Pagination
                            activePage={this.state.pending_activePage}
                            itemsCountPerPage={PER_PAGE}
                            totalItemsCount={pend.length}
                            pageRangeDisplayed={pend.length / PER_PAGE}
                            onChange={this.handlePageChange_pending}
                        />
                        : null
                    }
                </div>
            );
        }
        if (list === false) {
            return (
                <div className="studies">
                    <div className="hero-heading-wrapper studies-heading-wrapper">
                    <div className="hero-heading-col">
                    <h1>Studies</h1>
                    </div>
                    <div className="hero-heading-col">
                    <a href={API + "/study/download"} className="add-new-user"> Download</a>
                    <a href={"#"} onClick={this.openModal} className="add-new-user"> Upload</a>
                    <Link to={"/study/edit/add"} className="button-text-icon add-item icon-left">
                    <span className="plus-wrap">
                    <svg version="1.1" id="Layer_1"  x="0px" y="0px" viewBox="0 0 12 12">
                      <g id="Symbols">
                        <g id="buttons_x2F_addItem" transform="translate(-15.000000, -10.000000)">
                          <g id="Group-25" transform="translate(15.000000, 10.000000)">
                            <g id="Path-4">
                              <rect x="5.1" className="st0" width="2" height="12"/>
                            </g>
                            <g id="Path-5">
                              <rect y="5.1" className="st0" width="12" height="2"/>
                            </g>
                          </g>
                        </g>
                      </g>
                      </svg>
                      </span>
                    New Study</Link>
                    </div>
                    </div>
                    {/*<Chart data={released}/>*/}
                    <div className="filter-bar">
                    <Dropdown multi={"false"} data={countries} type={"Country ("+ countries.length +")"} parentChange={this.handleSelectChange}/>
                    <Dropdown multi={"false"} data={data_s} type={"Statuses ("+ data_s.length +")"} parentChange={this.handleSelectChange}/>
                    <Dropdown multi={"false"} data={method_types} type={"Method ("+ method_types.length +")"} parentChange={this.handleSelectChange}/>
                    <input className="search-input"
                           type="text" placeholder="Search"
                           value={this.state.searchText}
                           onChange={this.handleChange}/>
                           </div>
                    <div className="h1 section-heading section-heading-border">{rel.length} Studies
                        released
                        <div className="toggle-view-wrap">
                            <button className="card-toggle" onClick={this.changeCardView}>
                                <svg width="16px" height="14px" viewBox="0 0 16 14" version="1.1">
                                    <g id="V1-Intake" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                        <g id="Intake_1" transform="translate(-1165.000000, -473.000000)" fill="#FFFFFF" fillRule="nonzero">
                                            <path d="M1165,479 L1169,479 L1169,473 L1165,473 L1165,479 Z M1165,487 L1169,487 L1169,481 L1165,481 L1165,487 Z M1171,487 L1175,487 L1175,481 L1171,481 L1171,487 Z M1177,487 L1181,487 L1181,481 L1177,481 L1177,487 Z M1171,479 L1175,479 L1175,473 L1171,473 L1171,479 Z M1177,473 L1177,479 L1181,479 L1181,473 L1177,473 Z" id="Shape"/>
                                        </g>
                                    </g>
                                </svg>
                            </button>

                            <button className="list-toggle" onClick={this.changeListView}>
                                <svg width="16px" height="14px" viewBox="0 0 16 14" version="1.1">
                                    <g id="V1-Intake" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                        <g id="Intake_1" transform="translate(-1202.000000, -473.000000)" fill="#8A95A1" fillRule="nonzero">
                                            <path d="M1202,483 L1218,483 L1218,481 L1202,481 L1202,483 Z M1202,487 L1218,487 L1218,485 L1202,485 L1202,487 Z M1202,479 L1218,479 L1218,477 L1202,477 L1202,479 Z M1202,473 L1202,475 L1218,475 L1218,473 L1202,473 Z" id="Shape"/>
                                        </g>
                                    </g>
                                </svg>
                            </button>
                        </div>
                    </div>
                    <Cards data={released} view={"study"} user={this.props.user} />
                    {paginate ?
                        <Pagination
                            activePage={this.state.released_activePage}
                            itemsCountPerPage={PER_PAGE}
                            totalItemsCount={rel.length}
                            pageRangeDisplayed={rel.length / PER_PAGE}
                            onChange={this.handlePageChange_released}
                        />
                    :null
                    }
                    <div className="h1 section-heading section-heading-border">{pend.length} Studies
                        pending
                    </div>
                    <Cards data={pending} view={"study"} user={this.props.user} />
                    {paginate ?
                        <Pagination
                            activePage={this.state.pending_activePage}
                            itemsCountPerPage={PER_PAGE}
                            totalItemsCount={pend.length}
                            pageRangeDisplayed={pend.length / PER_PAGE}
                            onChange={this.handlePageChange_pending}
                        />
                    :null
                    }
                    <Modal
                        isOpen={this.state.modalIsOpen}
                        onRequestClose={this.closeModal}
                        contentLabel="Add user"
                        style={customStyles}>
                        <FormUpload name={"batch_upload"}
                                    title={"upload.svg"}
                                    caption={"Upload a batch file"}
                                    data={this.state}
                                    parentChange={this.onChange}
                                    type={"batch_study"}/>
                    </Modal>
                </div>
            );
        }

    }
}

export default connect(mapStateToProps, {fetchStudies})(Studies);