import React, {Component} from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import { Dropdown } from '../partials/InputBlock';

import Cards from '../Cards.js';
import { Gantt } from '../partials/Chart.js';
import { push } from 'connected-react-router'
import ListView from '../ListView';
import LoadingScreen from '../partials/Loading';

import Pagination from 'react-js-pagination';

import { fetchRallies, resetRally } from '../../actions/rallies';

import {
  getActiveRallies,
  getCompletedRallies,
  getCancelledRallies,
  getRallyParticipants,
  getRallyTags,
  getRallyTitles
} from '../../selectors/rallies';

const PER_PAGE = 6;

const mapStateToProps = (state) => ({
  loading: state.rallies.loading,
  loaded: state.rallies.loaded,
  rallies: state.rallies.rallies,
  user: state.auth.user,
  level: state.auth.level,
  error: state.rallies.error,
  active: getActiveRallies(state) ? getActiveRallies(state) : [],
  completed: getCompletedRallies(state) ? getCompletedRallies(state) : [],
  cancelled: getCancelledRallies(state) ? getCancelledRallies(state) : [],
  participants: getRallyParticipants(state) ? getRallyParticipants(state) : [],
  tags: getRallyTags(state) ? getRallyTags(state) : [],
  titles: getRallyTitles(state) ? getRallyTitles(state) : []
});

const mapDispatchToProps = (dispatch) => ({
  getRallies: () => (
    dispatch(fetchRallies())
  ),
  resetRally: () => (
    dispatch(resetRally())
  ),
  redirect: (id) => {
    dispatch(push('/rally/' + id))
  }
});

class Rallies extends Component {
  constructor(props) {
    super(props);

    this.state = {
      sort: {
        list: ["Most Recent","Oldest","A-Z","Z-A"],
        selected: null,
      },
      filter: {
        participants: null,
        tags: null,
        rallies: null,
      },
      search: "",
      rallies: {
        full: this.props.rallies ? this.props.rallies : [],
        active: this.props.active ? this.props.active : [],
        completed: this.props.completed ? this.props.completed : [],
        cancelled: this.props.cancelled ? this.props.cancelled : [],
      },
      error: null,
      list: localStorage.getItem("rally_list_view") === "1",
      autoSave: false,
      paginate: false
    };
  }

  getActive = (list) => {
    let today = new Date();
    return list.filter(function (obj) {
      let newDate = new Date(obj.timeline[1]);
      return newDate.getTime() >= today.getTime() && (obj.status && obj.status.toLowerCase()) != 'cancelled';
    });
  };

  getCompleted = (list) => {
    let today = new Date();
    return list.filter(function (obj) {
      let newDate = new Date(obj.timeline[1]);
      return newDate.getTime() < today.getTime() && (obj.status && obj.status.toLowerCase()) != 'cancelled';
    });
  };

  getCancelled = (list) => {;
    return list.filter(function (obj) {
      return (obj.status && obj.status.toLowerCase()) === 'cancelled';
    });
  };

  handleSearch = (e) => {
    let filtered = [];
    if (e) {
      let searchText = e.target.value;
      if (searchText === "") {
        this.setState({
          search: searchText,
          rallies: {
            ...this.state.rallies,
            active: this.getActive(this.state.rallies.full),
            completed: this.getCompleted(this.state.rallies.full),
            cancelled: this.getCancelled(this.state.rallies.full),
          }
        })
      } else {
        filtered = this.state.rallies.full.filter((rally) => {
          let query = new RegExp(searchText.toLowerCase(), 'i');
          let match = false;
          if (rally.sprint_title) match = match || rally.sprint_title.toLowerCase().match(query);
          if (rally.status) match = match || rally.status.toLowerCase().match(query);
          if (rally.participants) match = match || rally.participants.join().toLowerCase().match(query);
          if (rally.tags) match = match || rally.tags.toLowerCase().match(query);

          return match;
        });
        this.setState({
          search: searchText,
          rallies: {
            ...this.state.rallies,
            active: this.getActive(filtered),
            completed: this.getCompleted(filtered),
            cancelled: this.getCancelled(filtered),
          }
        })
      }
    }
  };

  handleSort = (value) => {
    let filtered = [];
    let revert = false;
    if (value) {
      this.setState({
        sort: {
          ...this.state.sort,
          selected: value,
        }
      });
      switch (value.toLowerCase()) {
        case "":
          filtered = this.props.rallies;
          revert = true;
          break;
        case "most recent":
          filtered = this.state.rallies.full.sort(function(a, b){
            if(parseInt(a.rally_number) > parseInt(b.rally_number)) return -1;
            if(parseInt(a.rally_number) < parseInt(b.rally_number)) return 1;
            return 0;
          });
          break;
        case "oldest":
          filtered = this.state.rallies.full.sort(function(a, b){
            if(parseInt(a.rally_number) < parseInt(b.rally_number)) return -1;
            if(parseInt(a.rally_number) > parseInt(b.rally_number)) return 1;
            return 0;
          });
          break;
        case "a-z":
          filtered = this.state.rallies.full.sort(function(a, b){
            if(a.sprint_title < b.sprint_title) return -1;
            if(a.sprint_title > b.sprint_title) return 1;
            return 0;
          });
          break;
        case "z-a":
          filtered = this.state.rallies.full.sort(function(a, b){
            if(a.sprint_title > b.sprint_title) return -1;
            if(a.sprint_title < b.sprint_title) return 1;
            return 0;
          });
          break;
        default:
          filtered = this.props.rallies;
          revert = true;
      }
    }

    if (revert) {
      this.setState({
        rallies: {
          ...this.state.rallies,
          active: this.getActive(this.state.rallies.full),
          completed: this.getCompleted(this.state.rallies.full),
          cancelled: this.getCancelled(this.state.rallies.full),
        }
      });

    } else {
      this.setState({
        rallies: {
          ...this.state.rallies,
          active: this.getActive(filtered),
          completed: this.getCompleted(filtered),
          cancelled: this.getCancelled(filtered),
        }
      });
    }
  };

  handleFilter = (name, value) => {
    let filtered = [];
    if (value) {
      this.setState({
        filter: {
          ...this.state.filter,
          [name]: value,
        }
      });
      let filterText = value.split("','");
      filterText = filterText.join("|");

      filtered = this.state.rallies.full.filter((rally) => {
        let query = new RegExp(filterText.toLowerCase(), 'i');
        let match = false;
        if (rally.rally_number) match = match || rally.rally_number.match(query);
        if (rally.status) match = match || rally.status.toLowerCase().match(query);
        if (rally.participants) match = match || rally.participants.join().toLowerCase().match(query);
        if (rally.tags) match = match || rally.tags.toLowerCase().match(query);

        return match;
      });
      this.setState({
        rallies: {
          ...this.state.rallies,
          active: this.getActive(filtered),
          completed: this.getCompleted(filtered),
          cancelled: this.getCancelled(filtered)
        }
      })
    } else {
      this.setState({
        filter: {
          ...this.state.filter,
          [name]: value,
        },
        rallies: {
          ...this.state.rallies,
          active: this.getActive(this.state.rallies.full),
          completed: this.getCompleted(this.state.rallies.full),
          cancelled: this.getCancelled(this.state.rallies.full),
        }
      })
    }
  };

  componentWillUnmount() {
    document.body.classList.remove('rallies');
  };

  componentDidMount() {
    document.body.classList.toggle('rallies', true);
    localStorage.setItem("current_tab", 0);
    this.props.getRallies();
    this.props.resetRally();
  }

  componentDidUpdate(prevProps) {
    if (JSON.stringify(this.props.rallies) !== JSON.stringify(prevProps.rallies)) {
      if (this.props.rallies) {
        this.setState({
          rallies: {
            full: this.props.rallies,
            active: this.props.active,
            completed: this.props.completed,
            cancelled: this.props.cancelled
          }
        })
      }
    }
  }

  componentDidCatch(error, info) {
    this.setState({ error: error, info: info });
  }

  render() {
    if (this.props.error) { return <p className="error">{this.state.error.message} {this.state.info}</p>;}
    if (this.props.loading) return <LoadingScreen interior />;
    return (
      <div className="rallies">
        <div className="hero-heading-wrapper rallies-heading-wrapper">

          <div className="hero-heading-col"><h1>Rallies</h1></div>

          <div className="hero-heading-col">
            {this.props.level === 3 || this.props.level === 2 ?
              <Link to={"/rally/edit/add"} className="button-text-icon add-item icon-left">
                <span className="plus-wrap">
                  <svg version="1.1" id="Layer_1"  x="0px" y="0px" viewBox="0 0 12 12">
                    <g id="Symbols">
                      <g id="buttons_x2F_addItem" transform="translate(-15.000000, -10.000000)">
                        <g id="Group-25" transform="translate(15.000000, 10.000000)">
                          <g id="Path-4">
                            <rect x="5.1" className="st0" width="2" height="12"/>
                          </g>
                          <g id="Path-5">
                            <rect y="5.1" className="st0" width="12" height="2"/>
                          </g>
                        </g>
                      </g>
                    </g>
                  </svg>
                </span>
                Start Sprint
              </Link>
              :
              null
            }
          </div>
        </div>

        <Gantt
          payload={this.props.active}
          onClick={(id) => this.props.redirect(id)}
        />

        <div className="filter-bar">
          <Dropdown
            updateValue={(name, value) => this.handleSort(value)}
            options={this.state.sort.list}
            name="sort"
            type="Sort"
            selected={this.state.sort.selected}
          />
          <Dropdown
            updateValue={(name, value) => this.handleFilter(name, value)}
            options={this.props.participants}
            type={"People ("+ this.props.participants.length +")"}
            name="participants"
            selected={this.state.filter.participants}
            isMulti
          />
          <Dropdown
            updateValue={(name, value) => this.handleFilter(name, value)}
            options={this.props.tags}
            type={"Tags ("+ this.props.tags.length +")"}
            name="tags"
            selected={this.state.filter.tags}
            isMulti
          />
          <Dropdown
            updateValue={(name, value) => this.handleFilter(name, value)}
            options={this.props.titles}
            type={"Rallies ("+ this.props.titles.length +")"}
            name="rallies"
            selected={this.state.filter.rallies}
            isMulti
          />
          <input
            className="search-input"
            type="text"
            placeholder="Search"
            value={this.props.search}
            onChange={this.handleSearch}
          />
        </div>

        <div className="h1 section-heading section-heading-border">
          {this.state.rallies.active.length} Active/future sprints

          <div className="toggle-view-wrap">
            <button
              className={this.state.list ? "card-toggle-list-view" : "card-toggle"}
              onClick={() => this.setState({ list: false }, () => { localStorage.setItem("rally_list_view", "0"); })}>
              <svg width="16px" height="14px" viewBox="0 0 16 14" version="1.1">
                <g id="V1-Intake" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                  <g id="Intake_1" transform="translate(-1165.000000, -473.000000)" fill={this.state.list ? "#8A95A1" : "#FFFFFF"} fillRule="nonzero">
                    <path d="M1165,479 L1169,479 L1169,473 L1165,473 L1165,479 Z M1165,487 L1169,487 L1169,481 L1165,481 L1165,487 Z M1171,487 L1175,487 L1175,481 L1171,481 L1171,487 Z M1177,487 L1181,487 L1181,481 L1177,481 L1177,487 Z M1171,479 L1175,479 L1175,473 L1171,473 L1171,479 Z M1177,473 L1177,479 L1181,479 L1181,473 L1177,473 Z" id="Shape"/>
                  </g>
                </g>
              </svg>
            </button>

            <button
              className={this.state.list ? "list-toggle-list-view" : "list-toggle"}
              onClick={() => this.setState({ list: true }, () => { localStorage.setItem("rally_list_view", "1"); })}>
              <svg width="16px" height="14px" viewBox="0 0 16 14" version="1.1">
                <g id="V1-Intake" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                  <g id="Intake_1" transform="translate(-1202.000000, -473.000000)" fill={this.state.list ?  "#FFFFFF" : "#8A95A1"} fillRule="nonzero">
                    <path d="M1202,483 L1218,483 L1218,481 L1202,481 L1202,483 Z M1202,487 L1218,487 L1218,485 L1202,485 L1202,487 Z M1202,479 L1218,479 L1218,477 L1202,477 L1202,479 Z M1202,473 L1202,475 L1218,475 L1218,473 L1202,473 Z" id="Shape"/>
                  </g>
                </g>
              </svg>
            </button>
          </div>
        </div>

        {this.state.list ?
        <div>
          <ListView
            data={this.state.rallies.active}
            view={"rally"}
            active={true}
          />
          {this.state.paginate ?
            <Pagination
              activePage={this.state.active_activePage}
              itemsCountPerPage={PER_PAGE}
              totalItemsCount={this.props.active}
              pageRangeDisplayed={Math.ceil(this.props.active / PER_PAGE)}
              onChange={this.handlePageChange_active}
            />
            :
            null
          }
        </div>
        :
        <div>
          <Cards
            data={this.state.rallies.active}
            active={true}
            parentCreate={this.ppt_create}
            user={this.props.user}
            view={"rally"}
          />
          {this.state.paginate ?
            <Pagination
              activePage={this.state.active_activePage}
              itemsCountPerPage={PER_PAGE}
              totalItemsCount={this.props.active.length}
              pageRangeDisplayed={Math.ceil(this.props.active.length / PER_PAGE)}
              onChange={this.handlePageChange_active}
            />
            :null
          }
        </div>
        }

        <div className="h1 section-heading section-heading-border">
          {this.state.rallies.completed.length} Completed sprints
        </div>

        {this.state.list ?
        <div>
          <ListView
            data={this.state.rallies.completed}
            parentCreate={this.ppt_create} active={false}
            user={this.props.user}
            view={"rally"}
          />
          {this.state.paginate ?
            <Pagination
              prevPageText='prev'
              nextPageText='next'
              firstPageText='first'
              lastPageText='last'
              activePage={this.state.complete_activePage}
              itemsCountPerPage={PER_PAGE}
              totalItemsCount={this.props.completed.length}
              pageRangeDisplayed={Math.ceil(this.props.completed.length / PER_PAGE)}
              onChange={this.handlePageChange_complete}
            />
            :
            null
          }
        </div>
        :
        <div>
          <Cards
            data={this.state.rallies.completed}
            active={false}
            parentCreate={this.ppt_create}
            user={this.props.user}
            view={"rally"}
          />
          {this.state.paginate ?
            <Pagination
              prevPageText='prev'
              nextPageText='next'
              firstPageText='first'
              lastPageText='last'
              activePage={this.state.complete_activePage}
              itemsCountPerPage={PER_PAGE}
              totalItemsCount={this.props.completed.length}
              pageRangeDisplayed={Math.ceil(this.props.completed.length / PER_PAGE)}
              onChange={this.handlePageChange_complete}
            />
            :null
          }
        </div>
        }

        <div className="h1 section-heading section-heading-border">
          {this.state.rallies.cancelled.length} Cancelled sprint{this.state.rallies.cancelled.length > 1 && 's'}
        </div>
        {this.state.list ?
        <div>
          <ListView
            data={this.state.rallies.cancelled}
            parentCreate={this.ppt_create} active={false}
            user={this.props.user}
            view={"rally"}
          />
          {this.state.paginate ?
            <Pagination
              prevPageText='prev'
              nextPageText='next'
              firstPageText='first'
              lastPageText='last'
              activePage={this.state.cancelled_activePage}
              itemsCountPerPage={PER_PAGE}
              totalItemsCount={this.props.cancelled.length}
              pageRangeDisplayed={Math.ceil(this.props.cancelled.length / PER_PAGE)}
              onChange={this.handlePageChange_cancelled}
            />
            :
            null
          }
        </div>
        :
        <div>
          <Cards
            data={this.state.rallies.cancelled}
            active={false}
            parentCreate={this.ppt_create}
            user={this.props.user}
            view={"rally"}
          />
          {this.state.paginate ?
            <Pagination
              prevPageText='prev'
              nextPageText='next'
              firstPageText='first'
              lastPageText='last'
              activePage={this.state.cancelled_activePage}
              itemsCountPerPage={PER_PAGE}
              totalItemsCount={this.props.cancelled.length}
              pageRangeDisplayed={Math.ceil(this.props.cancelled.length / PER_PAGE)}
              onChange={this.handlePageChange_cancelled}
            />
            :null
          }
        </div>
        }
      </div>
    );
  }

}

export default connect(mapStateToProps, mapDispatchToProps)(Rallies);