import { hubApi } from '../util/api';

export const LOADING_STUDIES = 'LOADING_STUDIES';
export const STUDIES_SUCCESS = 'STUDIES_SUCCESS';
export const STUDIES_FAIL = 'STUDIES_FAIL';

export const fetchingStudies = () => ({
  type: LOADING_STUDIES
});

export const studiesSuccess = studies => ({
  type: STUDIES_SUCCESS,
  studies,
  receivedAt: Date.now()
});

export const studiesFail = error => ({
  type: STUDIES_FAIL,
  error
});

/*
 * Fetch study data
 */
export const fetchStudies = () => (dispatch) => {
  dispatch(fetchingStudies());

  const endpoint = hubApi().endPoints.studies;

  hubApi().get(endpoint)
    .then((response) => {
      console.log(response);
      let studyData = response.data.data;
      dispatch(studiesSuccess(studyData));
    })
    .catch((error) => {
      dispatch(studiesFail(error))
    })
};
