import React, {Component} from 'react';
import { Redirect } from 'react-router-dom';
import StudyTable from './feeds/StudyTable';
import RallyTable from './feeds/RallyTable';
import NewsTable from './feeds/NewsTable';

class KIFeed extends Component {

  componentWillUnmount() {
    document.body.classList.remove('ki-feed');
  };

  componentDidMount() {
    document.body.classList.toggle('ki-feed', true);
  };

  render() {
    return <Redirect to={'/rallies'}/>
    // return (
    //   <div className={"content-wrapper"}>
    //     <h1 className="feedhero">KI FEED</h1>
    //     <div className={"feed-left-column hub-content-wrapper"}>
    //       <NewsTable />
    //     </div>
    //     <div className={"feed-right-column"}>
    //       <div className={"top-section hub-content-wrapper"}>
    //         <RallyTable />
    //       </div>
    //       <div className={"bottom-section hub-content-wrapper"}>
    //         <StudyTable />
    //       </div>
    //     </div>
    //   </div>
    // );
  }
}

export default KIFeed;
