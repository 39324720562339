import {
  LOGGING_IN,
  LOGGING_IN_SUCCESS,
  LOGGING_IN_FAIL,
  CLEAR_ERROR,
  COMPLETE_AUTH_CHECK
} from "../actions/auth";

const initialState = {
  user: [],
  level: null,
  error: null,
  loggingIn: false,
  loggedIn: false,
  checkedAuth: false,
  token: null,
};

export default function authReducer(state = {...initialState}, action) {
  switch (action.type) {
    case LOGGING_IN:
      return {
        ...state,
        loggingIn: true,
      };
    case LOGGING_IN_SUCCESS:
      return {
        ...state,
        loggingIn: false,
        loggedIn: true,
        user: action.user,
        level: action.level,
        token: action.user.token,
        checkedAuth: true,
        error: null,
      };
    case LOGGING_IN_FAIL:
      return {
        ...state,
        loggingIn: false,
        checkedAuth: true,
        error: action.error,
      };
    case CLEAR_ERROR:
      return {
        ...state,
        error: null,
      };
    case COMPLETE_AUTH_CHECK:
      return {
        ...state,
        checkedAuth: true,
      };
    default:
      return state;
  }
};