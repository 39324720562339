import React, {Component} from 'react';
import { connect } from 'react-redux';
import axios from "axios";

import {Redirect} from "react-router-dom";

import { resetPassword } from "../actions/users";

const mapStateToProps = (state) => ({
  user: state.auth.user,
});

class Reset extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      password: "",
      repassword: "",
      reset: false
    };
  }

  onChange = (e) => {
    this.setState({[e.target.name]: e.target.value });
  };

  handleSubmit = (type) => {
    if(this.state.password === this.state.repassword) {
      this.setState({error: null});

      let token =  document.getElementById("token").value;
      let id = document.getElementById("id").value;
      this.setState({token: token, id: id}, () => {
        console.log(this.state);
        this.props.resetPassword(this.state, type);
      });

    } else {
      this.setState({error: "Passwords do not match"});
    }
  };

  sendEmail = () => {
    let email = this.props.location.state.email;
    if (email) {
      this.props.resetPassword({email}, 'email');
    }
  };

  render() {
    console.log(this.props);
    const {error} = this.state;

    if (this.props.match.params.token) {
      return (
        <div className="set-password">
          <div className='view-element sign-in-field'>
            <h1>Welcome to Ki Community Hub</h1>
            <div className="form-question">
              <input type="hidden" id="id" value={this.props.match.params.userID}/>
              <input type="hidden" id="token" value={this.props.match.params.token}/>
              <label>Please set a password for your new account</label>
              <input
                type="password"
                placeholder="Password"
                onChange={this.onChange}
                name="password"
                value={this.state.password}
              />
              <input
                type="password"
                placeholder="Confirm Password"
                name="repassword"
                onChange={this.onChange}
                value={this.state.repassword}
              />
              {this.state.error ?
                <p className="error">{this.state.error}</p>
              :
                null
              }
              {this.state.repassword !== "" && this.state.password !== "" ?
                <input type="submit" className="submit-button" value="Update Password" onClick={() => this.handleSubmit('new')}/>
              :
                null
              }
            </div>
          </div>
        </div>
      );
      }

    if (parseInt(this.props.match.params.userID) === this.props.user.id) {
        return (
          <div className="set-password interior">
            <div className='view-element sign-in-field'>
              <div className="form-question">
                <input type="hidden" id="id" value={this.props.match.params.userID}/>
                <input type="hidden" id="token" value={this.props.match.params.token}/>
                <label>Please set a password for your account</label>
                <input
                  type="password"
                  placeholder="Password"
                  onChange={this.onChange}
                  name="password"
                  value={this.state.password}
                />
                <input
                  type="password"
                  placeholder="Confirm Password"
                  name="repassword"
                  onChange={this.onChange}
                  value={this.state.repassword}
                />
                {this.state.error ?
                  <p className="error">{this.state.error}</p>
                  :
                  null
                }
                {this.state.repassword !== "" && this.state.password !== "" ?
                  <input type="submit" className="submit-button" value="Update Password" onClick={() => this.handleSubmit('reset')}/>
                  :
                  null
                }
              </div>
            </div>
          </div>
        );
    } else {
        return (
          <div className="set-password interior">
            <div className='view-element sign-in-field'>
              <div className="form-question">
                <input type="hidden" id="id" value={this.props.match.params.userID}/>
                <input type="hidden" id="token" value={this.props.match.params.token}/>
                <label>Reset Password for another account</label>
                <button className="submit-button" value="Update Password" onClick={() => this.sendEmail()}>Send Email</button>
              </div>
            </div>
          </div>
        );
    }

  }
}

export default connect(mapStateToProps, { resetPassword })(Reset);