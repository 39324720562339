import { hubApi } from '../util/api';
import { push } from 'connected-react-router';


/*
 * Fetch all Users
 */
export const LOADING_USERS = 'LOADING_USERS';
export const USERS_SUCCESS = 'USERS_SUCCESS';
export const USERS_FAIL = 'USERS_FAIL';

export const fetchingUsers = () => ({
  type: LOADING_USERS
});

export const usersSuccess = users => ({
  type: USERS_SUCCESS,
  users,
  receivedAt: Date.now()
});

export const usersFail = error => ({
  type: USERS_FAIL,
  error
});

/*
 * Fetch users data
 */
export const fetchUsers = () => (dispatch) => new Promise((resolve, reject) => {
  dispatch(fetchingUsers());

  const endpoint = hubApi().endPoints.users;

  hubApi().get(endpoint)
    .then((response) => {
      let userData = response.data.data;
      dispatch(usersSuccess(userData));
      resolve(userData);
    })
    .catch((error) => {
      dispatch(usersFail(error))
      reject(error);
    })
});

/*
 * Fetch single User
 */
export const LOADING_USER = 'LOADING_USER';
export const USER_SUCCESS = 'USER_SUCCESS';
export const USER_FAIL = 'USER_FAIL';

export const fetchingUser = () => ({
  type: LOADING_USER
});

export const userSuccess = user => ({
  type: USER_SUCCESS,
  user,
  receivedAt: Date.now()
});

export const userFail = error => ({
  type: USER_FAIL,
  error
});

/*
 * Fetch single user data
 * @param {object} userID [Required]
 */
export const fetchSingleUser = (id) => (dispatch) => {
  dispatch(fetchingUser);

  const endpoint = hubApi().endPoints.users;

  hubApi().get(endpoint + "/" + id)
    .then((response) => {
      let userData = response.data.data;
      dispatch(userSuccess(userData));
    })
    .catch((error) => {
      dispatch(userFail(error))
    })
};

/*
 * Create/edit User
 */
export const UPDATING_USER = 'UPDATING_USER';
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS';
export const UPDATE_USER_FAIL = 'UPDATE_USER_FAIL';

export const updatingUser = () => ({
  type: UPDATING_USER
});

export const updateUserSuccess = user => ({
  type: UPDATE_USER_SUCCESS,
  user,
  receivedAt: Date.now()
});

export const updateUserFail = error => ({
  type: UPDATE_USER_FAIL,
  error
});

/*
 * Create a new user
 * @param {object} user data [Required]
 */
export const createUser = (data) => (dispatch) => {
  dispatch(updatingUser);

  const endpoint = hubApi().endPoints.createUser;

  hubApi().post(endpoint, data)
    .then((response) => {
      console.log(response);
      let userData = response.data.data;
      dispatch(updateUserSuccess(userData));
      dispatch(push('/people/' + userData.id));
    })
    .catch((error) => {
      console.log(error);
      dispatch(updateUserFail(error))
    })
};

/*
 * Edit a user
 * @param {object} user data [Required]
 */
export const editUser = (data) => (dispatch) => {
  dispatch(updatingUser);

  const endpoint = hubApi().endPoints.editUser;

  console.log(data);
  console.log(endpoint + data.id);

  hubApi().post(endpoint + data.id, data)
    .then((response) => {
      console.log(response);
      dispatch(updateUserSuccess(data));
      dispatch(push('/people/' + data.id));
    })
    .catch((error) => {
      console.log(error);
      dispatch(updateUserFail(error))
    })
};


/*
 * Reset password
 * @param {object} userID [Required]
 */
export const resetPassword = (data, view) => (dispatch) => {
  const endpoint = hubApi().endPoints.resetPassword;
  const emailEndpoint = hubApi().endPoints.emailPassword;

  if (view === 'email') {
    hubApi().post(emailEndpoint, data)
      .then((response) => {
        console.log(response);
        dispatch(push('/people'))
      })
      .catch((error) => {
        console.log(error);
      })
  } else {
    hubApi().post(endpoint, data)
      .then((response) => {
        console.log(response);
        if (view === 'reset') {
          dispatch(push('/people'))
        } else {
          dispatch(push('/login'));
        }
      })
      .catch((error) => {
        console.log(error);
      })
  }
};


/*
 * Delete single User
 */
export const DELETING_USER = 'DELETING_USER';
export const DELETE_USER_SUCCESS = 'DELETE_USER_SUCCESS';
export const DELETE_USER_FAIL = 'DELETE_USER_FAIL';

export const deletingUser = () => ({
  type: DELETING_USER
});

export const deleteUserSuccess = () => ({
  type: DELETE_USER_SUCCESS,
  receivedAt: Date.now()
});

export const deleteUserFail = error => ({
  type: DELETE_USER_FAIL,
  error
});

/*
 * Delete User
 * @param {object} userID [Required]
 */
export const deleteUser = (id) => (dispatch) => {
  dispatch(deletingUser());

  const endpoint = hubApi().endPoints.editUser;

  hubApi().delete(endpoint + id)
    .then((response) => {
      console.log(response);
      dispatch(deleteUserSuccess());
      dispatch(push('/people'));
    })
    .catch((error) => {
      console.log(error);
      dispatch(deleteUserFail(error))
    })
};

