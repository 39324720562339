import { createSelector } from 'reselect';

const simpleUserList = (state) => {
  let users = [];
  state.users.users.forEach((user) => {
    if(user.first_name) {
      users.push({id: user.id, name: user.first_name + " " + user.last_name})
    }
  });
  return users;
};

const activeUsers = (state) => state.users.users.filter((user) => user.active === "Active" && user.added === 1 );
const requestedUsers = (state) => state.users.users.filter((user) => user.active === "Active" && user.added === 0 );
const inactiveUsers = (state) => state.users.users.filter((user) => user.active === "Inactive" && user.added === 1 );

const userLocations = (state) => {
  let locations = [];
  state.users.users.forEach((user) => {
    if (user.location && user.location !== "") locations.push(user.location.toLowerCase());
  });
  return [...new Set(locations)];
};

const userOrganizations = (state) => {
  let orgs = [];
  state.users.users.forEach((user) => {
    if (user.primary_organizational_affiliation && user.primary_organizational_affiliation !== "") {
      orgs.push(user.primary_organizational_affiliation.toLowerCase());
    }
  });
  return [...new Set(orgs)];
};

const userRoles = (state) => {
  let roles = [];
  state.users.users.forEach((user) => {
    if (user.role && user.role !== "") {
      roles.push(user.role.toLowerCase());
    }
  });
  return [...new Set(roles)];
};

const participatingIn = (state) => {
  let userRallies = state.users.single.user.participating_in ? state.users.single.user.participating_in : [];
  let rallies = state.rallies.rallies;
  let pRallies = [];
  userRallies.forEach((pID) => {
    rallies.forEach((rally) => {
      if (rally.id === pID.table_id) {
        pRallies.push(rally);
      }
    })
  });
  return [...new Set(pRallies)];
};


export const getSimpleUserList = createSelector(
  [simpleUserList],
  list => list,
);

export const getActiveUsers = createSelector(
  [activeUsers],
  list => list,
);

export const getRequestUsers = createSelector(
  [requestedUsers],
  list => list,
);

export const getInactiveUsers = createSelector(
  [inactiveUsers],
  list => list,
);

export const getUserLocations = createSelector(
  [userLocations],
  list => list,
);

export const getUserOrganizations = createSelector(
  [userOrganizations],
  list => list,
);

export const getUserRoles = createSelector(
  [userRoles],
  list => list,
);

export const getParticpatingIn = createSelector(
  [participatingIn],
  list => list,
);