import React, { Component } from 'react';
import axios from "axios";
import {Link} from "react-router-dom";
import {Tabs, Tab} from 'react-bootstrap';
import Cards from '../Cards.js';
import FollowLink from '../FollowLink';
import StandardInput from '../StandardInput';
import moment from 'moment';
import { API } from '../../util/api';
const eStyle = {
    margin: "0px 5px 0px 0px"
};
class Study extends Component {
    constructor(props) {
        super(props);

        this.state = {
            study: [],
            isLoading: true,
            error: null,
            arms: [],
            epochs: []
        };
    }

    componentWillUnmount() {
        document.body.classList.remove('study')
    }

    componentDidMount() {
        document.body.classList.toggle('study', true);
        axios.get(API + "/api/v1/study/" + this.props.match.params.studyID)
            .then((response) => {
                let study = response.data.data;
                let arms = response.data.data.arms;
                let epochs = response.data.data.epochs;
                let visits = response.data.data.visits;
                let user = "";
                if(study.user){
                    axios.get(API + "/api/v1/user/" + study.user)
                        .then((response) => {
                            study['username'] = response.data.data.first_name + " " + response.data.data.last_name;
                            this.setState({study});
                        })
                        .catch((err) => {
                            console.log(err);
                        });
                } else {
                    study['username'] = "No user listed";
                    this.setState({study});
                }
                this.setState({arms, epochs, visits, study, user});
                this.setState({isLoading: false});
                console.log(this.state);
            })
            .catch((err) => {
                console.log(err);
            });
    }

    componentDidCatch(error, info) {
        this.setState({ error: error });
    }

    render() {
        const { study, isLoading, error, arms, epochs, visits, user } = this.state;

        if (error) {
            return <p className="error">{error.message}</p>;
        }

        if (isLoading) {
            return <p>Loading ...</p>;
        }

        return (
            <div className="study-content">
                <div className={"row"}>
                    <div className="col-sm-2">
                        <h1><Link className="backlink" to={"/studies/"}>{""}</Link>{study.short_id}</h1>
                    </div>
                    <div className="col-sm-6">

                    </div>
                    <div className="col-sm-4 button-controls">
                        {study.data_location !== null ?
                            <a href={study.data_location} className="button-text-icon icon-right data-link" style={eStyle}>Data
                            <span className="right-align-icon">
                              <svg width="12px" height="12px" viewBox="0 0 12 12" version="1.1">
                                <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                    <g id="linkBoxSmall" transform="translate(-171.000000, -10.000000)" fill="#FFFFFF" fillRule="nonzero">
                                        <g id="Group-8">
                                            <polygon id="Shape-Copy-2" points="177 10 175.9425 11.0575 180.1275 15.25 171 15.25 171 16.75 180.1275 16.75 175.9425 20.9425 177 22 183 16"/>
                                        </g>
                                    </g>
                                </g>
                            </svg>
                            </span>
                            </a>
                            :null
                        }
                        {study.locked === 1 ?
                            <Link to={"#"} className="button-text-icon icon-right edit-button rally-header-btn" style={eStyle}>Locked</Link>
                            :<Link to={"/study/edit/" + study.id} className="button-text-icon icon-left edit-button rally-header-btn" style={eStyle}>
                            <span className="icon-wrap">
                            <svg version="1.1" id="Layer_1" x="0px" y="0px" viewBox="0 0 15 15">
                            <path id="Shape" className="st0" d="M0.1,11.8v3.1h3.1l9.1-9L9.2,2.8L0.1,11.8z M14.6,3.5c0.3-0.3,0.3-0.8,0-1.2l-1.9-1.9
                            c-0.3-0.3-0.8-0.3-1.2,0l-1.5,1.5L13.1,5L14.6,3.5z"/>
                            </svg>
                            </span>
                            Edit</Link>
                        }
                        <p/>

                    </div>
                </div>
                <div className="row last-updated-box">
                  <p className="subtext">{ "Last updated: " + study.username + ", " + moment(study.last_updated).format('h:m A') }</p>
                </div>
                <Tabs activeKey={this.state.key} onSelect={this.changeKey} id={"tab-" + 0}>
                    <Tab eventKey={1} title="Summary">
                        <div className='view-element'>
                            <label>Program: {study.program}</label>
                        </div>
                        <div className='view-element'>
                            <h1>{study.short_id}</h1>
                        </div>
                        <div className='view-element'>
                            <label>Status</label>
                            <ul className="phase" type="circle">
                                <li className={study.data_status === "Not Transmitted yet" ? 'active' : null}>
                                    <div className="nav-dot active">
                                    </div>
                                    Not Transmitted yet
                                </li>
                                <li className={study.data_status === "Data received & data integration ongoing" ? 'active' : null}>
                                    <div className="nav-dot active">
                                    </div>
                                    Data received ongoing
                                </li>
                                <li className={study.data_status === "Data released for use but expecting updated transfer" ? 'active' : null}>
                                    <div className="nav-dot active">
                                    </div>
                                    Data released expecting
                                </li>
                                <li className={study.data_status === "Obsolete" ? 'active' : null}>
                                    <div className="nav-dot active">
                                    </div>
                                    Obsolete
                                </li>
                                <li className={study.data_status === "Data released for use" ? 'active' : null}>
                                    <div className="nav-dot active">
                                    </div>
                                    Data released for use
                                </li>
                                <li className={study.data_status === "Expired" ? 'active' : null}>
                                    <div className="nav-dot active">
                                    </div>
                                    Expired
                                </li>
                            </ul>
                        </div>
                        <StandardInput title={"Study title"} data={study.study_title} splitBool={false} />
                        <StandardInput title={"Population"} data={study.population} splitBool={false} />
                        <div className='view-element'>
                            <ul className="phase" type="circle">
                                <li>
                                    <label>Year</label>
                                    {study.start_year}
                                </li>
                                <li>
                                    <label>Subject count</label>
                                    {study.subject_count}
                                </li>
                                <li>
                                    <label>Age Upper/Lower</label>
                                    {study.enroll_age_upper}-{study.enroll_age_lower}
                                </li>
                                <li>
                                    <label>Country</label>
                                    {study.country}
                                </li>
                            </ul>
                        </div>
                    </Tab>
                    <Tab eventKey={2} title="Details">
                        <StandardInput title={"Date data recd"} data={study.date_data_recd} splitBool={false}/>
                        <FollowLink title={"Date Location"} data={study.date_location} />
                        <StandardInput title={"Short ID"} data={study.short_id} splitBool={false}/>
                        <StandardInput title={"Study title short"} data={study.study_title_short} splitBool={false}/>
                        <StandardInput title={"Clinical trial registry ids"} data={study.clinical_trial_registry_ids} splitBool={false}/>
                        <StandardInput title={"Study methods reference"} data={study.study_methods_reference} splitBool={false}/>
                        <StandardInput title={"Study results reference"} data={study.study_results_reference} splitBool={false}/>
                        <StandardInput title={"alternative_study_id"} data={study.alternative_study_id} splitBool={false}/>
                        <FollowLink title={"Study url"} data={study.study_url}/>
                        <StandardInput title={"Subject level"} data={study.subject_level} splitBool={false}/>
                        <StandardInput title={"Human"} data={study.human} splitBool={false}/>
                        <StandardInput title={"Non human animal"} data={study.non_human_animal} splitBool={false}/>
                        <StandardInput title={"Species"} data={study.species} splitBool={false}/>
                        <StandardInput title={"Other subject"} data={study.other_subject} splitBool={false}/>
                        <StandardInput title={"Method type"} data={study.method_type} splitBool={false}/>
                        <StandardInput title={"Longitudinal"} data={study.longitudinal} splitBool={false}/>
                        <StandardInput title={"Randomized"} data={study.randomized} splitBool={false}/>
                        <StandardInput title={"Rand type"} data={study.rand_type} splitBool={false}/>
                        <StandardInput title={"Rand details"} data={study.rand_details} splitBool={false}/>
                        <StandardInput title={"Controlled"} data={study.controlled} splitBool={false}/>
                        <StandardInput title={"Control type"} data={study.control_type} splitBool={false}/>
                        <StandardInput title={"Interventional"} data={study.interventional} splitBool={false}/>
                        <StandardInput title={"Interventional type"} data={study.interventional_type} splitBool={false}/>
                        <StandardInput title={"Number intervention arms"} data={study.number_intervention_arms} splitBool={false}/>
                        <div className="view-element">
                            <label>Study arms</label>
                            {arms.length > 0 ?
                                arms.map((data,index) =>
                                    <div className='view-element' key={index}>
                                        <Cards type={"Arms"} data={data} view={"arms"} />
                                    </div>
                                )
                                :
                                <div className='view-element'>
                                    <Cards type={"Arms"} data={null} view={"arms"} />
                                </div>
                            }
                        </div>
                        <StandardInput title={"Subject count"} data={study.subject_count} splitBool={false} />
                        <StandardInput title={"Primary objective"} data={study.primary_objective} splitBool={false}/>
                        <StandardInput title={"Secondary objective"} data={study.secondary_objective} splitBool={false} />
                        <StandardInput title={"Start year"} data={study.start_year} splitBool={false} />
                        <StandardInput title={"Stop year"} data={study.stop_year} splitBool={false} />
                        <StandardInput title={"Primary objective"} data={study.primary_objective} splitBool={false} />
                        <StandardInput title={"Enroll age lower"} data={study.enroll_age_lower} splitBool={true} />
                        <StandardInput title={"Enroll age upper"} data={study.enroll_age_upper} splitBool={true} />
                        <StandardInput title={"Population"} data={study.population} splitBool={false}/>
                        <StandardInput title={"Inclusion criteria"} data={study.inclusion_criteria} splitBool={false}/>
                        <StandardInput title={"Exclusion criteria"} data={study.exclusion_criteria} splitBool={false}/>
                        <StandardInput title={"Country"} data={study.country} splitBool={false}/>
                        <div className="view-element">
                            <label>Epochs</label>
                            {epochs.length > 0 ?
                                epochs.map((data,index) =>
                                    <div className='view-element' key={index}>
                                        <Cards type={"Epochs"} data={data} view={"epochs"} />
                                    </div>
                                )
                                :
                                <div className='view-element'>
                                    <Cards type={"Epochs"} data={""} view={"epochs"} />
                                </div>
                            }
                        </div>
                        <div className="view-element">
                            <label>Visits</label>
                            {visits.length > 0 ?
                                visits.map((data,index) =>
                                    <div className='view-element' key={index}>
                                        <Cards type={"Visits"} data={data} view={"visits"} />
                                    </div>
                                )
                                :
                                <div className='view-element'>
                                    <Cards type={"Visits"} data={null} view={"visits"} />
                                </div>
                            }
                        </div>
                        <Cards title={"Element Matrix:"} arms={study.arms} epochs={study.epochs} values={study.epoch_arm_values} view={"element_matrix"} />
                        <StandardInput title={"Scope of data"} data={study.scope_of_data} splitBool={false}/>
                        <StandardInput title={"Public user"} data={study.public_use} splitBool={false}/>
                        <StandardInput title={"Dua status"} data={study.dua_status} splitBool={false}/>
                        <StandardInput title={"Dua reason not req"} data={study.dua_reason_not_req} splitBool={false}/>
                        <StandardInput title={"Dua restrictions"} data={study.dua_restrictions} splitBool={false}/>
                        <StandardInput title={"Dua counterparty"} data={study.dua_counterparty} splitBool={false}/>
                        <StandardInput title={"Pi name"} data={study.pi_name} splitBool={true}/>
                        <StandardInput title={"Pi email"} data={study.pi_email} splitBool={true}/>
                        <StandardInput title={"Dm name"} data={study.dm_name} splitBool={true}/>
                        <StandardInput title={"Dm email"} data={study.dm_email} splitBool={true}/>
                        <StandardInput title={"Reason for interest"} data={study.reason_for_interest} splitBool={true}/>
                        <StandardInput title={"Contact note"} data={study.contact_note} splitBool={true}/>
                        <StandardInput title={"Date of contact note"} data={study.date_of_contact_note} splitBool={true}/>
                        <StandardInput title={"Follow up plan"} data={study.follow_up_plan} splitBool={true}/>
                        <StandardInput title={"Date of follow up plan"} data={study.date_of_follow_up_plan} splitBool={true}/>
                        <StandardInput title={"Last updated"} data={study.last_updated} splitBool={true}/>                    </Tab>
                </Tabs>
            </div>
        );
    }
}

export default Study;
