import React, { Component } from "react";
import { connect } from 'react-redux';

import { Redirect, Link } from "react-router-dom";
import axios from "axios";
import { Alert } from "react-bootstrap";

import { API } from '../../util/api';

const mapStateToProps = (state) => ({
  user: state.auth.user,
});

class News extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: true,
            error: null,
            spacing: "200",
        };
    }

    changeKey = (key) => {
        this.setState({key});
    };

    componentDidMount() {
        document.body.classList.toggle('news', true);
        axios.get(API + "/api/v1/news")
            .then((response) => {
                let news = response.data.data;
                news.forEach((n, i) => {
                    axios.get(API + "/api/v1/user/" + n.user)
                        .then((response) => {
                            let user = "";
                            if (news && response.data.data.first_name !== undefined) {
                                user = response.data.data.first_name +" "+ response.data.data.last_name;
                            } else {
                                user = "No user listed";
                            }
                            news[i].user_name = user;
                            this.setState({ news });
                        })
                        .catch((err) => {
                            console.log(err);
                            this.setState({error: err});
                        });
                });
                let active = news.filter(function (obj) {
                    return obj.active === "Active";
                });
                let inactive = news.filter(function (obj) {
                    return obj.active === "Inactive";
                });
                this.setState({ news: news });
                this.setState({ active: active });
                this.setState({ inActive: inactive });
                this.setState({ isLoading: false });
            })
            .catch((err) => {
                console.log(err);
                this.setState({error: err});
            });
    }

    componentWillUnmount() {
        document.body.classList.remove('news');
    }

    componentDidCatch(error, info) {
        this.setState({ error: error });
        this.setState({ info: info });
    }

    render() {
        const {isLoading, error, news} = this.state;

        if (error) {
            return <Alert bsStyle="danger">{error.message}</Alert>;
        }
        if (isLoading) {
            return <p>Loading ...</p>;
        }

        return (
          <div>
              <div className="hero-heading-wrapper">
              <div className="hero-heading-col">
              <h1>News</h1>
              </div>
              <div className="hero-heading-col">
              <Link to={"/news/edit/add"} className="button-text-icon add-item icon-left">
              <span className="plus-wrap">
              <svg version="1.1" id="Layer_1"  x="0px" y="0px" viewBox="0 0 12 12">
                <g id="Symbols">
                  <g id="buttons_x2F_addItem" transform="translate(-15.000000, -10.000000)">
                    <g id="Group-25" transform="translate(15.000000, 10.000000)">
                      <g id="Path-4">
                        <rect x="5.1" className="st0" width="2" height="12"/>
                      </g>
                      <g id="Path-5">
                        <rect y="5.1" className="st0" width="12" height="2"/>
                      </g>
                    </g>
                  </g>
                </g>
                </svg>
                </span>
              News</Link>
              </div>
              </div>
              <div className="hub-content-wrapper">
              {news !== undefined && news !== null ?
                  news.map((data, index) =>
                      <div className="news-item" key={index}>
                          <div className="news-item-left-col news-item-col">
                              <div className="subtext">{data.user_name}, {data.updated_at}</div>
                              <Link to={"/news/"+data.id} className="h2">{data.title}</Link>
                          </div>
                          <div className="news-item-right-col news-item-col">
                              {((this.props.user.id) === data.user) ?
                                  <Link to={"/news/edit/"+data.id} className="button-text-icon edit-button edit-icon-only float-right">
                                  <div className="icon-wrap">
                                    <svg viewBox="0 0 16 16" version="1.1">
                                        <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                            <g id="Buttons/Edit/Large" transform="translate(-14.000000, -7.000000)" fillRule="nonzero">
                                                <g id="Group-3">
                                                    <g id="baseline-edit-24px" transform="translate(12.391304, 5.000000)">
                                                        <polygon id="Path" points="0 0 19.1304348 0 19.1304348 20 0 20"/>
                                                        <path d="M2.39130435,14.375 L2.39130435,17.5 L5.38043478,17.5 L14.1963768,8.28333333 L11.2072464,5.15833333 L2.39130435,14.375 Z M16.507971,5.86666667 C16.8188406,5.54166667 16.8188406,5.01666667 16.507971,4.69166667 L14.6427536,2.74166667 C14.3318841,2.41666667 13.8297101,2.41666667 13.5188406,2.74166667 L12.0601449,4.26666667 L15.0492754,7.39166667 L16.507971,5.86666667 Z" id="Shape" fill="#3A4652"/>
                                                    </g>
                                                </g>
                                            </g>
                                        </g>
                                    </svg>
                                  </div>
                                  </Link>
                                  : null
                              }
                              <div className="subtext">{data.program}</div>
                              <div className="body-small">{data.message}
                              </div>
                              {data.link ?
                                  <span className="link-button-wrap">
                                  <a href={data.link} className="link-button">
                                  {data.link_title}
                                      <span className="right-align-icon">
                          <svg width="12px" height="12px" viewBox="0 0 12 12" version="1.1">
                          <g id="Symbols" stroke="none" strokeWidth="1" fill="none"
                             fillRule="evenodd">
                              <g id="AccessDataBtn" transform="translate(-57.000000, -10.000000)"
                                 fill="#3A4652" fillRule="nonzero">
                                  <polygon id="Shape"
                                           points="63 10 61.9425 11.0575 66.1275 15.25 57 15.25 57 16.75 66.1275 16.75 61.9425 20.9425 63 22 69 16"/>
                              </g>
                          </g>
                        </svg></span></a></span>
                              :
                              null
                              }
                          </div>
                      </div>
                  )
                  : <div className="view-element">Current there isn't any content</div>
              }
              </div>
          </div>
        );
    }
}

export default connect(mapStateToProps)(News);
