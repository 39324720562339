import React, { Component } from 'react';
import { connect } from 'react-redux';
import FormTextInput from "../FormTextInput";
import axios from "axios";
import Dropdown from "../Dropdown";
import RepeatTextBoxes from "../RepeatTextBoxes";
import FormRadioInputs from "../FormRadioInputs";
import StandardInput from "../StandardInput";
import VisitsEpochsArms from "../VisitsEpochsArms";
import {Link, Redirect} from "react-router-dom";

// import Cards from "./Cards";
import moment from 'moment';
import {Alert} from "react-bootstrap";
import Tags from "../Tags";

import { API } from '../../util/api';
const pStyle = {
    height: '15px'
};
const eStyle = {
    margin: "0px 5px 0px 0px"
};

const mapStateToProps = (state) => ({
  user: state.auth.user,
});

const programs = ["HBGD","MNCH","NTD","TB","HIV","Malaria","Family planning"];
const dua_status = ["DUA not required","Not contact yet","Initial discussion", "DUA in process","DUA full executed","DUA fully executed but expecting update"];
const status = ["Not Transmitted yet","Data received & data integration ongoing","Data released for use but expecting updated transfer","Obsolete","Data released for use","Expired"];
const method_types = ["Animal model","Clinical","In vitro","Registry","Survey"];
const enroll_age = ["D","W","M","Y"];

class StudyEdit extends Component {
    constructor(props) {
        super(props);

        this.state = {
            study: [],
            isLoading: true,
            reDirect: false,
            autoSave: false,
            error: null,
            arms: [],
            epochs: [],
            savedTime: "",
            isLoggedIn: false,
        };
    }

    onUnload = () => {
        this.autoSave();
    };

    autoSave = () => {
        if(this.props.match.params.studyID !== "add"){
            axios.put(API +"/study/edit/"+ this.props.match.params.studyID, this.state)
                .then((response) => {
                    this.setState({ autoSave: false });
                })
                .catch((err) => {
                    this.setState({ savedTime: "!" });
                    this.setState({ autoSave: false });
                    console.log(err);
                });
        } else {
            axios.post(API +"/study/"+ this.props.match.params.studyID, this.state)
                .then((response) => {
                    this.setState({ autoSave: false });
                })
                .catch((err) => {
                    this.setState({ savedTime: "!" });
                    this.setState({ autoSave: false });
                    console.log(err);
                });
        }
    };

    handleSubmit = (e) => {
        this.setState({ autoSave: true, reDirect: true }, () => {
            this.autoSave();
        });
    };

    onMultiChange = (e) => {
        this.setState({[e.name]: e.value }, () => {
            if(this.props.match.params.rallyID !== "add"){
                this.setState({ autoSave: true });
                this.autoSave();
            }
        });
    };

    onChange = (e) => {
        if(e.target !== undefined){
            if(e.target.name === "epoch_arms_values"){
            } else {
                let user = document.getElementById("user");
                this.setState({ user: user.defaultValue });
                this.setState({ [e.target.name]: e.target.value }, () => {
                    if(this.props.match.params.studyID !== "add"){
                        this.setState({ autoSave: true });
                        this.autoSave();
                    }
                });
            }
        }else {
            let user = document.getElementById("user");
            this.setState({ user: user.defaultValue });
            e.name === "date_data_recd" ?
                this.setState({[e.name]: e.defaultValue }, () => {
                    if(this.props.match.params.studyID !== "add"){
                        this.setState({ autoSave: true });
                        this.autoSave();
                    }
                })
                :this.setState({[e.name]: e.value }, () => {
                    if(this.props.match.params.studyID !== "add"){
                        this.setState({ autoSave: true });
                        this.autoSave();
                    }
                });
        }
    };

    componentDidMount() {
        document.body.classList.toggle('study-edit', true);
        window.addEventListener("beforeunload", this.onUnload);
        axios.get(API + "/api/v1/tags")
            .then((response) => {
                let tags = response.data.data;
                this.setState({tags_all: tags}, () => {});
            })
            .catch((err) => {
                console.log(err);
            });
            axios.get(API +"/api/v1/study/"+ this.props.match.params.studyID)
                .then((response) => {
                    if(response.data.data) {
                        let study = response.data.data;
                        if(study.columns){
                            study.columns.forEach((data, index) => {
                               this.setState({[data.COLUMN_NAME]: ""});
                            });
                            this.setState({ epochs: [""] });
                            this.setState({ arms: [""] });
                            this.setState({ visits: [""] })
                        } else {
                            for (let key in study) {
                                if (study[key] === null || study[key] === undefined) {
                                    study[key] = "";
                                }
                                this.setState({[key]: study[key]});
                            }
                        }
                        if(this.props.match.params.studyID !== "add")
                        {
                            axios.get(API +"/api/v1/user/"+ study.user)
                                .then((response) => {
                                    let user = "";
                                    if( response.data.data !== undefined){
                                        user = response.data.data.first_name +" "+ response.data.data.last_name;
                                    } else {
                                        user = "No user listed";
                                    }
                                    this.setState({ username: user }, () => {});
                                })
                                .catch((err) => {
                                    console.log(err);
                                    this.setState({ error: err });
                                });
                            let enroll_age_lower = this.state.enroll_age_lower.toString().split("','");
                            let enroll_age_upper = this.state.enroll_age_upper.toString().split("','");
                            this.setState({ enroll_age_lower: enroll_age_lower[0], enroll_age_upper: enroll_age_upper[0] });
                        }else {
                            this.setState({ autoSave: true });
                            this.setState({ enroll_age_lower: "", enroll_age_upper: "" });
                        }
                        axios.get(API +"/api/v1/tags")
                            .then((response) => {
                                let tags = response.data.data;
                                this.setState({tags_all: tags});
                            })
                            .catch((err) => {
                                console.log(err);
                                this.setState({error: err});
                            });
                        this.setState({ isLoading: false });
                    } else {
                        this.setState({ reDirect: true });
                    }
                    console.log(this.state);
                })
                .catch((err) => {
                    console.log(err);
                });
        this.setState({ enroll_age: ["D","W","M","Y"] });
    }

    componentWillUnmount() {
      document.body.classList.remove('study-edit');
      window.removeEventListener("beforeunload", this.onUnload)
    };

    componentDidCatch(error, info) {
        this.setState({ error: error, info: info });
    }

    render() {
        const {user, isLoading, error, autoSave, epochs, visits, arms, epoch_arm_values, reDirect, enroll_age_lower, enroll_age_upper} = this.state;

        if (error) {
            return <Alert className="error">{error.message}</Alert>;
        }
        if (isLoading) {
            return <p>Loading ...</p>;
        }
        if (reDirect) {
            return (<Redirect to='/studies'/>);
        }

        return (
            <div className="App">
                <div className={"row"}>
                    <div className="col-sm-2">
                        <h1><Link className="backlink" to={"/study/"+ this.state.id}>{""}</Link>{this.state.short_id}</h1>
                    </div>
                    <div className="col-sm-6">
                    </div>
                    <div className="col-sm-4 button-controls">
                        <div className="button-text-icon" onClick={this.handleSubmit} style={eStyle}>Submit</div>
                    </div>
                    {user ?
                        autoSave ?
                                <img src="/images/uploading.gif" style={pStyle}/>
                                :
                                <div className="row last-updated-box">
                                <p className="subtext">{"Last updated: " + this.state.username + ", " + moment(this.state.last_updated).format('LT')}</p>
                                </div>
                        :null
                    }

                  </div>
                  <hr className="header-hr"/>
            <form>
                <input type={"hidden"} defaultValue={this.props.user.id} id={"user"} />
                <Dropdown selected={this.state.program} title={"Program:"} name={"program"} caption={"Specify the research program"} data={programs} parentChange={this.onChange} />
                <FormTextInput name={"study_id"} parentChange={this.onChange} title={"Study ID"} caption={"Provide a unique identifier for this system"} data={this.state.study_id} />
                <Dropdown name={"status"} title={"Data status:"} caption={""} selected={this.state.data_status} data={status} parentChange={this.onChange} />
                <FormTextInput name={"date_data_recd"} parentChange={this.onChange} title={"Date data recd:"} caption={"Specify the date that the data was received. If there were multiple transfers, use the first transfer of useable data."} data={this.state.date_data_recd} type={"date"}/>
                <FormTextInput name={"data_location"} parentChange={this.onChange} title={"Data location:"} caption={"Provide URL"} data={this.state.data_location} type={"url"} />
                <FormTextInput name={"short_id"} parentChange={this.onChange} title={"Short id:"} caption={"Provide a short ID for the study, 2-8 characters, with no spaces."} maxlength={"8"} data={this.state.short_id} />
                <FormTextInput name={"study_title"} parentChange={this.onChange} title={"Study title:"} caption={"Provide study title from protocol"} data={this.state.study_title} />
                <FormTextInput name={"study_title_short"} parentChange={this.onChange} title={"Study title short:"} caption={"Suggest an abbreviated title (<= 200 chars). If the Study ID is an acronym, use this field to spell out the meaning."} data={this.state.study_title_short} />
                <FormTextInput name={"clinical_trial_registry_ids"} parentChange={this.onChange} title={"Clinical trial registry ids:"} caption={"Provide ID numbers from clinical trial registries, or specify \"Not Applicable\" or \"Pending\". Examples are ISCTRP and clinicaltrials.gov."} data={this.state.clinical_trial_registry_ids} />
                <RepeatTextBoxes name={"study_methods_reference"} parentChange={this.onMultiChange} title={"Study methods reference:"} caption={"Provide PubMed (or doi) identifiers for study methods reference(s), or specify \"Not Applicable\" or \"Pending\""} data={this.state.study_methods_reference} />
                <RepeatTextBoxes name={"study_results_reference"} parentChange={this.onMultiChange} title={"Study results reference:"} caption={"Provide PubMed (or doi) identifiers for study methods reference(s), or specify \"Not Applicable\" or \"Pending\""} data={this.state.study_results_reference} />
                <RepeatTextBoxes name={"alternative_study_id"} parentChange={this.onMultiChange} title={"Alternative study id:"} caption={"Specify alternate study identifiers"} data={this.state.alternative_study_id} />
                <RepeatTextBoxes name={"study_url"} parentChange={this.onMultiChange} title={"Study url:"} caption={"If there is a public website for the study, specify here."} data={this.state.study_url} />
                <FormRadioInputs name={"subject_level"} parentChange={this.onChange} title={"Subject level:"} caption={"Is this a subject-level dataset, or an aggregate-level dataset?"} data={this.state.subject_level}/>
                <FormRadioInputs name={"human"} parentChange={this.onChange} title={"Human:"} caption={"Is this human data?"} data={this.state.human}/>
                {this.state.human == 0 ?
                    <FormRadioInputs name={"non_human_animal"} parentChange={this.onChange} title={"Non human animal:"} caption={""} data={this.state.non_human_animal}/>
                    :<Dropdown name={"method_type"} selected={this.state.method_type} title={"Method Type:"} caption={""} parentChange={this.onChange} data={method_types}/>
                }
                {this.state.human == 0 ?
                this.state.non_human_animal == 1 ?
                    <FormTextInput name={"species"} parentChange={this.onChange} title={"Species:"}
                                   caption={"If not human, specify species"} data={this.state.species}/>
                    :<FormTextInput name={"other_subject"} parentChange={this.onChange}
                                    title={"Other subject:"}
                                    caption={"If not human or animal, what is the subject of the data?"}
                                    data={this.state.species}/>
                :null
                }

                <FormRadioInputs name={"longitudinal"} parentChange={this.onChange} title={"Longitudinal:"} caption={"Is longitudinal"} data={this.state.longitudinal}/>
                <FormRadioInputs name={"randomized"} parentChange={this.onChange} title={"Randomized:"} caption={"Is a randomized study?"} data={this.state.randomized}/>
                {this.state.randomized == 1 ?
                    <Dropdown name={"rand_type"} selected={this.state.rand_type} title={"Rand type:"} parentChange={this.onChange} data={["individual","cluster"]}/>
                    :null
                }
                <FormTextInput name={"rand_details"} parentChange={this.onChange} title={"Rand details:"} caption={"Provide other details about the randomization, such as the stratification factor(s) and the composition of clusters (e.g., villages)."} data={this.state.rand_details} />
                <FormRadioInputs name={"controlled"} parentChange={this.onChange} title={"Controlled:"} caption={"Is a controlled study?"} data={this.state.controlled}/>
                {this.state.controlled == 1 ?
                    <Dropdown name={"control_type"} selected={this.state.control_type} title={"Control type"} parentChange={this.onChange} data={["placebo","positive control","historic control","standard of care"]} />
                    :null
                }
                <FormRadioInputs name={"interventional"} parentChange={this.onChange} title={"Interventional:"} caption={"Is interventional?"} data={this.state.interventional}/>
                {this.state.interventional == 1 ?
                    <Dropdown name={"intervention_type"} selected={this.state.interventional_type} caption={""} title={"Intervention type"} parentChange={this.onChange} data={["behavioral","educational","healthcare process","integrated management","medical device","nutritional"]}/>
                    :null
                }
                <StandardInput name={"number_intervention_arms"} title={"Number intervention arms:"} caption={"Specify number of intervention arms"} notRadio={true} data={arms.length} splitBool={false} />
                <VisitsEpochsArms data={arms} name={"arms"} type={"arms"} parentChange={this.onChange}/>
                <FormTextInput name={"subject_count"} type={"number"} parentChange={this.onChange} title={"Subject count:"} caption={"Specify number of subjects with useable data included in the data transfer. For example, exclude screen failure subjects from the count."} data={this.state.subject_count}/>
                <FormTextInput name={"primary_objective"} parentChange={this.onChange} title={"Primary objective"} type={"textarea"} caption={""} data={this.state.primary_objective}/>
                <RepeatTextBoxes name={"secondary_objective"} parentChange={this.onMultiChange} title={"Secondary objective:"} caption={""} data={this.state.secondary_objective} />
                <FormTextInput name={"start_year"} parentChange={this.onChange} title={"Start year:"} caption={"First year of enrollment"} data={this.state.start_year} />
                <FormTextInput name={"stop_year"} parentChange={this.onChange} title={"Stop year:"} caption={"Last year of follow-up (or specify if ongoing)"} data={this.state.stop_year} />
                {enroll_age_lower[0] ?
                    <FormTextInput name={"enroll_age_lower"} type={"number"} parentChange={this.onChange} title={"Enroll age lower:"} caption={"Specify the lower limit of the enrollment age"} data={enroll_age_lower[0]} />
                    :<FormTextInput name={"enroll_age_lower"} type={"number"} parentChange={this.onChange} title={"Enroll age lower:"} caption={"Specify the lower limit of the enrollment age"} data={""} />
                }
                {enroll_age_lower[1] ?
                    <Dropdown name={"enroll_age_lower"} selected={enroll_age_lower[1]} data={enroll_age} parentChange={this.onChange} />
                    :<Dropdown name={"enroll_age_lower"} selected={""} data={enroll_age} parentChange={this.onChange} />
                }
                {enroll_age_upper[0] ?
                    <FormTextInput name={"enroll_age_upper"} type={"number"} parentChange={this.onChange} title={"Enroll age upper:"} caption={"Specify the upper limit of the enrollment age"} data={enroll_age_upper[0]} />
                    :<FormTextInput name={"enroll_age_upper"} type={"number"} parentChange={this.onChange} title={"Enroll age upper:"} caption={"Specify the upper limit of the enrollment age"} data={""} />
                }
                {enroll_age_upper[1] ?
                    <Dropdown name={"enroll_age_upper"} selected={enroll_age_upper[1]} data={enroll_age} parentChange={this.onChange} />
                    :<Dropdown name={"enroll_age_upper"} selected={""} data={enroll_age} parentChange={this.onChange} />
                }
                <FormTextInput name={"population"} type={"textarea"} parentChange={this.onChange} title={"Population:"} caption={"Population Description. Omit the country."} data={this.state.population} />
                <RepeatTextBoxes name={"inclusion_criteria"} parentChange={this.onMultiChange} title={"Inclusion criteria:"} caption={"Inclusion criteria (verbatim from protocol)"} data={this.state.inclusion_criteria} />
                <RepeatTextBoxes name={"exclusion_criteria"} parentChange={this.onMultiChange} title={"Exclusion criteria:"} caption={"Exclusion criteria (verbatim from protocol)"} data={this.state.exclusion_criteria} />
                <Dropdown name={"country"} title={"Country:"} caption={""} parentChange={this.onChange} multi={"true"} data={this.state.countries} selected={this.state.country} />
                <VisitsEpochsArms data={epochs} type={"epochs"} title={"Add epochs"} parentchange={this.onChange}/>
                <VisitsEpochsArms data={visits} epochs={epochs} type={"visits"} title={"Add visits"} parentchange={this.onChange}/>

                <div className='view-element'>
                    <label>Element matrix</label>
                    {epochs && arms && epoch_arm_values ?
                        <table>
                            <thead>
                            <tr>
                                <th className="heading-3"/>
                                {epochs.map((data, i) =>
                                    <th className="heading-3" key={i}>{data.epoch_label}</th>
                                )}
                            </tr>
                            </thead>
                            <tbody>
                            {arms.map((data,i) =>
                                <tr key={i}>
                                    <th className="heading-3">{data.arm_description_short}</th>
                                    {epochs.map((data,i) =>
                                        <td className="body-text" key={i}>
                                            <Tags data={epoch_arm_values[i].value} parentChange={this.onChange} suggestions={this.state.tags_all} name={"epoch_arm_values[value]"} />
                                        </td>
                                    )}
                                </tr>
                            )}
                            </tbody>
                        </table>
                        :<div className='view-element'>No data for this field</div>
                    }
                </div>
                {/*<Cards type={"element_matrix_input"} epochs={epochs} arms={arms} values={epoch_arm_values} parentChange={this.onChange} view={"element_matrix_input"} suggestions={this.state.tags_all} />*/}
                <FormTextInput name={"miscellaneous_notes"} parentChange={this.onChange} title={"Miscellaneous Notes:"} type={"text"} caption={"Miscellaneous notes about the study"} data={this.state.miscellaneous_notes} />
                <FormTextInput name={"scope_of_data"} parentChange={this.onChange} title={"Scope of data:"} type={"textarea"} caption={"Specify the scope of data included in the transfer, including to describe data that was omitted"} data={this.state.scope_of_data} />
                <FormRadioInputs name={"public_use"} parentChange={this.onChange} title={"Public use:"} caption={"Is the data available for public use (e.g., can download from the internet w/o login required)"} data={this.state.public_use}/>
                <Dropdown name={"dua_status"} title={"Dua status:"} caption={"What is the status of the MOU (data use agreement)?"} selected={this.state.dua_status} data={dua_status} parentChange={this.onChange} />
                <FormTextInput name={"dua_reason_not_req"} parentChange={this.onChange} title={"Dua reason not req:"} type={"textarea"} caption={"Specify the reason why a DUA is not required"} data={this.state.dua_reason_not_req} />
                <FormTextInput name={"dua_restrictions"} parentChange={this.onChange} title={"Dua restrictions:"} type={"textarea"} caption={"Specify the nature of the DUA restrictions"} data={this.state.dua_restrictions} />
                <FormTextInput name={"dua_counterparty"} parentChange={this.onChange} title={"Dua counterparty:"} type={"textarea"} caption={"Specify the institution(s)"} data={this.state.dua_counterparty} />
                <FormTextInput name={"dua_restrictions"} parentChange={this.onChange} title={"Dua restrictions:"} type={"textarea"} caption={"Specify the nature of the DUA restrictions"} data={this.state.dua_restrictions} />
                <FormTextInput name={"reason_for_interest"} parentChange={this.onChange} title={"Reason for interest:"} type={"textarea"} caption={"Describe the reason this data is of interest"} data={this.state.reason_for_interest} />
                <Dropdown name={"priority"} title={"Priority: "} caption={"Priority for acquisition"} selected={this.state.priority} data={["Low","Medium","High"]} parentChange={this.onChange} />
                <RepeatTextBoxes name={"contact_note"} parentChange={this.onMultiChange} title={"Contact note:"} type={"textarea"} caption={"Describe contact with the PI or study sponsor"} data={this.state.contact_note} />
                <RepeatTextBoxes name={"date_of_contact_note"} type={"date"}  parentChange={this.onMultiChange} title={"Date of contact note:"} caption={""} data={this.state.date_of_contact_note} />
                <RepeatTextBoxes name={"follow_up_plan"} parentChange={this.onMultiChange} title={"Follow up plan:"} type={"textarea"} caption={"Describe plans to follow up with the PI or study sponsor"} data={this.state.follow_up_plan} />
                <RepeatTextBoxes name={"date_of_follow_up_plan"} parentChange={this.onMultiChange} title={"Date of follow up plan:"} type={"date"} caption={""} data={this.state.date_of_follow_up_plan} />
                <StandardInput parentChange={this.onChange} title={"Last updated:"} caption={""} data={this.state.last_updated} splitBool={false} />
                <FormRadioInputs name={"active"} parentChange={this.onChange} title={"Active:"} caption={""} data={this.state.active} />
            </form>
        </div>
        );

    }
}

export default connect(mapStateToProps)(StudyEdit);
