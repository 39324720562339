import { hubApi } from '../util/api';
import {deleteUserFail, deleteUserSuccess, deletingUser, fetchUsers} from "./users";
import { push } from 'connected-react-router';
import {receiveUser, updateAuthStatus} from "./auth";

export const LOADING_RALLIES = 'LOADING_RALLIES';
export const RALLIES_SUCCESS = 'RALLIES_SUCCESS';
export const RALLIES_FAIL = 'RALLIES_FAIL';

export const FETCHING_RALLY = 'LOADING_RALLY';
export const RALLY_SUCCESS = 'RALLY_SUCCESS';
export const RALLY_FAIL = 'RALLY_FAIL';
export const UNMOUNT_RALLY = 'UNMOUNT_RALLY';

export const UPDATING_RALLY = 'UPDATING_RALLY';
export const UPDATE_SUCCESS = 'UPDATE_SUCCESS';
export const UPDATE_FAIL = 'UPDATE_FAIL';

export const CREATING_RALLY = 'CREATING_RALLY';
export const CREATE_SUCCESS = 'CREATE_SUCCESS';
export const CREATE_FAIL = 'CREATE_FAIL';

export const fetchingRallies = () => ({
  type: LOADING_RALLIES
});

export const ralliesSuccess = rallies => ({
  type: RALLIES_SUCCESS,
  rallies,
  receivedAt: Date.now()
});

export const ralliesFail = error => ({
  type: RALLIES_FAIL,
  error
});



export const fetchingRally = () => ({
  type: FETCHING_RALLY
});

export const rallySuccess = rally => ({
  type: RALLY_SUCCESS,
  rally,
  receivedAt: Date.now()
});

export const rallyFail = error => ({
  type: RALLY_FAIL,
  error
});

export const resetRally = () => ({
  type: UNMOUNT_RALLY
});


export const updatingRally = () => ({
  type: UPDATING_RALLY
});

export const updateSuccess = rally => ({
  type: UPDATE_SUCCESS,
  rally,
  receivedAt: Date.now()
});

export const updateFail = error => ({
  type: UPDATE_FAIL,
  error
});


export const creatingRally = () => ({
  type: CREATING_RALLY
});

export const createSuccess = rally => ({
  type: CREATE_SUCCESS,
  rally,
  receivedAt: Date.now()
});

export const createFail = error => ({
  type: CREATE_FAIL,
  error
});

/*
 * Fetch rallies data
 * @param {object} credentials [Required]
 */
export const fetchRallies = () => (dispatch, getState) => {
  dispatch(fetchingRallies());

  const getUserData = (userId, userList) => {
    let user = userList.find(user => user.id === userId);
    if (user) {
      if (user.first_name !== undefined) {
        return user.first_name + " " + user.last_name;
      } else {
        return "No User Listed";
      }
    } else {
      return "No User Listed";
    }
  };

  const endpoint = hubApi().endPoints.rallies;

  const getRallies = (users) => hubApi().get(endpoint)
    .then((response) => {
      let rallyData = response.data.data;
      rallyData.forEach((rally, i) => {
        rallyData[i].username = getUserData(rally.user, users);
        rally.participants.forEach((p, pIndex) => {
          p = p.split("-");
          if (p[0] !== "") {
            rally.participants[pIndex] = p[0].trim();
          }
        })
        console.log(rally)
        if (rally.raw_roles) {
          console.log(rally.raw_roles)
          let rolesList = []
          rally.raw_roles.forEach((data) => {
            rolesList.push(data.role)
          })
          rally.roles = rolesList;
        }
      });
      dispatch(ralliesSuccess(rallyData));
    })
    .catch((error) => {
      dispatch(ralliesFail(error))
    });

  const state = getState();
  if (state.users.loaded) {
    getRallies(state.users.users);
  } else {
    dispatch(fetchUsers()).then((userList) => {
      getRallies(userList);
    });
  }

};


/*
 * Fetch single rally
 * @param {object} credentials [Required]
 */
export const fetchRallyById = id => (dispatch, getState) => {
  dispatch(fetchingRally());

  const getUserData = (userId, userList) => {
    let user = userList.find(user => user.id === userId);
    if (user) {
      if (user.first_name !== undefined) {
        return user.first_name + " " + user.last_name;
      } else {
        return "No User Listed";
      }
    } else {
      return "No User Listed";
    }
  };

  const endpoint = hubApi().endPoints.default;

  const getRally = (users) => hubApi().get(endpoint + '/analysis/' + id)
    .then((response) => {
      let rally = response.data.data;
      let roles = [];
      let participants = [];
      let pIDs = [];

      rally.username = getUserData(rally.user, users);

      if (rally.final_documentation) {
        rally.final_documentation = JSON.parse(rally.final_documentation);
      } else {
        rally.final_documentation = [];
      }

      if (rally.raw_roles) {
        rally.raw_roles.forEach((data) => {
          let name;
          if (data.first_name) {
            name = data.first_name + " " + data.last_name;
          } else {
            name = "No user listed"
          }
          participants.push({id: data.user_id, name });
          roles.push(data.role);
          // roles.push({
          //   name,
          //   role: data.role
          // });
        })
      }

      rally.roles = roles;
      rally.participants = participants;
      dispatch(rallySuccess(rally));
    })
    .catch((error) => {
      console.log(error);
      dispatch(rallyFail());
    });

  const state = getState();
  if (state.users.loaded) {
    getRally(state.users.users);
  } else {
    dispatch(fetchUsers()).then((userList) => {
      getRally(userList);
    });
  }
};

/*
 * Get Roles
 */
export const fetchRallyRoles = (id) => () => new Promise((resolve, reject) => {
  const endpoint = hubApi().endPoints.default;

  hubApi().get(endpoint + '/analysis/' + id)
    .then((response) => {
      let rally = response.data.data;
      let roles = [];
      let participants = [];

      if (rally.raw_roles) {
        rally.raw_roles.forEach((data) => {
          let name;
          if (data.first_name) {
            name = data.first_name + " " + data.last_name;
          } else {
            name = "No user listed"
          }
          participants.push(data.user_id);
          roles.push(data.role);
        })
      }

      resolve({roles, participants})
    })
    .catch(() => {
      reject('Could not fetch roles')
    })
})


/*
 * Update rally
 * @param {int} id [Required]
 * @param {object} payload [Required]
 */
export const updateRally = (id, data, auto) => (dispatch) => new Promise((resolve, reject) =>{
  dispatch(updatingRally());

  const endpoint = hubApi().endPoints.updateRally;

  // Use this to format data so the API can receive it - look into updating API on this
  const formatData = (payload) => {
    let fData = payload;
    let participants = [];
    fData.participants.forEach((user) => {
      participants.push(user.id)
    });
    fData.participants = participants;
    return fData;
  };

  if (data) {
    const payload = formatData(data);
    console.log(payload)
    hubApi().put(endpoint + id, payload)
      .then((response) => {
        dispatch(updateSuccess(response));
        if (!auto) {
          dispatch(push('/rally/' + id));
        }
        resolve(response);
      })
      .catch((error) => {
        dispatch(updateFail(error));
        reject(error);
      })
  } else {
    dispatch(updateFail("No data delivered"));
    reject("No data");
  }

});


/*
 * Create rally
 * @param {object} payload [Required]
 */
export const createRally = (data) => (dispatch) => new Promise((resolve, reject) => {
  dispatch(creatingRally());

  const endpoint = hubApi().endPoints.createRally;
  const update = hubApi().endPoints.updateRally;

  // Implement a check on data object
  if (data) {
    hubApi().post(endpoint, data)
      .then((response) => {
        let id = response.data.data;
        console.log(response);
        dispatch(createSuccess(id));

        let payload = data;
        payload.id = id;

        hubApi().put(update + id, payload)
          .then((response) => {
            dispatch(updateSuccess(response));
            dispatch(push('/rally/edit/' + id));
            resolve(id);
          })
          .catch((err) => {
            console.log("Couldnt update");
            reject("Couldnt update");
          })
      })
      .catch((error) => {
        dispatch(createFail(error))
      })
  } else {
    console.log("error");
    dispatch(createFail("No data delivered"))
  }

});


/*
 * Redirect rally
 * @param {int} id [Required]
 */
export const redirectToEdit = (id) => (dispatch) => {
  dispatch(push('/rally/edit/' + id))
};



/*
 * Delete Rally
 */
export const DELETING_RALLY = 'DELETING_RALLY';
export const DELETE_RALLY_SUCCESS = 'DELETE_RALLY_SUCCESS';
export const DELETE_RALLY_FAIL = 'DELETE_RALLY_FAIL';

export const deletingRally = () => ({
  type: DELETING_RALLY
});

export const deleteRallySuccess = () => ({
  type: DELETE_RALLY_SUCCESS,
  receivedAt: Date.now()
});

export const deleteRallyFail = error => ({
  type: DELETE_RALLY_FAIL,
  error
});

/*
 * Delete Rally
 * @param {id} rallyID [Required]
 */
export const deleteRally = (id) => (dispatch) => {
  dispatch(deletingUser());

  const endpoint = hubApi().endPoints.updateRally;

  hubApi().delete(endpoint + id)
    .then((response) => {
      console.log(response);
      dispatch(deleteRallySuccess());
      dispatch(push('/rallies'));
    })
    .catch((error) => {
      console.log(error);
      dispatch(deleteUserFail(error))
    })
};