import {
  LOADING_USERS,
  USERS_SUCCESS,
  USERS_FAIL,
  LOADING_USER,
  USER_SUCCESS,
  USER_FAIL,
  UPDATING_USER,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_FAIL
} from "../actions/users";

const initialState = {
  loading: false,
  loaded: false,
  users: [],
  error: null,
  single: {
    loading: false,
    loaded: false,
    user: [],
    error: null
  },
  update: {
    loading: false,
    updated: false,
    user: [],
    error: null,
  }
};

export default function userReducer(state = {...initialState}, action) {
  switch (action.type) {
    case LOADING_USERS:
      return {
        ...state,
        loading: true,
      };
    case USERS_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        users: action.users,
        error: null,
      };
    case USERS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case LOADING_USER:
      return {
        ...state,
        single: {
          ...state.single,
          loading: true,
        }
      };
    case USER_SUCCESS:
      return {
        ...state,
        single: {
          ...state.single,
          loading: false,
          loaded: true,
          user: action.user,
          error: null
        }
      };
    case USER_FAIL:
      return {
        ...state,
        single: {
          ...state.single,
          loading: false,
          error: action.error
        }
      };
    case UPDATING_USER:
      return {
        ...state,
        update: {
          ...state.create,
          loading: true,
          updated: false,
        }
      };
    case UPDATE_USER_SUCCESS:
      return {
        ...state,
        update: {
          ...state.create,
          loading: false,
          updated: true,
          user: action.user,
          error: null
        }
      };
    case UPDATE_USER_FAIL:
      return {
        ...state,
        update: {
          ...state.create,
          loading: false,
          error: action.error
        }
      };
    default:
      return state;
  }
};