import React, {Component} from 'react';
import FollowLink from "./FollowLink";

class StandardInput extends Component {
    constructor(props) {
        super(props);

    }

    componentDidMount = () => {

    };

    render() {
        let data = this.props.data +"";
        const title = this.props.title;
        const splitBool = this.props.splitBool;
        if( data === "null" ){
            return (
                <div className='view-element'>
                    <label>{title}</label>
                    <p>No data for this field</p>
                </div>
            );
        }
        if(! this.props.notRadio){
            if(data === "1"){
                data = "yes";
            }
            if(data === "0"){
                data = "no";
            }
        }
        if(splitBool){
            if(data.split("','")){
                let dlist = data.split("','");
                return (
                    <div className='view-element'>
                        <label>{title}</label>
                        {dlist.map((data,index) =>
                            <p key={index}>{data}</p>
                        )}
                    </div>
                );
            }else{
                return (
                    <div className='view-element'>
                        <label>{title}</label>
                        <p>No data for this field</p>
                    </div>
                );
            }
        } else {
            if(data){
                let regex = new RegExp("https?:\\/\\/(www\\.)?[-a-zA-Z0-9@:%._\\+~#=]{2,256}\\.[a-z]{2,6}\\b([-a-zA-Z0-9@:%_\\+.~#?&//=]*)\n", 'i');
                if(data.match(regex)){
                    return (
                        <FollowLink title={title} data={data} />
                    );
                }
                return (
                    <div className='view-element'>
                        <label>{title}</label>
                        <p>{data.replace(/','/g,", ")}</p>
                    </div>
                );
            }else {
                return (
                    <div className='view-element'>
                        <label>{title}</label>
                        <p>No data for this field</p>
                    </div>
                );
            }

        }
    }
}

export default StandardInput;