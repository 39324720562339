import { combineReducers } from 'redux';

import { connectRouter } from 'connected-react-router';

// import article from './article';
import auth from './auth';
import rallies from './rallies';
import users from './users';
import studies from './studies'
import synapse from './synapse';
import upload from './upload';

export default (history) => combineReducers({
  router: connectRouter(history),
  auth,
  rallies,
  users,
  studies,
  synapse,
  upload
});