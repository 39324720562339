import React, { Component } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';

import LoadingScreen from './Loading';

const mapStateToProps = state => ({
  auth: state.auth
});

class PrivateRoute extends Component {
  render() {
    if (this.props.auth.checkedAuth) {
      if (this.props.auth.loggedIn) {
        return (
          <Route {...this.props} />
        )
      } else {
        return (
          <Redirect to={{
            pathname: '/login',
            state: { from: this.props.location }
          }}/>
        )
      }
    } else {
      return (
        <LoadingScreen />
      )
    }
  }
}

export default connect(mapStateToProps)(PrivateRoute);