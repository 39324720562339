import React, {Component} from 'react';
import Textarea from 'react-textarea-autosize';
import {formatDate,parseDate,} from 'react-day-picker/moment';
import 'react-day-picker/lib/style.css';
const pStyle = {
    width: '100%'
};

class FormTextInput extends Component {
    constructor(props) {
        super(props);

        this.state = {
            selectedDate: "",
            error: ""
        };
    }

    componentDidCatch(error, info) {
        this.setState({ error: error });
    }

    onChange = (e) => {
        this.props.parentChange(e);
    };

    render() {
        const {data, caption, title, maxlength, type, name, error, placeholder, id} = this.props;

        if (error) {
            return <p className="error">{error}</p>;
        }
        if (type === "textarea") {
            if (data === "null" || data === "undefined" || data === null || data === undefined) {
                return (
                    <div className='view-element sign-in-field'>
                        <label htmlFor={name}>{title}</label>
                        <div className='caption-text'>{caption}</div>
                        <Textarea name={name}
                                  id={id}
                                  onBlur={this.onChange}
                                  defaultValue={data}
                                  maxLength={maxlength}
                                  rows="4"
                                  style={pStyle}
                                  autoComplete="off"/>
                    </div>
                );
            } else {
                return (
                    <div className='view-element sign-in-field'>
                        <label htmlFor={name}>{title}</label>
                        <div className='caption-text'>{caption}</div>
                        <Textarea name={name}
                                  id={id}
                                  onBlur={this.onChange}
                                  defaultValue={data}
                                  maxLength={maxlength}
                                  rows="4"
                                  style={pStyle}
                                  autoComplete="off"/>
                    </div>
                );
            }
        } else {
            // if (this.props.type === "date") {
            //     return (
            //         <div className='view-element sign-in-field'>
            //             <label htmlFor={name}>{title}</label>
            //             <div className='caption-text'>{caption}</div>
            //             <DayPickerInput
            //                 value={data}
            //                 format={"YYYY-MM-DD"}
            //                 onDayChange={this.onChange}
            //                 name={name}
            //                 id={name}
            //                 style={pStyle}
            //                 placeholder={"YYYY-MM-DD"}/>
            //         </div>
            //     );
            // }
            if(this.props.required !== null && this.props.required){
                return (
                    <div className='view-element sign-in-field'>
                        <label htmlFor={name}>{title}</label>
                        <div className='caption-text'>{caption}</div>
                        <input name={name}
                               type={type}
                               id={id}
                               onBlur={this.onChange}
                               defaultValue={data}
                               placeholder={placeholder}
                               maxLength={maxlength}
                               autoComplete="off"
                               required/>
                    </div>
                );
            }
            return (
                <div className='view-element sign-in-field'>
                    <label htmlFor={name}>{title}</label>
                    <div className='caption-text'>{caption}</div>
                    <input name={name}
                           type={type}
                           id={id}
                           onBlur={this.onChange}
                           defaultValue={data}
                           placeholder={placeholder}
                           maxLength={maxlength}
                           autoComplete="off"/>
                </div>
            );
        }
    }
}

export default FormTextInput;