import {
  UPLOADING_FILE,
  UPLOAD_SUCCESS,
  UPLOAD_FAIL
} from "../actions/upload";

const initialState = {
  loading: false,
  file: null,
  error: null
};

export default function uploadReducer(state = {...initialState}, action) {
  switch (action.type) {
    case UPLOADING_FILE:
      return {
        ...state,
        loading: true,
      };
    case UPLOAD_SUCCESS:
      return {
        ...state,
        loading: false,
        file: action.file
      };
    case UPLOAD_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    default:
      return state;
  }
};