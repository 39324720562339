import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';

import { API } from '../../util/api';
const PATH = "http://api.cognitionstudio.com";

class Header extends Component {
  constructor(props) {
    super(props);

    this.state = {
      personalmenu: false,
    };
  }

  handleLogOut = () => {
    localStorage.setItem("token", "");
    window.location.reload();
  };

  onChange = () => {
    this.setState({ personalmenu: !this.state.personalmenu });
  };

  render() {
    const {personalmenu } = this.state;
    let path = this.props.location.pathname;
    if (!this.props.auth.checkedAuth) return null;
    if(this.props.auth.loggedIn) {
      return (
        <header>
          <div className="sliding-menu">
            <img width="140" src="/images/ki.svg" className="main-logo"/>
            <hr/>
            {/*<Link to='/' className={`menu-item sidemenu-text nav-feed ${path === '/' && 'active'}`}>*/}
              {/*<img src="/images/nav-icon-feed.svg"/>*/}
              {/*KI feed*/}
            {/*</Link>*/}
            {/*<Link to='/news' className={`menu-item sidemenu-text nav-news ${path === '/news' && 'active'}`}>*/}
              {/*<img src="/images/nav-icon-news.svg"/>*/}
              {/*News*/}
            {/*</Link>*/}
            <Link
              to='/rallies'
              className={`menu-item sidemenu-text nav-rallies ${path.includes('rallies') || path.includes('rally') ? 'active' : null}`}
            >
              <img src="/images/nav-icon-rallies.svg"/>
              Rallies
            </Link>
            {/*<Link to='/studies' className={`menu-item sidemenu-text nav-studies ${path === '/studies' && 'active'}`}>*/}
              {/*<img src="/images/nav-icon-studies.svg"/>*/}
              {/*Studies*/}
            {/*</Link>*/}
            <Link to='/people' className={`menu-item sidemenu-text nav-people ${path.includes('people') && 'active'}`}>
              <img src="/images/nav-icon-people.svg"/>
              People
            </Link>
            <hr/>
            <Link to={"/people/" + this.props.user.id} className="profile-pic header-img">
              <img
                className="profile-img"
                src={API + "/public/uploads/user/" + this.props.user.id + "/profile_pic/" + this.props.user.profile_pic}
                onError={(e) => {
                  e.target.src = API + "/public/uploads/accountphoto.png"
                }}
              />
            </Link>

            <div
              id="personal-menu-reveal"
              className={personalmenu ? "personal-menu-revealed" : null}
              onClick={this.onChange}>
            </div>

            {personalmenu ?
              <div className="personal-menu">
                <Link to={"/people/" + this.props.user.id} className="menu-item sidemenu-text nav-people">Profile</Link>
                <Link to={"/login"} onClick={this.handleLogOut} className="menu-item sidemenu-text nav-people">Log out</Link>
              </div>
              :null
            }

          </div>
        </header>
      );
    } else {
      return (
        <div className="login-header">
          <img width="140" src="/images/ki.svg" className="main-logo"/>
        </div>
      );
    }
  }
}
export default withRouter(Header);
