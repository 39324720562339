import React, { Component } from "react";
import {AuthConsumer} from "react-check-auth";
import {Redirect, Link} from "react-router-dom";
import axios from "axios";
import moment from 'moment';

import { API } from '../../util/api';
class RallyTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            error: null,
            spacing: "200",
        };
    }

    componentWillMount() {
        axios.get(API +"/api/v1/updates/analysis/10")
            .then((response) => {
                let rallies = response.data.data;
                this.setState({ rallies: rallies });
                let ret = [];
                rallies.forEach((data, idx) => {
                    axios.get(API + "/api/v1/user/" + data.user)
                        .then((response) => {
                            let user = "";
                            if (response.data.data && response.data.data.first_name !== undefined) {
                                ret = response.data.data;
                                user = ret.first_name +" "+ ret.last_name;
                            } else {
                                user = "No user available";
                            }
                            rallies[idx].user_name = user;
                            this.setState({ rallies });
                        })
                        .catch((err) => {
                            console.log(err);
                        });
                    if (data.updated_date === data.created_date || data.status === "pre-planning" || data.status === "planning") {
                        rallies[idx].message = "Rally started";
                    } else if (data.status === "complete"){
                        rallies[idx].message = "Rally completed";
                    } else {
                        rallies[idx].message = "Sprint updated";
                    }
                    if(data.dataset_list){
                        rallies[idx].program = data.dataset_list.replace(/','/g,", ");
                    }
                    this.setState({ rallies: rallies });
                });
                axios.get(API +"/api/v1/analysis")
                    .then((response) => {
                        let total = response.data.data;
                        let active = total.filter(function (obj) {
                            return obj.status !== "complete";
                        });
                        let complete = total.filter(function (obj) {
                            return obj.status === "complete";
                        });
                        this.setState({ active: active });
                        this.setState({ complete: complete });
                        this.setState({ isLoading: false });
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            })
            .catch((err) => {
                console.log(err);
            });}

    componentDidCatch(error, info) {
        this.setState({ error: error });
        this.setState({ info: info });
    }

    render() {
        const {complete, active, rallies, isLoading, error} = this.state;

        if (error) {
            return <p className="error">{error.message}</p>;
        }
        if (isLoading) {
            return <p>Loading ...</p>;
        }


        return (
            <div>
                <div className="feed-header">
                    <div className="feed-header-col feed-header-left-col">
                        <Link to={"/rallies"}>Rallies</Link>
                    </div>
                    <div className="feed-header-col feed-header-right-col">
                        <div className="feed-info">{active.length} active sprints</div>
                        <div className="feed-info">{complete.length} completed</div>
                        <Link to={"/rally/edit/add"} className="button-text-icon add-button add-icon-only float-right display-none">
                        <div className="icon-wrap">
                          <svg viewBox="0 0 12 12" version="1.1">
                              <defs/>
                              <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                  <g id="buttons/addItem" transform="translate(-15.000000, -10.000000)" fillRule="nonzero" stroke="#4EB8C1" strokeWidth="2">
                                      <g id="Group-25" transform="translate(15.000000, 10.000000)">
                                          <path d="M6.0625,0 L6.0625,12" id="Path-4"/>
                                          <path d="M0,6.0625 L12,6.0625" id="Path-5"/>
                                      </g>
                                  </g>
                              </g>
                          </svg>
                        </div>
                        </Link>
                    </div>
                </div>
            <div className="feed-content">
                    {rallies != null ?
                        rallies.map((data,index) =>
                                <div className="feed-item" key={index}>
                                    <div className="feed-item-header-col feed-item-left-col subtext">Last updated: {data.user_name}, {moment(data.updated_date).format('MMM. Do')}</div>
                                    <p className="body-small">{data.message}</p>
                                    <Link to={"/rally/"+data.id} className="label">{data.rally_number +""+ data.sprint_letter}: {data.sprint_title}</Link>
                                </div>
                        )
                        :<div className='view-element'>No data for this field</div>
                    }
                </div>
            </div>
        );
      }

}
export default RallyTable;
