import React, { Component } from 'react';
import { connect } from 'react-redux';

import moment from 'moment';
import Cards from '../Cards.js';
import { BasicView, StatusChart, LinkList, SingleLink, TextList, FinalImpact } from "../partials/ViewBlock";
import FollowLink from '../FollowLink';
import StandardInput from '../StandardInput';
import LoadingScreen from '../partials/Loading';
import { Tabs, Tab } from 'react-bootstrap';

import { Link } from 'react-router-dom';
import { fetchRallyById, resetRally } from '../../actions/rallies';
import {API} from "../../util/api";

const mapStateToProps = (state) => ({
  user: state.auth.user,
  level: state.auth.level,
  rally: state.rallies.selected.rally,
  loading: state.rallies.selected.loading,
  error: state.rallies.selected.error,
  loaded: state.rallies.selected.loaded
});

const mapDispatchToProps = dispatch => ({
  getRally: (id) => (
    dispatch(fetchRallyById(id))
  )
});

const eStyle = {
    margin: "0px 5px 0px 0px"
};

class Rally extends Component {

  constructor(props) {
    super(props);

    this.state = {
      key: localStorage.getItem("current_tab") ? parseInt(localStorage.getItem("current_tab")) : 0,
      loading: false,
    };
    this.changeKey = this.changeKey.bind(this);
  }

  changeKey = (key) => {
    this.setState({key}, () => {
      localStorage.setItem("current_tab", key);
    });
    let el = document.getElementsByClassName("tab-content");
    el[0].scrollTo(0,0);
  };

  componentWillUnmount() {
    document.body.classList.remove('rally');
    document.body.classList.remove('tab-view');
  };

  componentDidMount() {
    document.body.classList.toggle('rally', true);
    document.body.classList.toggle('tab-view', true);
    this.props.getRally(this.props.match.params.rallyID);
  }

  render() {
    const { rally } = this.props;

    if (this.props.error) return <p className="error">{this.props.error}</p>;
    if (this.props.loading) return <LoadingScreen interior />;

    if (this.props.rally) {
      let isOSF = false;
      if (rally.sprint_osf) {
        let url = rally.sprint_osf;
        let slug = url.split('.');
        if (slug[0].slice(-3) === 'osf') isOSF = true;
      }
      return (
        <div className="right-column-content">
          <div className="interior-header">
            <div className="row">
              <div className="col-sm-8 header-title">
                <h1>
                  <Link className="backlink" to={"/rallies"}>
                    {""}
                  </Link>
                  {rally.rally_number + rally.sprint_letter}
                </h1>
                <p className="body-small">{rally.sprint_title}</p>
              </div>

              <div className="col-sm-4 button-controls">

                {rally.ppt_slides &&
                  <a href={API + '/' + rally.ppt_slides} className="button-text-icon icon-right data-link" style={eStyle} download>
                    PPT
                    <span className="right-align-icon">
                      <svg width="12px" height="12px" viewBox="0 0 12 12" version="1.1">
                        <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                          <g id="linkBoxSmall" transform="translate(-171.000000, -10.000000)" fill="#FFFFFF"
                             fillRule="nonzero">
                            <g id="Group-8">
                                <polygon
                                  id="Shape-Copy-2"
                                  points="177 10 175.9425 11.0575 180.1275 15.25 171 15.25 171 16.75 180.1275 16.75 175.9425 20.9425 177 22 183 16"
                                />
                            </g>
                          </g>
                        </g>
                    </svg>
                    </span>
                  </a>
                }

                {rally.sprint_osf !== null &&
                  <a href={rally.sprint_osf} target={"_blank"} className="button-text-icon icon-right data-link"
                     style={eStyle}>
                    {isOSF ? 'OSF' : 'Synapse'}
                    <span className="right-align-icon">
                      <svg width="12px" height="12px" viewBox="0 0 12 12" version="1.1">
                        <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                          <g id="linkBoxSmall" transform="translate(-171.000000, -10.000000)" fill="#FFFFFF"
                             fillRule="nonzero">
                            <g id="Group-8">
                              <polygon id="Shape-Copy-2"
                                       points="177 10 175.9425 11.0575 180.1275 15.25 171 15.25 171 16.75 180.1275 16.75 175.9425 20.9425 177 22 183 16"/>
                            </g>
                          </g>
                        </g>
                      </svg>
                    </span>
                  </a>
                }

                {this.props.level === 0 || this.props.level === 1 ?
                  null
                  :
                  <Link to={"/rally/edit/" + rally.id} className="button-text-icon edit-button icon-left" style={eStyle}>
                    <div className="icon-wrap">
                      <svg viewBox="0 0 16 16" version="1.1">
                        <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                          <g id="Buttons/Edit/Large" transform="translate(-14.000000, -7.000000)" fillRule="nonzero">
                            <g id="Group-3">
                              <g id="baseline-edit-24px" transform="translate(12.391304, 5.000000)">
                                <polygon id="Path" points="0 0 19.1304348 0 19.1304348 20 0 20"/>
                                <path
                                  d="M2.39130435,14.375 L2.39130435,17.5 L5.38043478,17.5 L14.1963768,8.28333333 L11.2072464,5.15833333 L2.39130435,14.375 Z M16.507971,5.86666667 C16.8188406,5.54166667 16.8188406,5.01666667 16.507971,4.69166667 L14.6427536,2.74166667 C14.3318841,2.41666667 13.8297101,2.41666667 13.5188406,2.74166667 L12.0601449,4.26666667 L15.0492754,7.39166667 L16.507971,5.86666667 Z"
                                  id="Shape" fill="#3A4652"/>
                              </g>
                            </g>
                          </g>
                        </g>
                      </svg>
                    </div>
                    Edit
                  </Link>
                }
              </div>
            </div>
            <div className="row last-updated-box">
              {rally.username &&
                <p className="subtext">
                  {"Last updated: " + rally.username + ", " + moment(rally.updated_date).format('LT')}
                </p>
              }
            </div>
          </div>

          <Tabs activeKey={this.state.key} onSelect={this.changeKey} id={"tab-" + 0} className="tab-header">
            <Tab eventKey={0} title="Summary">

              <BasicView
                title={rally.rally_number + " " + rally.rally_title}
                type="header"
                content={rally.rally_number + rally.sprint_letter.toUpperCase() + " " + rally.sprint_title}
              />

              <BasicView
                title="Dates"
                type="p"
                content={moment(rally.timeline[0]).format('MMM. Do') + " - " + moment(rally.timeline[1]).format('MMM. Do YYYY')}
              />

              <StatusChart
                title="Status"
                levels={["pre-planning","planning","analysis","cancelled","complete"]}
                current={rally.status}
              />

              {/*<LinkList*/}
                {/*title="Datasets"*/}
                {/*list={rally.dataset_list ? rally.dataset_list.split("','") : []}*/}
              {/*/>*/}

              <BasicView
                title="Value Hypothesis"
                type="p"
                content={rally.motivation}
              />

              <div className='view-element rally-next-icon footer-button'>
                <button onClick={() => this.changeKey(1)} className="button-text-icon icon-right">Planning
                  <span className="right-align-icon">
                  <svg width="12px" height="12px" viewBox="0 0 12 12" version="1.1">
                    <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                      <g id="AccessDataBtn" transform="translate(-57.000000, -10.000000)" fill="#3A4652"
                         fillRule="nonzero">
                        <polygon id="Shape"
                                 points="63 10 61.9425 11.0575 66.1275 15.25 57 15.25 57 16.75 66.1275 16.75 61.9425 20.9425 63 22 69 16"/>
                      </g>
                    </g>
                  </svg>
                </span>
                </button>
              </div>

            </Tab>

            <Tab eventKey={1} title="Planning">

              <BasicView
                title="Dates"
                type="p"
                content={moment(rally.timeline[0]).format('MMM. Do') + " - " + moment(rally.timeline[1]).format('MMM. Do YYYY')}
              />

              <div className='two-col'>
                <TextList
                  title="Participants"
                  list={rally.participants.map((user) => user.name)}
                />
                <TextList
                  title="Roles"
                  list={rally.roles}
                />
              </div>

              <TextList
                title="Tags"
                list={rally.tags ? rally.tags.split("','") : []}
                isBulleted
              />
              {/*<BasicView*/}
                {/*title="Tags"*/}
                {/*type="p"*/}
                {/*content={rally.tags}*/}
              {/*/>*/}

              <TextList
                title="Sprint Questions/Focus"
                list={rally.sprint_question}
                isBulleted
              />

              <BasicView
                title="Background"
                type="p"
                content={rally.background}
              />

              {/*<BasicView*/}
                {/*title="Analysis Plan"*/}
                {/*type="p"*/}
                {/*content={rally.analysis_plan}*/}
              {/*/>*/}

              <BasicView
                title="Value Hypothesis"
                type="p"
                content={rally.motivation}
              />

              <TextList
                title="Deliverables"
                list={rally.deliverables}
                isBulleted
              />

              {/*<BasicView*/}
                {/*title="Sufficient data to continue"*/}
                {/*type="p"*/}
                {/*content={rally.is_data_available === 1 ? 'Yes' : "No"}*/}
              {/*/>*/}

              <SingleLink
                title="Synapse Space"
                url={rally.sprint_osf}
                text={rally.sprint_osf}
              />

              <div className='view-element rally-next-icon footer-button'>
                <button onClick={() => this.changeKey(2)} className="button-text-icon icon-right">Analysis
                  <span className="right-align-icon">
                  <svg width="12px" height="12px" viewBox="0 0 12 12" version="1.1">
                    <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                      <g id="AccessDataBtn" transform="translate(-57.000000, -10.000000)" fill="#3A4652"
                         fillRule="nonzero">
                        <polygon id="Shape"
                                 points="63 10 61.9425 11.0575 66.1275 15.25 57 15.25 57 16.75 66.1275 16.75 61.9425 20.9425 63 22 69 16"/>
                      </g>
                    </g>
                  </svg>
                </span>
                </button>
              </div>

            </Tab>

            <Tab eventKey={2} title="Analysis">

              {/*<FinalImpact*/}
                {/*title="Value"*/}
                {/*list={rally.final_impact_value !== "" ? JSON.parse(rally.final_impact_value) : []}*/}
              {/*/>*/}

              <SingleLink
                title="Analysis Git Repo"
                url={rally.ghap_repo}
                text={rally.ghap_repo}
              />

              <SingleLink
                title="Analysis Slides"
                url={rally.data_description ? API + "/public/uploads/analysis/" + rally.id +"/data_description/"+ rally.data_description : null}
                text={"Download Current Version"}
              />

              {/*<LinkList*/}
                {/*title="Datasets"*/}
                {/*list={rally.dataset_list ? rally.dataset_list.split("','") : []}*/}
              {/*/>*/}

              <TextList
                title="Key Findings"
                list={rally.key_findings}
                isBulleted
              />

              <TextList
                title="Next Steps"
                list={rally.next_steps}
                isBulleted
              />

              {/*<TextList*/}
                {/*title="Outcome Variables"*/}
                {/*list={rally.outcome_variables ? rally.outcome_variables.split("','") : []}*/}
                {/*isBulleted*/}
              {/*/>*/}

              {/*<TextList*/}
                {/*title="Predictor Variables"*/}
                {/*list={rally.predictor_variables ? rally.predictor_variables.split("','") : []}*/}
                {/*isBulleted*/}
              {/*/>*/}

              {/*<TextList*/}
                {/*title="Method (Keywords)"*/}
                {/*list={rally.methods ? rally.methods.split("','") : []}*/}
                {/*isBulleted*/}
              {/*/>*/}

            </Tab>

          </Tabs>
        </div>
      );
    } else {
      return (
        <div>Something went wrong!</div>
      );
    }

  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Rally);
