import React, {Component} from 'react';
import StandardInput from "./StandardInput";

class FollowLink extends Component {
    constructor(props) {
        super(props);

    }

    render() {
        const data = this.props.data;
        const title = this.props.title;
        const link_title = this.props.link_title;
        const url = this.props.url;
        if(data === null || data === undefined || ! data){
            return (
                <div className='view-element'>
                    <label>{title}</label>
                </div>
            );
        }
        if(data.length > 60){
            return (
                <div className='view-element'>
                    <StandardInput title={title} data={data} splitBool={false}/>
                </div>
            );
        }
        let dlist = data.split("','");
        return (
            <div className='view-element'>
                <label>{title}</label>
                {dlist.map((data, index) =>
                    <span className="link-button-wrap" key={index}>
                        {link_title !== undefined ?
                            <a href={data} className="link-button" target={"_blank"}>{link_title}
                            <span className="right-align-icon">
                        <svg width="12px" height="12px" viewBox="0 0 12 12" version="1.1">
                        <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                            <g id="AccessDataBtn" transform="translate(-57.000000, -10.000000)" fill="#3A4652"
                               fillRule="nonzero">
                                <polygon id="Shape"
                                         points="63 10 61.9425 11.0575 66.1275 15.25 57 15.25 57 16.75 66.1275 16.75 61.9425 20.9425 63 22 69 16"/>
                            </g>
                        </g>
                        </svg>
                        </span>
                            </a>
                         :<a href={url} className="link-button" target={"_blank"}>{data}
                         <span className="right-align-icon">
                     <svg width="12px" height="12px" viewBox="0 0 12 12" version="1.1">
                     <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                         <g id="AccessDataBtn" transform="translate(-57.000000, -10.000000)" fill="#3A4652"
                            fillRule="nonzero">
                             <polygon id="Shape"
                                      points="63 10 61.9425 11.0575 66.1275 15.25 57 15.25 57 16.75 66.1275 16.75 61.9425 20.9425 63 22 69 16"/>
                         </g>
                     </g>
                     </svg>
                     </span>
                         </a>
                        }

                   </span>
                )}
            </div>
                );
    }
}

export default FollowLink;
