import React, { Component } from 'react';
import {Alert} from "react-bootstrap";

class FormRadioInputs extends Component {
    constructor(props) {
        super(props);

        this.state = {
            data: 0,
            title: "",
            caption: "",
            name: ""
        };
    }

    onChange = (e) => {
        this.setState({ data: e.target.value });
        this.setState({ [e.target.name]: e.target.value });
        this.props.parentChange(e);
    };

    componentDidMount() {
        const { data, caption, title, name } = this.props;
        this.setState({ data, caption, title, name });
    }

    componentDidCatch(error, info) {
        this.setState({ error: error });
        this.setState({ info: info });
    }

    render() {
        const {data, caption, title, name, error} = this.state;
        if (error) {
            return <Alert className="error">{error.message}</Alert>;
        }

        if (data !== "") {
            if(data === 1 || data === "1"){
                return (
                    <div className="view-element sign-in-field">
                        <label>{title}</label>
                        <div className='caption-text'>{caption}</div>
                        <label className="radio-inline"><input type='radio'
                                                               className='radio_t_f'
                                                               name={name}
                                                               onClick={this.onChange}
                                                               defaultValue={1}
                                                               checked={"checked"}
                        />Yes:
                        </label>
                        <label className="radio-inline"><input type='radio'
                                                               className='radio_t_f'
                                                               name={name}
                                                               onClick={this.onChange}
                                                               defaultValue={0}
                        />No:
                        </label>
                    </div>
                );
            } else {
                return (
                    <div className="view-element sign-in-field">
                        <label>{title}</label>
                        <div className='caption-text'>{caption}</div>
                        <label className="radio-inline"><input type='radio'
                                                               className='radio_t_f'
                                                               name={name}
                                                               onClick={this.onChange}
                                                               defaultValue={1}/>Yes:
                        </label>
                        <label className="radio-inline"><input type='radio'
                                                               className='radio_t_f'
                                                               name={name}
                                                               onClick={this.onChange}
                                                               defaultValue={0}
                                                               defaultChecked />No:
                        </label>
                    </div>
                );
            }
        } else {
            return (
                <div className="view-element sign-in-field">
                    <label>{title}</label>
                    <div className='caption-text'>{caption}</div>
                    <label className="radio-inline"><input type='radio'
                                                           className='radio_t_f'
                                                           name={name}
                                                           onClick={this.onChange}
                                                           defaultValue={1}/>Yes:
                    </label>
                    <label className="radio-inline"><input type='radio'
                                                           className='radio_t_f'
                                                           name={name}
                                                           onClick={this.onChange}
                                                           defaultValue={0}/>No:
                    </label>
                </div>
            );
        }
    }
}

export default FormRadioInputs;