import React, { Component } from 'react';
import { Link, withRouter } from "react-router-dom";
import { connect } from 'react-redux';
import FormTextInput from "./FormTextInput";
import Tags from "./Tags";
import StandardInput from "./StandardInput";
import {Alert} from "react-bootstrap";
import FollowLink from "./FollowLink";
import Dropdown from "./Dropdown";
import moment from 'moment';

import { API } from '../util/api';

const eStyle = {
  margin: "0px 5px 0px 0px"
};

class Cards extends Component {

  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      error: null,
      spacing: "70"
    };
  }

  handleDelete = (e) => {
    alert(e);
  };

  componentDidCatch(error) {
    this.setState({ error: error });
  };

  onChange = (e) => {
    if(e.target !== undefined){
      this.setState({ [e.target.name]: e.target.defaultValue });
    }else{
      this.setState({ [e.name]: e.defaultValue });
    }
    this.props.parentChange(e);
  };

  ppt_create = (e) => {
    e.id = e.target.id;
    this.props.parentCreate(e);
  };

  render() {
    const {isLoading, error} = this.state;
    const { data } = this.props;

    if (error) return <Alert className="error">{error.message}</Alert>;

    if (isLoading) return <p>Loading ...</p>;

    if (this.props.view === 'rally') {
      if (data !== null) {
        let user = this.props.user;
        return (
          <div className="card-wrapper">
            {data.map(data => {
              let isOSF = false;
              if (data.sprint_osf) {
                let url = data.sprint_osf;
                let slug = url.split('.');
                if (slug[0].slice(-3) === 'osf') isOSF = true;
              }
              return (
                <div className="card rally-card" key={data.id}>
                <Link
                  to={"/rally/" + data.id}
                  className="redux-link"
                >
                  <h2>{data.rally_number +""+ data.sprint_letter.toUpperCase()}</h2>
                </Link>

                { this.props.active ?
                  (new Date(data.timeline[0]).getTime() > new Date().getTime()) ?
                    <div className={"status status-active-future"}></div>
                    :
                    <div className={"status status-active"}></div>
                  :
                  null
                }

                <Link
                  to={"/rally/" + data.id}
                  className="redux-link"
                >
                  <p>
                    {((data.sprint_title !== null && data.sprint_title).length > 30) ?
                      (((data.sprint_title).substring(0, 30)) + '...')
                    :
                      data.sprint_title
                    }
                  </p>
                </Link>

                <Link
                  to={"/rally/" + data.id}
                  className="redux-link"
                >
                  {data.rally_title !== null ?
                    <div>
                      {((data.rally_title).length > 30) ?
                        (((data.rally_title).substring(0, 30)) + '...')
                      :
                        data.rally_title
                      }
                    </div>
                  :
                    <p>No title for this</p>
                  }
                </Link>

                <p className="body-small">{data.timeline[0] ? data.timeline[0] : "------"} - {data.timeline[1] ? data.timeline[1] : "------"}</p>
                <p className="body-small">Status: {data.status ? data.status : "-----"}</p>
                <p className="body-small card-tag">{data.tags? data.tags.replace(/','/g, ",") : "-----"}</p>
                <p className="body-small">{data.participants ? data.participants.join("; ") :"-----"}</p>
                <p className="subtext">Last Updated: {data.username}. {moment(data.updated_date).format('MM.DD.YYYY')}</p>
                <div className="card-button-row">
                  {data.ppt_slides ?
                    <a href={API + '/' + data.ppt_slides} className="button-text-icon icon-right rally-header-btn" style={eStyle} download>PPT
                      <span className="right-align-icon">
                        <svg width="12px" height="12px" viewBox="0 0 12 12" version="1.1">
                          <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                            <g id="AccessDataBtn" transform="translate(-57.000000, -10.000000)" fill="#3A4652" fillRule="nonzero">
                              <polygon id="Shape" points="63 10 61.9425 11.0575 66.1275 15.25 57 15.25 57 16.75 66.1275 16.75 61.9425 20.9425 63 22 69 16"/>
                            </g>
                          </g>
                        </svg>
                      </span>
                    </a>
                  :
                    <div className="button-text-icon icon-right icon-arrow no-data">PPT</div>
                  }

                  {((data.sprint_osf) !== null) ?
                    <a href={data.sprint_osf} target={"_blank"} className="data-link button-text-icon icon-right">{isOSF ? 'OSF' : 'Synapse'}
                      <span className="right-align-icon">
                        <svg width="12px" height="12px" viewBox="0 0 12 12" version="1.1">
                          <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                            <g id="AccessDataBtn" transform="translate(-57.000000, -10.000000)" fill="#3A4652" fillRule="nonzero">
                              <polygon id="Shape" points="63 10 61.9425 11.0575 66.1275 15.25 57 15.25 57 16.75 66.1275 16.75 61.9425 20.9425 63 22 69 16"/>
                            </g>
                          </g>
                        </svg>
                      </span>
                    </a>
                  :
                    <div className="button-text-icon icon-right icon-arrow no-data">Synapse</div>
                  }

                  {((user.role) === "super admin" || (user.role) === "rally admin/master" || (user.role) === "rally participant") ?
                    ((data.locked) === 1 && (user.id) !== data.user) ?
                      null
                    :
                      <Link to={"/rally/edit/" + data.id} className="button-text-icon edit-button edit-icon-only">
                        <div className="icon-wrap">
                          <svg>
                            <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                              <g id="Buttons/Edit/Large" transform="translate(-14.000000, -7.000000)" fillRule="nonzero">
                                <g id="Group-3">
                                  <g id="baseline-edit-24px" transform="translate(12.391304, 5.000000)">
                                    <polygon id="Path" points="0 0 19.1304348 0 19.1304348 20 0 20"/>
                                    <path d="M2.39130435,14.375 L2.39130435,17.5 L5.38043478,17.5 L14.1963768,8.28333333 L11.2072464,5.15833333 L2.39130435,14.375 Z M16.507971,5.86666667 C16.8188406,5.54166667 16.8188406,5.01666667 16.507971,4.69166667 L14.6427536,2.74166667 C14.3318841,2.41666667 13.8297101,2.41666667 13.5188406,2.74166667 L12.0601449,4.26666667 L15.0492754,7.39166667 L16.507971,5.86666667 Z" id="Shape" fill="#3A4652"/>
                                  </g>
                                </g>
                              </g>
                            </g>
                          </svg>
                        </div>
                      </Link>
                  :
                    null
                  }
                </div>
              </div>
              )
            }
            )}
          </div>
        );
      } else {
        return (
          <div className="card-wrapper">
            <p>No current rallies available</p>
          </div>
        );
      }
    }
    if (this.props.view === 'people') {
        const LINK = "/people";
        return (
            <div className="card-wrapper">
                {data.map(data =>
                    <Link to={'/people/' + data.id} className="card profile-card" key={data.id}>
                        {data.profile_pic ?
                          <div className="profile-pic small">
                            <img
                              className="profile-img"
                              src={API +"/public/uploads/user/"+ data.id +"/profile_pic/" + data.profile_pic}
                              onError={(e) => {
                                e.target.src = "/uploads/user/profile_pic/accountphoto.png"
                              }}
                            />
                          </div>
                        :
                          <div className="profile-pic small">
                            <img className="profile-img" src={"/uploads/user/profile_pic/accountphoto.png"}/>
                          </div>
                        }
                        {data.last_name ?
                        <div className="h2 profile-name">
                            {data.first_name} {data.last_name}
                        </div>
                        : <div/>
                        }

                        {data.primary_organizational_affiliation ?
                        <div className="profile-organisation profile-attribute body-small">
                            {data.primary_organizational_affiliation}
                        </div>
                        : <div/>
                        }

                        {data.location ?
                        <div className="profile-location profile-attribute body-small">
                            {data.location}
                        </div>
                        : <div/>
                        }

                        {data.email ?
                        <div className="profile-email profile-attribute body-small">
                            {data.email}
                        </div>
                        : <div/>
                        }

                        {data.role ?
                        <div className="profile-role profile-attribute body-small">{data.role}</div>
                        : <div/>
                        }

                    </Link>
                )}
            </div>
        );
    }
    if (this.props.view === 'study') {
        if (data !== null) {
            const user = this.props.user;
            return (
                <div className="card-wrapper">
                    {data.map(data =>
                        <Link to={'/study/' + data.id} className="card study-card" key={data.id}>
                            <div className="status"/>
                            <div className="h2">{data.program ? data.program : "------"}</div>
                            <p>{data.short_id ? data.short_id : "------"}</p>
                            <p>{data.method_type ? data.method_type : "------"}, {data.stop_year ? data.stop_year : "------"}</p>
                            <p>{data.study_title ? data.study_title : "------"}</p>
                            <p>Randomized: {data.randomized ? "Yes" : "No"}</p>
                            <p>Longitudinal: {data.longitudinal ? "Yes" : "No"}</p>
                            <p>Interventional: {data.interventional ? "Yes" : "No"}</p>
                            <p className="subtext">Last updated: {data.user_name}, {moment(data.last_updated).format('MM.DD.YYYY')}</p>
                            <div className="card-button-row">
                                {((data.data_location) !== null && (data.data_location) !== undefined) ?
                                    <a href={data.data_location}
                                       className="data-link button-text-icon icon-right">Data
                                       <span className="right-align-icon">
                                       <svg width="12px" height="12px" viewBox="0 0 12 12" version="1.1">
                                       <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                           <g id="AccessDataBtn" transform="translate(-57.000000, -10.000000)" fill="#3A4652" fillRule="nonzero">
                                               <polygon id="Shape" points="63 10 61.9425 11.0575 66.1275 15.25 57 15.25 57 16.75 66.1275 16.75 61.9425 20.9425 63 22 69 16"/>
                                           </g>
                                       </g>
                                     </svg></span>
                                       </a>
                                    : <div className="button-text-icon icon-right icon-arrow no-data">No Data</div>
                                }
                                {((user.role) === "super admin" || (user.role) === "data admin") ?
                                    <Link to={"/study/edit/" + data.id}
                                          className="button-text-icon edit-button edit-icon-only">
                                        <div className="icon-wrap">
                                            <svg>
                                                <g id="Symbols" stroke="none" strokeWidth="1" fill="none"
                                                   fillRule="evenodd">
                                                    <g id="Buttons/Edit/Large"
                                                       transform="translate(-14.000000, -7.000000)"
                                                       fillRule="nonzero">
                                                        <g id="Group-3">
                                                            <g id="baseline-edit-24px"
                                                               transform="translate(12.391304, 5.000000)">
                                                                <polygon id="Path"
                                                                         points="0 0 19.1304348 0 19.1304348 20 0 20"/>
                                                                <path
                                                                    d="M2.39130435,14.375 L2.39130435,17.5 L5.38043478,17.5 L14.1963768,8.28333333 L11.2072464,5.15833333 L2.39130435,14.375 Z M16.507971,5.86666667 C16.8188406,5.54166667 16.8188406,5.01666667 16.507971,4.69166667 L14.6427536,2.74166667 C14.3318841,2.41666667 13.8297101,2.41666667 13.5188406,2.74166667 L12.0601449,4.26666667 L15.0492754,7.39166667 L16.507971,5.86666667 Z"
                                                                    id="Shape" fill="#3A4652"/>
                                                            </g>
                                                        </g>
                                                    </g>
                                                </g>
                                            </svg>
                                        </div>
                                    </Link>
                                    : null
                                }
                            </div>
                        </Link>
                    )}
                </div>
            );
        }
    }

    if (this.props.view === 'mdrCard') {
        if (data) {
            if (this.props.type === "paragraph") {
                return (
                    <div className="card-wrapper">
                        <Link to={"#"} className="card profile-card">
                            <label>Paragraph</label>
                            <label>Title</label>
                            <p>{data.paragraph_title}</p>
                            <label>Paragraph</label>
                            <pre>{data.paragraph}</pre>
                        </Link>
                    </div>
                );
            }
            if (this.props.type === "figure") {
                return (
                    <div className="card-wrapper">
                        <Link to={"#"} className="card profile-card">
                            <label>Figure/Title</label>
                            <label>Title</label>
                            <p>{data.figure_out}</p>
                            <label>Image</label>
                            <p>{data.image}</p>
                            <label>Caption</label>
                            <p>{data.figure_caption}</p>
                            <label>Takeaway</label>
                            <pre>{data.key_takeaway}</pre>
                        </Link>
                    </div>
                );
            }
            if (this.props.type === "bullet") {
                return (
                    <div className="card-wrapper">
                        <Link to={"#"} className="card profile-card">
                            <label>Bulleted list</label>
                            <label>Title</label>
                            <p>{data.bullet_list_title}</p>
                            <label>Bullet list</label>
                            <pre>{data.add_bullet_points}</pre>
                        </Link>
                    </div>
                );
            }
        }
        return (
            <div className="card-wrapper">
                <Link to={"#"} className="card profile-card">
                    "No data for this field"
                </Link>
            </div>
        );
    }

    if (this.props.view === 'fdCard') {
        if (data !== null) {
            return (
                <div className="card-wrapper">
                    <div className="card profile-card">
                        <label>Final Documentation</label>
                        <label>Name</label>
                        <p>{data.name}</p>
                        <label>Category</label>
                        <p>{data.category}</p>
                        <label>Description</label>
                        <pre>{data.description}</pre>
                        <label>Location</label>
                        <p><Link to={"https://www.synapse.org/#!Synapse:" + data.location}>{data.location}</Link></p>
                    </div>
                </div>
            );
        }
        return (
            <div className="card-wrapper">
                <Link to={"#"} className="card profile-card">
                    "No data for this field"
                </Link>
            </div>
        );
    }

    if (this.props.view === 'fdCardInput') {
        if (data !== null) {
            return (
                <div className="card-wrapper">
                    <div className="card profile-card">
                        <label>Final Documentation</label>
                        <p><FormTextInput title={"Name"} type={'text'} data={data.name}/></p>
                        <p><Dropdown title={"Category"}
                                     name={"final_documentation[][category]"}
                                     parentChange={this.onChange}
                                     data={["app","r_package","other","model","report","paper"]}
                                     selected={data.category}/>
                        </p>
                        <p><FormTextInput title={"Description"} type={'textarea'} data={data.description}/></p>
                        <p><FormTextInput title={"Location"} type={'text'} data={data.location}/></p>
                        <p><FollowLink title={"synapse link"} url={"https://www.synapse.org/#!Synapse:" + data.location} data={data.location} /></p>
                    </div>
                </div>
            );
        }
        return (
            <div className="card-wrapper">
                <Link to={"#"} className="card profile-card">
                    "No data for this field"
                </Link>
            </div>
        );
    }

    if (this.props.view === 'arms') {
        if (data !== null) {
            return (
                <div className="card-wrapper">
                    <div className="card profile-card">
                        <label>Study Arms</label>
                        <label>Description</label>
                        <p>{data.arm_description}</p>
                        <label>Arm description - short</label>
                        <p>{data.arm_description_short}</p>
                        <label>Number per arm</label>
                        <p>{data.number_per_arm}</p>
                    </div>
                </div>
            );
        }
        return (
            <div className="card-wrapper">
                <div className="card profile-card">
                    <label>Study Arms</label>
                    <label>Description</label>
                    <p>"No data for this field"</p>
                    <label>Arm description - short</label>
                    <p>"No data for this field"</p>
                    <label>Number per arm</label>
                    <p>"No data for this field"</p>
                </div>
            </div>
        );
    }
    if (this.props.view === 'epochs') {
        if (data !== null) {
            return (
                <div className="card-wrapper">
                    <div className="card profile-card">
                        <label>Epochs</label>
                        <p className="subtext">Number: {data.epoch_number}</p>
                        <p className="subtext">Label: {data.epoch_label}</p>
                    </div>
                </div>
            );
        }
        return (
            <div className="card-wrapper">
                <div className="card profile-card">
                    <label>Epochs</label>
                    <p className="subtext">Number: "No data for this field"</p>
                    <p className="subtext">Label: "No data for this field"</p>
                </div>
            </div>
        );
    }
    if (this.props.view === 'visits') {
        if (data !== null) {
            return (
                <div className="card-wrapper">
                    <div className="card profile-card">
                        <label>Visits</label>
                        <p className="subtext">Visit Number: {data.visit_number}</p>
                        <p className="subtext">Visit Day: {data.visit_day}</p>
                        <p className="subtext">Visit Description: {data.visit_description}</p>
                    </div>
                </div>
            );
        }
        return (
            <div className="card-wrapper">
                <div className="card profile-card">
                    <label>Visits</label>
                    <p className="subtext">Visit Number: "No data for this field"</p>
                    <p className="subtext">Visit Day: "No data for this field"</p>
                    <p className="subtext">Visit Description: "No data for this field"</p>
                </div>
            </div>
        );
    }

    if (this.props.view === 'element_matrix') {
        if (data !== null) {
            const {arms, epochs, values, title} = this.props;
            return (
                <div className='view-element'>
                    <label>{title}</label>
                    <table>
                        <thead>
                        <tr>
                            <th className="heading-3"/>
                            {epochs.map((data,i) =>
                                <th className="heading-3" key={i}>{data.epoch_label}</th>
                            )}
                        </tr>
                        </thead>
                        <tbody>
                        {arms.map((data,i) =>
                            <tr key={i}>
                                <th className="heading-3">{data.arm_description_short}</th>
                                {epochs.map((data,i) =>
                                    <td className="body-text" key={i}>
                                        {values[i].value.replace("|", " - ")}
                                    </td>
                                )}
                            </tr>
                        )}
                        </tbody>
                    </table>
                </div>
            );
        }
        return (
            <div className="card-wrapper">
                <div className="card profile-card">
                    <label>Element Matrix:</label>
                    "No data for this field"
                </div>
            </div>
        );
    }

    if (this.props.view === 'element_matrix_input') {
        const {arms, epochs, values, title, suggestions} = this.props;

        if (data !== null && epochs.length > 1 && arms.length > 1) {
            return (
                <div className='view-element'>
                    <label>{title}</label>
                    <table>
                        <thead>
                        <tr>
                            <th className="heading-3"/>
                            {epochs.map((data, i) =>
                                <th className="heading-3" key={i}>{data.epoch_label}</th>
                            )}
                        </tr>
                        </thead>
                        <tbody>
                        {arms.map((data,i) =>
                            <tr key={i}>
                                <th className="heading-3">{data.arm_description_short}</th>
                                {epochs.map((data,i) =>
                                    <td className="body-text" key={i}>
                                        <Tags data={values[i].value} parentChange={this.onChange} suggestions={suggestions} name={"epoch_arm_values["+i+"][value]"} />
                                    </td>
                                )}
                            </tr>
                        )}
                        </tbody>
                    </table>
                </div>
            );
        }
        return (
            <div className="card-wrapper">
                <div className="card profile-card">
                    <label>Element Matrix:</label>
                    "No data for this field"
                </div>
            </div>
        );
    }

    if (this.props.view === "mdrFormInput") {
            const {data, name} = this.props;
            if (data) {
                if (this.props.type === "linked_file") {
                    return (
                        <div className="card-wrapper">
                            <Link to={"#"} className="card profile-card">
                                <label>Linked file</label>
                                <FormTextInput data={data.linked_title} type={"text"} parentChange={this.onChange} required={false} title={"Title"} name={name+"[linked_title]"} />
                                <FormTextInput data={data.linked_link} type={"url"} title={"Link"} parentChange={this.onChange} required={false} name={name+"[linked_link]"} />
                            </Link>
                        </div>
                    );
                }
                if (this.props.type === "linked_file_view") {
                    return (
                        <div className="card-wrapper">
                            <Link to={"#"} className="card profile-card">
                                <label>Linked file</label>
                                <StandardInput data={data.linked_title} type={"text"} title={"Title"} parentChange={this.onChange} name={name+"[linked_title]"} />
                                <StandardInput data={data.linked_link} type={"url"} title={"Link"} parentChange={this.onChange} name={name+"[linked_link]"} />
                            </Link>
                        </div>
                    );
                }
                if (this.props.type === "paragraph") {
                    return (
                        <div className="card-wrapper">
                            <Link to={"#"} className="card profile-card">
                                <label>Paragraph</label>
                                <FormTextInput data={data.paragraph_title} type={"text"} title={"Title"} parentChange={this.onChange} name={name+"[paragraph_title]"} />
                                <FormTextInput data={data.paragraph} type={"textarea"} title={"Paragraph"} parentChange={this.onChange} name={name+"[paragraph]"} />
                            </Link>
                        </div>
                    );
                }
                if (this.props.type === "figure") {
                    return (
                        <div className="card-wrapper">
                            <div className="card profile-card">
                                <label>Figure/Title</label>
                                <div className="delete-trigger"/>
                                <a href={"#"} onClick={this.handleDelete} className="ki-btn">
                                    <svg width="10px" height="9px" viewBox="0 0 10 9" version="1.1"
                                         xmlns="http://www.w3.org/2000/svg">
                                        <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd"
                                           strokeLinecap="square">
                                            <g id="Icons/Remove" transform="translate(-13.000000, -13.000000)"
                                               stroke="#70B6BC">
                                                <g id="+-Button">
                                                    <g id="Group-2"
                                                       transform="translate(17.500000, 17.500000) rotate(-45.000000) translate(-17.500000, -17.500000) translate(12.000000, 12.000000)">
                                                        <path d="M0.5,5.5 L10.7958984,5.5" id="Line-2"/>
                                                        <path d="M6,0.289473684 L6,10.7105263" id="Line-2"/>
                                                    </g>
                                                </g>
                                            </g>
                                        </g>
                                    </svg>
                                    <span>Delete</span>
                                </a>
                                <FormTextInput data={data.figure_out} type={"text"} parentChange={this.onChange} title={"Title"} name={name+"[figure_out]"} />
                                <FormTextInput data={data.image} type={"text"} parentChange={this.onChange} title={"Image"} name={name+"[image]"} />
                                <a href={"https://www.synapse.org/#!Synapse:"+data.image}>{data.image}</a>
                                <FormTextInput data={data.figure_caption} parentChange={this.onChange} type={"text"} title={"Caption"} name={name+"[figure_caption]"} />
                                <FormTextInput data={data.key_takeaway} parentChange={this.onChange} type={"textarea"} title={"Takeaway"} name={name+"[key_takeaway]"} />
                            </div>
                        </div>
                    );
                }
                if (this.props.type === "bullet") {
                    return (
                        <div className="card-wrapper">
                            <Link to={"#"} className="card profile-card">
                                <label>Bulleted list</label>
                                <FormTextInput data={data.bullet_list_title} parentChange={this.onChange} type={"text"} title={"Title"} name={name+"[bullet_list_title]"} />
                                <FormTextInput data={data.add_bullet_points} parentChange={this.onChange} type={"textarea"} title={"Bullet list"} name={name+"[add_bullet_points]"} />
                            </Link>
                        </div>
                    );
                }
            } else {
                if (this.props.type === "linked_file") {
                    return (
                        <div className="card-wrapper">
                            <Link to={"#"} className="card profile-card">
                                <label>Linked file</label>
                                <FormTextInput data={""} parentChange={this.onChange} type={"text"} title={"Title"} name={name+"[linked_title]"} />
                                <FormTextInput data={""} parentChange={this.onChange} type={"url"} title={"Link"} name={name+"[linked_link]"} />
                            </Link>
                        </div>
                    );
                }
                if (this.props.type === "linked_file_view") {
                    return (
                        <div className="card-wrapper">
                            <Link to={"#"} className="card profile-card">
                                <label>Linked file</label>
                                <StandardInput data="" parentChange={this.onChange} type={"text"} title={"Title"} />
                                <StandardInput data="" parentChange={this.onChange} type={"url"} title={"Link"} />
                            </Link>
                        </div>
                    );
                }
                if (this.props.type === "paragraph") {
                    return (
                        <div className="card-wrapper">
                            <Link to={"#"} className="card profile-card">
                                <label>Paragraph</label>
                                <FormTextInput data={""} parentChange={this.onChange} type={"text"} title={"Title"} name={name+"[paragraph_title]"} />
                                <FormTextInput data={""} parentChange={this.onChange} type={"textarea"} title={"Paragraph"} name={name+"[paragraph]"} />
                            </Link>
                        </div>
                    );
                }
                if (this.props.type === "figure") {
                    return (
                        <div className="card-wrapper">
                            <div className="card profile-card">
                                <label>Figure/Title</label>
                                <div className="delete-trigger"/>
                                <Link to={"#"} onClick="removeBackground($index)" className="ki-btn">
                                    <svg width="10px" height="9px" viewBox="0 0 10 9" version="1.1">
                                        <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd"
                                           strokeLinecap="square">
                                            <g id="Icons/Remove" transform="translate(-13.000000, -13.000000)"
                                               stroke="#70B6BC">
                                                <g id="+-Button">
                                                    <g id="Group-2"
                                                       transform="translate(17.500000, 17.500000) rotate(-45.000000) translate(-17.500000, -17.500000) translate(12.000000, 12.000000)">
                                                        <path d="M0.5,5.5 L10.7958984,5.5" id="Line-2"/>
                                                        <path d="M6,0.289473684 L6,10.7105263" id="Line-2"/>
                                                    </g>
                                                </g>
                                            </g>
                                        </g>
                                    </svg>
                                    <span>Delete</span>
                                </Link>
                                <FormTextInput data={""} parentChange={this.onChange} type={"text"} title={"Title"} name={name+"[figure_out]"} />
                                <FormTextInput data={""} parentChange={this.onChange} type={"text"} title={"Image"} name={name+"[image]"} />
                                <FormTextInput data={""} parentChange={this.onChange} type={"text"} title={"Caption"} name={name+"[figure_caption]"} />
                                <FormTextInput data={""} parentChange={this.onChange} type={"textarea"} title={"Takeaway"} name={name+"[key_takeaway]"} />
                            </div>
                        </div>
                    );
                }
                if (this.props.type === "bullet") {
                    return (
                        <div className="card-wrapper">
                            <Link to={"#"} className="card profile-card">
                                <label>Bulleted list</label>
                                <FormTextInput data={""} parentChange={this.onChange} type={"text"} title={"Title"} name={name+"[bullet_list_title]"} />
                                <FormTextInput data={""} parentChange={this.onChange} type={"textarea"} title={"Bullet list"} name={name+"[add_bullet_points]"} />
                            </Link>
                        </div>
                    );
                }
            }
        }
    }
}

export default connect()(Cards);
