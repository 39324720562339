import {
  CREATING_SYNAPSE,
  CREATE_SUCCESS,
  CREATE_FAIL,
  UPDATING_SYNAPSE,
  UPDATE_SUCCESS,
  UPDATE_FAIL
} from "../actions/synapse";

const initialState = {
  inProgress: false,
  completed: false,
  id: null,
  error: null
};

export default function authReducer(state = {...initialState}, action) {
  switch (action.type) {
    case CREATING_SYNAPSE:
      return {
        ...state,
        inProgress: true,
        completed: false,
      };
    case CREATE_SUCCESS:
      return {
        ...state,
        inProgress: false,
        completed: true,
        id: action.synapseId
      };
    case CREATE_FAIL:
      return {
        ...state,
        inProgress: false,
        error: action.error
      };
    case UPDATING_SYNAPSE:
      return {
        ...state,
        inProgress: true,
        completed: false,
      };
    case UPDATE_SUCCESS:
      return {
        ...state,
        inProgress: false,
        completed: true,
        id: action.synapseId
      };
    case UPDATE_FAIL:
      return {
        ...state,
        inProgress: false,
        error: action.error
      };
    default:
      return state;
  }
};