import React, { Component } from 'react';
import axios from "axios";
import FollowLink from "./FollowLink";
import moment from 'moment';

import { API } from '../util/api';
const STYLE = {
    float: "left"
};
const modalStyle = {
    width: 25 +"%"
};
const pStyle = {
    height: '40px'
};


class FormUpload extends Component {
  constructor(props) {
    super(props);

    this.state = {
      file: null,
      action: null,
    }
  }

  onChange = (e) => {
    this.setState({ file: e.target.files[0] }, () => {
      console.log(this.state);
    });
  };

  onBatchChange = (e) => {
    let action = API + "/upload/batch/study";
    this.setState({ file: e.target.files[0], action, autoSave: true }, () => {
      this.upLoadFile(e);
    });
  };

  upLoadFile = (e) => {
    e.preventDefault();

    console.log(e.target);

    if (e.target.action !== undefined){
      this.setState({action: e.target.action}, () => {
        let formData = new FormData();
        console.log(this.state.file.name);
        formData.append("file", this.state.file, this.state.file.name);
        console.log(formData);
        const options = {
          headers: {
            'Content-Type': 'multipart/form-data',
          }
        };
        console.log(this.state.action);
        axios.post(this.state.action, formData, options)
          .then((response) => {
            console.log(response);
            this.setState({ data: response.data.data, response: response.data.message, autoSave: false, isLoading: false },() => {
              // window.location.reload();
            });
          })
          .catch((err) => {
            this.setState({ savedTime: "!" });
            this.setState({ autoSave: false });
            console.log(err);
            // window.location.reload();
          });
      });
    } else {
      let formData = new FormData();
      formData.append("file", this.state.file);
      const Header = {'Content-Type': 'multipart/form-data'};
      // axios.post(this.state.action, formData, Header)
      //   .then((response) => {
      //     this.setState({ data: response.data.data, response: response.data.message, autoSave: false, isLoading: false },() => {
      //      //  window.location.reload();
      //     });
      //   })
      //   .catch((err) => {
      //     this.setState({ savedTime: "!" });
      //     this.setState({ autoSave: false });
      //     console.log(err);
      //   });
    }
  };

  render() {
    const {mime, data, type, title, caption, action, id, name} = this.props;
    if (type === "batch_study"){
          return (
              <div className='view-element sign-in-field'>
                  <h1>Study</h1>
                  <div className="caption-text">Bulk upload</div>
                  <div className="heading-3 mb-5">Upload a bulk batch of data from a csv file</div>
                  {this.state.autoSave ?
                      <div className="row last-updated-box">
                          <img src="/images/uploading.gif" style={pStyle}/>
                      </div>
                      :<form method="post" encType="multipart/form-data" action={API + "/upload/batch/study"}
                             id="batch_form" className="batch_upload_form heading-3-white mt-3"  onSubmit={this.upLoadFile}>
                          <div className="image-upload">
                              <label htmlFor="file-input">
                                  <div className="ki-btn">
                                      <div className="upload-svg"/>
                                      <span>Upload a batch file</span>
                                  </div>
                              </label>
                              <input type="file" id="file-input" onChange={this.onBatchChange} name="file"
                                     className="ki-btn" accept=".xls,.csv"/>
                              <input className="inputfile" type="submit"/>
                              { this.state.response }
                          </div>
                      </form>
                  }
              </div>
          );
      }
    if(type === "analysis"){
      return (
        <div className='view-element'>
          <div className="form-wrap">
            <div>
              <div className="heading-3">{title}</div>
              <div className="caption-text">{caption}</div>
            </div>
            <FollowLink data={"Analysis Slide Template"} url={"/uploads/template_ki.pptx"} style={STYLE}/>
          </div>
          <form method="post" encType="multipart/form-data" onSubmit={this.upLoadFile} action={API +"/upload/"+type+"/upload/"+id+"/"+action} className="upload_form">
            <input type="file" onChange={this.onChange} id={"file"} name={name} accept={mime} />
            <input type="submit" id="profile_pic_submit" value="Upload"/>
          </form>
          {data ?
            <FollowLink data={"Download analysis slides"} url={API + "/uploads/analysis/"+ id +"/"+ name +"/"+ data} style={STYLE}/>
          :
            "No slides uploaded for this rally"
          }
        </div>
      );
      }
    if(type === "user"){
          return (
              <div className='view-element'>
                  <div className="profile-image">
                      <img className="profile_pic" src={API +"/uploads/user/"+data.id+"/profile_pic/"+data.profile_pic} onError={(e)=>{e.target.src = API +"/uploads/user/profile_pic/accountphoto.png"}} />
                  </div>
                  <div className="form-wrap">
                      <div className="heading-3">PROFILE PICTURE</div>
                      <div className="caption-text">Upload here</div>
                      <div id="approvedFiles"/>
                      <form method="post" encType="multipart/form-data" onSubmit={this.upLoadFile}  action={API +"/upload/user/upload/"+data.id+"/profile_pic"} className="upload_form">
                          <p><input type="file" name={name} onChange={this.onChange} className="form-control-file" id="file" accept=".jpg,.gif,.png,.jpeg"/></p>
                          <p><input type="submit" id="profile_pic_submit" value="Upload"/></p>
                      </form>
                  </div>
              </div>
          );
      } else {
      return (
          <div className='view-element'>
              <div className="form-wrap">
                  <div className="heading-3">{title}</div>
                  <div className="caption-text">{caption}</div>
                  <div id="approvedFiles"/>
                  <form method="post" encType="multipart/form-data" onSubmit={this.upLoadFile} action={API +"/upload/"+type+"/upload/"+id+"/"+action} className="upload_form">
                      <input type="file" onChange={this.onChange} id={"file"} name={name} accept={mime} />
                      <p><input type="submit" id="profile_pic_submit" value="Upload"/></p>
                  </form>
              </div>
          </div>
      );
      }
  }
}

export default FormUpload;