import React, {Component} from 'react';
import axios from "axios";
import {AuthConsumer} from 'react-check-auth';
import {Link} from "react-router-dom";

const API = "http://api.cognitionstudio.com/api/v1";
const iStyle = {
    color: "#dddddd"
};
const pStyle = {
    height: '15px'
};

class ForgotEmail extends Component {
    constructor(props) {
        super(props);

        this.state = {
            email: "",
            retrieving: false
        };
    }

    onChange = (e) => {
        this.setState({[e.target.name]: e.target.value }, () => {});
    };

    componentWillUnmount() {
        document.body.classList.remove('forgot-pass');
    };

    componentDidMount() {
        document.body.classList.toggle('forgot-pass', true);
    };

    handleSubmit = () => {
        this.setState({retrieving: true});
        let email = document.getElementById("email").value;
        this.setState({ email: email }, () => {
            axios.post(API + "/reset/email", this.state)
                .then((response) => {
                    if(response.data.message){
                        this.setState({error: response.data.message}, () => {});
                        this.setState({retrieving: false});
                    } else {
                        this.setState({error: "Failed to reset password"}, () => {});
                        this.setState({retrieving: false});
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
        });
    };

    render() {
        const {error, retrieving} = this.state;
        return (
            <div className='view-element sign-in-field'>
                <div className='caption-text'>{error}</div>
                <div className="form-question">
                    <label className="heading-3-white" htmlFor='email'>Enter email </label>
                    <input type="email" placeholder="Email" id="email" onChange={this.onChange} name="email" value={this.state.email}/>

                    <div className="button-controls">
                    {retrieving ?
                        <img src="/images/uploading.gif" style={pStyle}/>
                        : <input type="submit" className="submit-button" onClick={this.handleSubmit} value="submit"/>
                    }
                    <Link className="button-text-icon" to="/">Return to Main</Link>
                    </div>
                </div>
            </div>
        );
    }
}

export default ForgotEmail;
