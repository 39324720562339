import React, { Component } from 'react';
import Select from 'react-select';

class Dropdown extends Component {
    constructor(props) {
        super(props);

        this.state = {
            selectedOption: null,
            options: []
        };
    }

    componentDidMount() {
        let options = [];
        let test = [];
        if(this.props.type === "visits"){
            this.props.data.forEach((value) => {
                if(test.indexOf(value.id) === -1){
                    options.push({value: value.id,label: value.epoch_label});
                    test.push(value.id);
                }
            });
        } else {
            if(this.props.data){
                if(this.props.data.constructor === Array){
                    this.props.data.forEach((value,i) => {
                        if(value !== null){
                            if(test.indexOf(value.toString().toLowerCase()) === -1){
                                value = value.toString().trim();
                                options.push({value: value.toString().toLowerCase(),label: value.charAt(0).toUpperCase() + value.slice(1)});
                                test.push(value.toString().toLowerCase());
                            }
                        }
                    });
                }
            }
        }
        this.setState({options: options});
        let selectedOption = [];
        if(this.props.selected) {
            let data = this.props.selected;
            if (data === undefined || data === null) {
                selectedOption = {value: "",label: ""};
            }
            if(data.toString().indexOf("','") !== -1){
                let ret = data;
                ret = ret.split("','");
                ret.forEach(d => {
                    selectedOption.push({value: d, label: d.toUpperCase()});
                });
            }else{
                selectedOption = {value: data, label: data.toUpperCase()};
            }
        }
        this.setState({selectedOption: selectedOption});
    }

    onChange = (e) => {
        let selectedOption = [];
        if(e){
            selectedOption = {value: e.value, label: e.label};
            e.name = this.props.name;
        }else {
            e = {value: "",name: this.props.name}
        }
        this.setState({ selectedOption });
        this.props.parentChange(e);
    };

    onMultiChange = (e) => {
        let selectedOption = e;
        let parentVars = [];
        selectedOption.forEach((option) => {
            parentVars.push(option.value.trim());
        });

        parentVars = {name: [this.props.name], value: parentVars.join("','")};
        this.setState({ selectedOption });
        this.props.parentChange(parentVars);
    };

    render() {
        const {name, multi, type, title, caption} = this.props;
        const { options, selectedOption } = this.state;

        if(multi === "true"){
            if(this.props.title !== undefined){
                return (
                    <div className="view-element label-fix">
                        <label>{title}</label>
                        <div className='caption-text'>{caption}</div>
                        <Select options={options}
                                className={"dynamic-select-box"}
                                placeholder={type}
                                isClearable={true}
                                onChange={this.onMultiChange}
                                name={name}
                                isMulti={true}
                                defaultValue={""}
                                value={selectedOption}
                                isSearchable={true}/>
                    </div>
                    );
            }else{
                return (
                    <Select options={options}
                            className={"dynamic-select-box"}
                            placeholder={type}
                            isClearable={true}
                            onChange={this.onMultiChange}
                            name={name}
                            isMulti={true}
                            defaultValue={""}
                            value={selectedOption}
                            isSearchable={true}/>
                );
            }
        } else {
            if(this.props.title !== undefined){
                return (
                    <div className="view-element label-fix">
                        <label>{title}</label>
                        <div className='caption-text'>{caption}</div>
                        <Select options={options}
                                className={"dynamic-select-box"}
                                placeholder={type}
                                isClearable={true}
                                onChange={this.onChange}
                                name={name}
                                isMulti={false}
                                defaultValue={""}
                                value={selectedOption}
                                isSearchable={true}/>
                    </div>
                    );
            } else {
                return (
                    <Select options={options}
                            className={"dynamic-select-box"}
                            placeholder={type}
                            isClearable={true}
                            onChange={this.onChange}
                            name={name}
                            isMulti={false}
                            defaultValue={""}
                            value={selectedOption}
                            isSearchable={true}/>
                );
            }
        }
    }
}

export default Dropdown;
