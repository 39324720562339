const alphabet = "abcdefghijklmnopqrstuvwxyz".split("");
const alphObj = alphabet.map(letter => {
  return {letter, available: true}
});

export const getAvailableSprintLetter = (number, proposed, rallies, boolCheck) => {
  const sameSprintNumber = rallies.filter(rally => rally.rally_number === number);
  sameSprintNumber.forEach(sprint => {
    for (const i in alphObj) {
      if (alphObj[i].letter === sprint.sprint_letter) alphObj[i].available = false
    }
  });

  const checkAvail = (letter) => {
    for (const i in alphObj) {
      if (alphObj[i].letter === letter) return alphObj[i].available
    }
  };

  const checkProposed = checkAvail(proposed);

  if (boolCheck) return checkProposed;
  if (checkProposed) return proposed;

  const lIndex = alphabet.findIndex(l => l === proposed);

  // Check if Z - if so, return Z back
  if (lIndex === alphabet.length - 1) return proposed;

  const findNext = () => {
    let foundLetter;
    alphObj.forEach((rally, i) => {
      if (!foundLetter) {
        if (lIndex < i) {
          if (rally.available) foundLetter = rally.letter
        }
      }
    });
    if (foundLetter) return foundLetter;
    // Default to Z
    return "z"
  };

  // Find next available letter
  return findNext()
};