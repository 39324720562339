import {API, synapseApi} from '../util/api';

export const CREATING_SYNAPSE = 'CREATING_SYNAPSE';
export const CREATE_SUCCESS = 'CREATE_SUCCESS';
export const CREATE_FAIL = 'CREATE_FAIL';

export const UPDATING_SYNAPSE = 'UPDATING_SYNAPSE';
export const UPDATE_SUCCESS = 'UPDATE_SUCCESS';
export const UPDATE_FAIL = 'UPDATE_FAIL';

export const UPDATING_SLIDEDECK = 'UPDATING_SLIDEDECK';
export const SLIDEDECK_SUCCESS = 'SLIDEDECK_SUCCESS';
export const SLIDEDECK_FAIL = 'SLIDEDECK_FAIL';

export const creatingSynpase = () => ({
  type: CREATING_SYNAPSE
});

export const createSuccess = synapseId => ({
  type: CREATE_SUCCESS,
  synapseId,
  receivedAt: Date.now()
});

export const createFail = error => ({
  type: CREATE_FAIL,
  error
});


export const updatingSynpase = () => ({
  type: UPDATING_SYNAPSE
});

export const updateSuccess = synapseId => ({
  type: UPDATE_SUCCESS,
  synapseId,
  receivedAt: Date.now()
});

export const updateFail = error => ({
  type: UPDATE_FAIL,
  error
});


export const updatingSlidedeck = () => ({
  type: UPDATING_SLIDEDECK
});

export const slidedeckSuccess = synapseId => ({
  type: SLIDEDECK_SUCCESS,
  synapseId,
  receivedAt: Date.now()
});

export const slidedeckFail = error => ({
  type: SLIDEDECK_FAIL,
  error
});

/*
 * Create Synapse Space
 * @param {int} userid
 * @param {object} rally data [Required]
 */
export const createSynapse = (userId, data) => (dispatch) => new Promise((resolve, reject) => {
  dispatch(creatingSynpase());

  let synapse_user = userId ? userId : 3372015;

  if (data) {
    let payload = {
      "query": "mutation CreateSynProject($name: String!, $permissions: [PermissionDataInput], $annotations: [AnnotationDataInput], $wiki: WikiDataInput, $folders: [String], $posts: [PostDataInput]) { createSynProject(name: $name, permissions: $permissions, annotations: $annotations, wiki: $wiki, folders: $folders, posts: $posts) { synProject { id name } } }",
      "variables": {
        "name": data.sprint_title,
        "permissions": [{
          "principalId": synapse_user,
          "access": "ADMIN"
        }],
        "annotations": [{
          "key": "rally",
          "value": data.rally_number
        }, {
          "key": "sprint",
          "value": data.sprint_letter
        }],
        "wiki": {
          "title": "Main Wiki",
          "markdown": "main wiki markdown"
        },
        "folders": ["Data/Documentation", "Data/Auxiliary", "Planning", "Report Out", "Research Questions", "Results", "Timeline"],
        "posts": [{
          "title": "test",
          "messageMarkdown": "Use this post for a daily check in."
        }]
      }
    };

    synapseApi().post(JSON.stringify(payload))
      .then((response) => {
        console.log(response);
        let synapseData = response.data.data.createSynProject.synProject;
        dispatch(createSuccess(synapseData.id));
        resolve(synapseData.id);
      })
      .catch((error) => {
        dispatch(createFail(error));
        reject("Could not create Synapse space");
      })

  } else {
    dispatch(createFail("No data provided"));
    reject("No data provided");
  }

});


/*
 * Update Synapse Space
 * @param {int} userId
 * @param {object} data [Required]
 */
export const updateSynapse = (userId, data) => (dispatch) => new Promise((resolve, reject) => {
  dispatch(updatingSynpase());

  let synapse_user = userId ? userId : 3381745;

  if (data) {
    let payload = {
      "query": "mutation UpdateSynProject($id: String!, $name: String, $permissions: [PermissionDataInput]) { updateSynProject(id: $id, name: $name, permissions: $permissions) { synProject { id name } } }",
      "variables": {
        "id": (data.sprint_osf.split(':'))[2],
        "name": data.sprint_title,
        "permissions": [{
          "principalId": synapse_user,
          "access": "CAN_EDIT"
        }]
      }
    };

    synapseApi().post(JSON.stringify(payload))
      .then((response) => {
        console.log(response);
        let synapseData = response.data.data.updateSynProject.synProject;
        dispatch(updateSuccess(synapseData.id));
        resolve(synapseData.id);
      })
      .catch((error) => {
        dispatch(updateFail(error));
        reject("Could not create Synapse space");
      })

  } else {
    dispatch(updateFail("No data provided"));
    reject("No data provided");
  }

});