import React, { Component } from 'react';
import { connect } from 'react-redux';
import {Link, Redirect} from "react-router-dom";

import { BasicInput, Dropdown, RadioSelect } from '../partials/InputBlock';
import { Modal } from '../partials/Modal';
import { ProfilePic } from '../partials/FileUpload';
import LoadingScreen from '../partials/Loading';

import { fetchSingleUser, createUser, editUser } from "../../actions/users";
import { resetPassword } from "../../actions/users";
import { uploadFile } from "../../actions/upload";

import { API } from '../../util/api';


const mapStateToProps = (state) => ({
  user: state.auth.user,
  level: state.auth.level,
  single: {
    loading: state.users.single.loading,
    loaded: state.users.single.loaded,
    user: state.users.single.user,
    error: state.users.single.error
  }
});

class PeopleEdit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      create: this.props.match.params.userID === "add",
      data: {},
      roles: null,
      loaded: false,
      error: {
        all: null,
        email: false,
      },
      modal: {
        activate: false,
        deactivate: false,
        password: false,
      }
    };
  }

  updateState = (name, value) => {
    console.log(name + " | " + value);
    if (name) {
      this.setState({
        data: {
          ...this.state.data,
          [name]: value,
        }
      });
    }
  };

  uploadProfilePic = (file) => {
    let endpoint = 'user/upload/' + this.state.data.id + "/profile_pic";
    this.props.uploadFile(endpoint, file)
      .then((fileName) => {
        console.log(fileName);
        this.setState({
          data: {
            ...this.state.data,
            profile_pic: fileName
          }
        })
      });
  };

  saveUser = () => {
    let data = this.state.data;
    console.log(data);

    if (!data.email || data.email === "") {
      this.setState({
        error: {
          ...this.state.error,
          email: true,
        }
      })
    } else {
      this.setState({
        error: {
          ...this.state.error,
          email: false,
        }
      });

      if (this.state.create) {
        this.props.createUser(data);
      } else {
        this.props.editUser(data);
      }
    }
  };

  activate = () => {
    console.log("Activate");
    let data = this.state.data;
    this.setState({
      data: {
        ...this.state.data,
        active: 1,
      }
    });
    this.props.editUser(data);
    this.props.resetPassword({email: data.email}, 'email');
  };

  deactivate = () => {
    console.log("Deactivate");
    this.setState({
      data: {
        ...this.state.data,
        active: 0,
      }
    }, () => {
      this.props.editUser(this.state.data);
    });
  };

  resetPassword = () => {
    console.log("Reset Password");
    this.props.resetPassword({email: this.state.data.email}, 'email');
  };

  componentWillUnmount() {
    document.body.classList.remove('people-edit');
  };

  componentDidMount() {
    document.body.classList.toggle('people-edit', true);
    let id  = document.getElementById("id");
    this.setState({ id: id });

    if (this.props.single.loaded) {
      if (this.props.match.params.userID === 'add') {
        this.props.fetchSingleUser(this.props.match.params.userID);
        this.setState((state) => {
          // let roles = this.props.single.user.roles;
          // if (this.props.level !== 3) roles.splice(0,1);
          return {
            loaded: true,
            roles: this.props.single.user.roles,
            data: {
              ...this.state.data,
              added: 1
            }
          }
        })
      } else {
        if (this.props.single.user.id) {
          // Do stuff to hydrate the state with preloaded user
          let user = this.props.single.user;
          for (let key in user) {
            if (user[key] === null || user[key] === undefined) {
              user[key] = "";
            }
            this.setState((state) => {
              return {
                loaded: true,
                roles: user.roles,
                data: {
                  ...state.data,
                  [key]: user[key]
                }
              }
            });
          }
        } else {
          this.props.fetchSingleUser(this.props.match.params.userID);
        }
      }
    } else {
      this.props.fetchSingleUser(this.props.match.params.userID)
    }
  }

  componentDidUpdate(prevProps) {
    if (JSON.stringify(this.props.single.user) !==  JSON.stringify(prevProps.single.user)) {
      let user = this.props.single.user;

      if (user) {
        if (this.props.match.params.userID === 'add') {
          this.setState((state) => {
            let roles = user.roles;
            if (this.props.level !== 3) roles.splice(0,1);
            return {
              loaded: true,
              roles: roles,
              data: {
                ...this.state.data,
                added: 1
              }
            }
          })
        } else {

          for (let key in user) {
            if (user[key] === null || user[key] === undefined) {
              user[key] = "";
            }
            this.setState((state) => {
              return {
                loaded: true,
                roles: user.roles,
                data: {
                  ...state.data,
                  [key]: user[key]
                }
              }
            });
          }
        }
      }
    }
  }

  componentDidCatch(error, info) {
      this.setState({ error: error });
      this.setState({ info: info });
  }

  render() {
    console.log(this.state);
    const { error, data } = this.state;

    if (error.all) return <p className="error">{error.all}</p>;
    if (!this.state.loaded) return <LoadingScreen interior />;

    if (this.props.level > 0 || this.props.user.id === data.id) {
      return (
        <div className="create-edit">
          {this.state.modal.activate ?
            <Modal
              title="Activate User"
              caption="An email will be sent to the person to join the Hub"
              secondaryAction={() => this.setState({modal: {...this.state.modal, activate: false} })}
              secondaryText="Cancel"
              primaryAction={() => this.activate()}
              primaryText="Activate"
            />
            :
            null
          }
          {this.state.modal.deactivate ?
            <Modal
              title="Deactivate User"
              caption="They will no longer have access to the tool, but you may still reference them in a sprint or study."
              secondaryAction={() => this.setState({modal: {...this.state.modal, deactivate: false} })}
              secondaryText="Cancel"
              primaryAction={() => this.deactivate()}
              primaryText="Dectivate"
            />
            :
            null
          }
          {this.state.modal.password ?
            <Modal
              title="Reset Password"
              caption="This will send an email to the user to reset their password"
              secondaryAction={() => this.setState({modal: {...this.state.modal, password: false} })}
              secondaryText="Cancel"
              primaryAction={() => this.resetPassword()}
              primaryText="Reset Password"
            />
            :
            null
          }
          <div className="interior-header with-border">
            <div className="row">
              <div className="col-sm-7 header-title">
                <h1>
                  <Link className="backlink" to={"/people/" + data.id}>{""}</Link>
                  {this.state.create ? "Add Person" : "Edit Person"}
                </h1>
              </div>
              <div className="col-sm-5 button-controls">
                {!this.state.create ?
                  <div>
                    {this.props.user.id === data.id ?
                      <div className="button-text-icon">
                        <Link
                          to={{
                            pathname: "/reset/password/" + data.id,
                            state: {
                              email: data.email
                            }
                          }}
                        >
                          Change Password
                        </Link>
                      </div>
                      :
                      <button
                        className="button-text-icon secondary-btn"
                        onClick={() => this.setState({
                          modal: {...this.state.modal, password: true}
                        })}
                      >
                        Reset Password
                      </button>
                    }
                    {data.active === 0 && data.added === 1 ?
                      <button
                        className="button-text-icon secondary-btn"
                        onClick={() => this.setState({
                          modal: { ...this.state.modal, activate: true}
                        })}
                      >
                        Activate
                      </button>
                    :
                      null
                    }
                    <button className="button-text-icon primary-btn" onClick={() => this.saveUser()}>
                      {this.state.create ? "Create" : "Save"}
                    </button>
                  </div>
                  :
                  <div>
                    <div className="button-text-icon">
                      <Link to={"/people"}>
                        Cancel
                      </Link>
                    </div>
                    <button className="button-text-icon primary-btn" onClick={() => this.saveUser()}>
                      {this.state.create ? "Create" : "Save"}
                    </button>
                  </div>
                }
                {this.state.create ?
                  this.state.active ?
                    <div onClick={() => {
                    }} id="delete" className="button-text-icon">Delete</div>
                    :
                    null
                  :
                  null
                }
              </div>
            </div>
          </div>
          <div className='form-wrap'>
            <input
              type='hidden'
              name='id'
              id='id'
              value={this.props.match.params.userID}
              maxLength='11'
            />
            {!this.state.create ?
              <ProfilePic
                title="Profile Picture"
                data={data}
                id={data.id}
                name="profile_pic"
                upload={(e) => this.uploadProfilePic(e)}
                mime=".jpg,.gif,.png,.jpeg"
              />
              :
              null
            }
            {this.props.user.id !== parseInt(this.props.match.params.userID) || this.props.level === 3 ?
              <Dropdown
                title="Select Access"
                updateValue={(name, value) => this.updateState(name, value)}
                name="role"
                options={this.state.roles}
                selected={data.role}
              />
              :
              null
            }
            <BasicInput
              title="Email (Required)"
              updateValue={(name, value) => this.updateState(name, value)}
              name="email"
              data={data.email}
              type="text"
              maxlength="255"
              isRequired={this.state.error.email}
            />
            {this.state.create ?
              <RadioSelect
                title="Active User (email will be sent)"
                updateValue={(name, value) => this.updateState(name, value)}
                name="active"
                data={data.active}
              />
              :
              null
            }
            <BasicInput
              title="First Name"
              updateValue={(name, value) => this.updateState(name, value)}
              name="first_name"
              data={data.first_name}
              type="text"
              maxlength="255"
            />
            <BasicInput
              title="Last Name "
              updateValue={(name, value) => this.updateState(name, value)}
              name="last_name"
              data={data.last_name}
              type="text"
              maxlength="255"
            />
            <BasicInput
              title="Synapse User Account"
              updateValue={(name, value) => this.updateState(name, value)}
              name="synapse_id"
              data={data.synapse_id}
              type="text"
              maxlength="255"
            />
            <BasicInput
              title="Highest Degree Attained"
              updateValue={(name, value) => this.updateState(name, value)}
              name="highest_degree_attained"
              data={data.highest_degree_attained}
              type="text"
              maxlength="255"
            />
            <BasicInput
              title="Primary Organizational Affiliation"
              updateValue={(name, value) => this.updateState(name, value)}
              name="primary_organizational_affiliation"
              data={data.primary_organizational_affiliation}
              type="text"
              maxlength="255"
            />
            <BasicInput
              title="Discipline(s)"
              updateValue={(name, value) => this.updateState(name, value)}
              name="disciplines"
              data={data.disciplines}
              type="text"
              maxlength="255"
            />
            <BasicInput
              title="Interests"
              updateValue={(name, value) => this.updateState(name, value)}
              name="interests"
              data={data.interests}
              type="text"
              maxlength="255"
            />
            <BasicInput
              title="Geographic Areas of Expertise"
              updateValue={(name, value) => this.updateState(name, value)}
              name="geographic_areas_of_expertise"
              data={data.geographic_areas_of_expertise}
              type="text"
              maxlength="255"
            />
            <BasicInput
              title="Location"
              updateValue={(name, value) => this.updateState(name, value)}
              name="location"
              data={data.location}
              type="text"
              maxlength="255"
            />
            <BasicInput
              title="Team"
              updateValue={(name, value) => this.updateState(name, value)}
              name="team"
              data={data.team}
              type="text"
              maxlength="255"
            />
            <BasicInput
              title="Mobile"
              updateValue={(name, value) => this.updateState(name, value)}
              name="mobile"
              data={data.mobile}
              type="text"
              maxlength="255"
            />
            <BasicInput
              title="LinkedIn Profile Name"
              caption="www.linkedin.com/in/[profile name]"
              updateValue={(name, value) => this.updateState(name, value)}
              name="linkdin"
              data={data.linkdin}
              type="text"
              maxlength="255"
            />

            {this.state.error.email ?
              <p className="error">Email is required</p>
              :
              null
            }

            {/*<button className="button-text-icon" onClick={() => this.saveUser()}>*/}
              {/*{this.state.create ? "Create User" : "Save User"}*/}
            {/*</button>*/}

          </div>
          <div className="interior-footer">
            {/*{data.active === 1 && data.added === 1 ?*/}
              {/*<button*/}
                {/*className="button-text-icon secondary-btn"*/}
                {/*onClick={() => this.setState({*/}
                  {/*modal: { ...this.state.modal, deactivate: true}*/}
                {/*})}*/}
              {/*>*/}
                {/*Deactivate User*/}
              {/*</button>*/}
              {/*:*/}
              {/*null*/}
            {/*}*/}
          </div>
        </div>
      );
    } else {
      return (<Redirect to={'/people'} />)
    }

    }
}
export default connect(mapStateToProps, {
  fetchSingleUser,
  createUser,
  editUser,
  uploadFile,
  resetPassword,
})(PeopleEdit);
