import React, { Component } from 'react';
import { connect } from 'react-redux';

import { Redirect } from 'react-router-dom';
import { AuthConsumer } from 'react-check-auth';
import { Link } from 'react-router-dom';

import { fetchUser, clearError } from '../actions/auth';

const mapStateToProps = state => ({
  loggedIn: state.auth.loggedIn,
  user: state.auth.user,
  error: state.auth.error,
});

const mapDispatchToProps = dispatch => ({
  login: (user, pass) => (
    dispatch(fetchUser(user, pass))
  ),
  dismiss: () => {
    dispatch(clearError())
  }
});

class Login extends Component {

  constructor() {
    super();
    this.state = {
      username: '',
      password: '',
      error: null,
    };
  };

  dismissError = () => {
    this.props.dismiss();
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.login(this.state.username, this.state.password);
  };

  handleLogOut = () => {
    localStorage.setItem("token", "");
    window.location.reload();
  };

  componentWillUnmount() {
    document.body.classList.remove('login');
  };

  componentDidMount() {
    document.body.classList.toggle('login', true);
  };

  render() {
    let { from } = this.props.location.state || { from: { pathname: "/" } };
    if (this.props.loggedIn) return <Redirect to={from} />;
    return (
      <div className="Login">
        <div className="left">
          <div className="login-left-wrap">

            <div className={"rectangle-2"}>

            </div>

            <div className="h1 section-heading">
              Ki hub a portal for the KI community.
            </div>

            <ul style={{ listStyleType: 'disc'}}>
              <li>
                <p>View Rally updates.</p>
              </li>
              <li>
                <p>Edit and Update Metadata</p>
              </li>
            </ul>

          </div>
        </div>
        <div className="right">
          <div className="login-right-wrap sign-in-field">
            <h1>Sign In</h1>
            <form onSubmit={this.handleSubmit}>
              {this.props.error &&
              <h3 data-test="error" onClick={this.dismissError}>
                <button onClick={this.dismissError}>✖</button>
                {this.props.error}
              </h3>
              }
              <label>Email Address</label>
              <input
                type="text"
                data-test="username"
                value={this.state.username}
                onChange={(e) => this.setState({ username: e.target.value })}
              />

              <label>
                Password
                {/*<Link to={"/forgot/password"} className="subtext">Forgot password</Link>*/}
              </label>
              <input
                type="password"
                data-test="password"
                value={this.state.password}
                onChange={(e) => this.setState({ password: e.target.value })}
              />
              <input type="submit" className="submit-button" value="Log In" data-test="submit"/>
              {/*<a href={"#"} className="button-text-icon">Request an account*</a>*/}
            </form>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Login);
