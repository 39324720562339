import React, { Component } from 'react';
import { Redirect, Link } from "react-router-dom";
import axios from "axios";
import FollowLink from "../FollowLink";
import { Alert } from "react-bootstrap";
import Cards from "../Cards";
import moment from 'moment';

import { API } from '../../util/api';

class NewsTable extends Component {

  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      error: null,
      spacing: "200",
    };
  }

  componentWillMount() {
      axios.get(API + "/api/v1/news")
          .then((response) => {
              let news = response.data.data;
              news.forEach((n, i) => {
                  axios.get(API +"/api/v1/user/"+ n.user)
                      .then((response) => {
                          let user = response.data.data;
                          if (response.data.data && response.data.data.first_name !== undefined) {
                              news[i].user_name = user.first_name +" "+ user.last_name;
                          } else {
                              news[i].user_name = "No user available";
                          }
                          this.setState({ news });
                      })
                      .catch((err) => {
                          console.log(err);
                          this.setState({error:err});
                      });
              });
              this.setState({ isLoading: false });
          })
          .catch((err) => {
              console.log(err);
              this.setState({ error:err });
          });
  }

    componentDidCatch(error, info) {
        this.setState({ error: error });
        this.setState({ info: info });
    }

    render() {
        const {isLoading, error, news} = this.state;

        if (error) {
            return <Alert bsStyle="danger">{error.message}</Alert>;
        }
        if (isLoading) {
            return <p>Loading ...</p>;
        }
        return (
            <div>
              <div className="news-feed-header">
                <Link className="h2" to={"/news"}>News</Link>
                <Link to={"/news/edit/add"} className="button-text-icon add-button add-icon-only float-right">
                <div className="icon-wrap">
                  <svg viewBox="0 0 12 12" version="1.1">
                      <defs/>
                      <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                          <g id="buttons/addItem" transform="translate(-15.000000, -10.000000)" fillRule="nonzero" stroke="#4EB8C1" strokeWidth="2">
                              <g id="Group-25" transform="translate(15.000000, 10.000000)">
                                  <path d="M6.0625,0 L6.0625,12" id="Path-4"/>
                                  <path d="M0,6.0625 L12,6.0625" id="Path-5"/>
                              </g>
                          </g>
                      </g>
                  </svg>
                </div>
                </Link>
              </div>
                {news !== undefined ?
                    news.map((data, index) =>
                    <div className="feed-item" key={index}>
                          <div className="feed-item-header-cols">
                            <div className="feed-item-header-col feed-item-left-col subtext">
                              Last updated: {data.user_name}, {moment(data.updated_date).format('MMM. Do')}
                            </div>
                            <div className="feed-item-header-col feed-item-right-col subtext">
                              {data.program}
                            </div>
                          </div>
                          <Link to={"/news/"+data.id} className="label">{data.title}</Link>
                          <div className="body-small">{data.message}
                          </div>
                          <div>
                            {data.link ?
                              <span className="link-button-wrap"><a href={data.link} className="link-button">
                              {data.link_title}
                              <span className="right-align-icon">
                              <svg width="12px" height="12px" viewBox="0 0 12 12" version="1.1">
                              <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                              <g id="AccessDataBtn" transform="translate(-57.000000, -10.000000)" fill="#3A4652" fillRule="nonzero">
                              <polygon id="Shape" points="63 10 61.9425 11.0575 66.1275 15.25 57 15.25 57 16.75 66.1275 16.75 61.9425 20.9425 63 22 69 16"/>
                              </g>
                              </g>
                              </svg></span></a></span>
                              :null
                            }
                                </div>
                          </div>
                    )
                    : <div className='view-element'>Current there isn't any content</div>
                }
            </div>
        );

    }
}


export default NewsTable;
