import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';


class FourOhFour extends Component {
  constructor(props) {
    super(props);

  }

  render() {
    return (
      <div className="fourohfour">
        <h1>404 - <Link to={'/'}>Go back to Login</Link></h1>
      </div>
    )
  }
}
export default FourOhFour;
