import {
  LOADING_RALLIES,
  RALLIES_SUCCESS,
  RALLIES_FAIL,
  FETCHING_RALLY,
  RALLY_SUCCESS,
  RALLY_FAIL,
  UNMOUNT_RALLY
} from "../actions/rallies";

const initialState = {
  loading: false,
  loaded: false,
  error: null,
  rallies: [],
  selected: {
    rally: null,
    loading: false,
    loaded: false,
    error: null
  }
};

export default function ralliesReducer(state = {...initialState}, action) {
  switch (action.type) {
    case LOADING_RALLIES:
      return {
        ...state,
        loading: true,
      };
    case RALLIES_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        rallies: action.rallies,
      };
    case RALLIES_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case FETCHING_RALLY:
      return {
        ...state,
        selected: {
          ...state.selected,
          loading: true,
          loaded: false,
        }
      };
    case RALLY_SUCCESS:
      return {
        ...state,
        selected: {
          ...state.selected,
          rally: action.rally,
          loading: false,
          loaded: true
        }
      };
    case RALLY_FAIL:
      return {
        ...state,
        selected: {
          ...state.selected,
          error: action.error,
          loading: false,
        }
      };
    case UNMOUNT_RALLY: {
      return {
        ...state,
        selected: {
          rally: null,
          loading: false,
          loaded: false,
          error: null
        }
      }
    }
    default:
      return state;
  }
};