import React, { Component } from 'react';
import { connect } from 'react-redux';

import {Redirect, Link} from "react-router-dom";
import axios from "axios";
import FormTextInput from '../FormTextInput';
import Dropdown from "../Dropdown";
import Cards from "../Cards";
import {Alert} from "react-bootstrap";
import moment from 'moment';
import FormRadioInputs from "../FormRadioInputs";
import RepeatTextBoxes from "../RepeatTextBoxes";

import { API } from '../../util/api';
const programs = ['HBGD','MNCH','NTD','TB','HIV','Malaria','Family planning'];
const eStyle = {
    margin: "0px 5px 0px 0px"
};
const pStyle = {
    height: '15px'
};

const mapStateToProps = (state) => ({
  user: state.auth.user,
});

class NewsEdit extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: true,
            error: false,
            spacing: "200",
            autoSave: false,
            reDirect: false
        };
    }

    autoSave = () => {
        let link = "";
        if(this.props.match.params.newsID === "add"){
            this.setState({active: 1}, () => {
                link = API +"/news/add";
                axios.post(link, this.state)
                    .then((response) => {
                        let today = new Date();
                        let h = today.getHours();
                        let m = today.getMinutes();
                        let s = today.getSeconds();
                        if (h > 12) {
                            h = h - 12;

                        }
                        if (h < 10) {
                            h = "0" + h;
                        }
                        if (m < 10) {
                            m = "0" + m;
                        }
                        if (s < 10) {
                            s = "0" + s;
                        }
                        this.setState({savedTime: h + ":" + m + ":" + s});
                        if(response.status === 500 || response.status === 204){
                            this.setState({ autoSave: false });
                            this.setState({ user: response.data.message });
                            return false;
                        }
                        let news = response.data.data;
                        for (let key in news) {
                            if(news[key] === null || news[key] === undefined){
                                news[key] = "";
                            }
                            this.setState({[key]:news[key]});
                        }
                        axios.get(API +"/api/v1/user/"+ this.state.user)
                            .then((response) => {
                                let user = response.data.data;
                                this.setState({ user: user });
                                this.setState({ autoSave: false });
                                this.setState({ isLoading: false});
                            })
                            .catch((err) => {
                                console.log(err);
                                this.setState({ error: err });
                            });
                    })
                    .catch((err) => {
                        this.setState({savedTime: "!"});
                        this.setState({autoSave: false});
                        console.log(err);
                    });
            });

        } else {
            link = API +"/news/edit/"+ this.props.match.params.newsID;
            axios.put(link, this.state)
                .then((response) => {
                    let today = new Date();
                    let h = today.getHours();
                    let m = today.getMinutes();
                    let s = today.getSeconds();
                    if (h > 12) {
                        h = h - 12;
                    }
                    if (h < 10) {
                        h = "0" + h;
                    }
                    if (m < 10) {
                        m = "0" + m;
                    }
                    if (s < 10) {
                        s = "0" + s;
                    }
                    this.setState({savedTime: h + ":" + m + ":" + s});
                    if(response.status === 500 || response.status === 204){
                        this.setState({ user: response.data.message });
                        this.setState({ autoSave: false });
                        return false;
                    }
                    let news = response.data.data;
                    for (let key in news) {
                        if(news[key] === null || news[key] === undefined){
                            news[key] = "";
                        }
                        this.setState({[key]:news[key]});
                    }
                    axios.get(API + "/api/v1/user/" + this.state.user)
                        .then((response) => {
                            let user = response.data.data;
                            this.setState({ user: user });
                            this.setState({ isLoading: false });
                            this.setState({ autoSave: false });
                        })
                        .catch((err) => {
                            console.log(err);
                            this.setState({ error: err });
                        });
                })
                .catch((err) => {
                    this.setState({ savedTime: "!" });
                    this.setState({ autoSave: false });
                    console.log(err);
                });
        }

    };

    delete = (e) => {
        this.setState({ active: 0, reDirect: true }, () => {
            this.autoSave();
        });
    };

    handleSubmit = (e) => {
        this.setState({ autoSave: true, reDirect: true }, () => {
            this.autoSave();
        });
    };

    onChange = (e) => {
        this.setState({ autoSave: true });
        if (e.target !== undefined && e.target !== null) {
            this.setState({autoSave: true});
            let user = document.getElementById("user");
            this.setState({user: user.defaultValue}, () => {
                if (this.props.match.params.newsID === "add") {
                    this.setState({ [e.target.name]: e.target.value, autoSave: false }, () => {});
                } else {
                    this.setState({ [e.target.name]: e.target.value }, () => {
                        this.autoSave();
                    });
                }
            });
        } else {
            this.setState({ autoSave: true });
            let user = document.getElementById("user");
            this.setState({ user: user.defaultValue }, () => {
                e.name === "date_data_recd" ?
                    this.setState({[e.name]: e.defaultValue }, () => {
                        this.autoSave();
                    })
                    :
                    this.setState({[e.name]: e.value }, () => {
                        this.autoSave();
                    });
            });
        }
    };

    componentWillUnmount() {
        this.setState({locked: 0}, () => {
            this.autoSave();
            document.body.classList.remove('study');
        });
    }

    componentDidMount() {
        document.body.classList.toggle('study', true);
        axios.get(API + '/api/v1/news/' + this.props.match.params.newsID)
            .then((response) => {
                if (response.data.data) {
                    let news = response.data.data;
                    for (let key in news) {
                        if (news[key] === null || news[key] === undefined) {
                            news[key] = "";
                        }
                        this.setState({[key]: news[key]});
                    }
                    this.setState({ news, programs }, () => {
                        axios.get(API +"/api/v1/user/"+ this.state.user)
                            .then((response) => {
                                let user_name = "";
                                if( ! response.data.data.first_name){
                                    user_name = "No user listed";
                                } else {
                                    user_name = response.data.data.first_name +" "+ response.data.data.last_name;
                                }
                                this.setState({ user_name }, () =>{});
                                this.setState({ isLoading: false });
                            })
                            .catch((err) => {
                                console.log(err);
                                this.setState({error: err});
                            });
                    });
                } else {
                    this.setState({news: null});
                    this.setState({programs: programs});
                    this.setState({isLoading: false});
                }
            })
            .catch((err) => {
                console.log(err);
            });
    }

    reDirect = (url) => {
        return (<Redirect to={url} />);
    };

    componentDidCatch(error, info) {
        this.setState({ error: error });
        this.setState({ info: info });
    }

    render() {
        const { isLoading, error, autoSave, reDirect } = this.state;

        if (error) {
            return <Alert className="error">{error.message}</Alert>;
        }

        if (isLoading) {
            return <p>Loading ...</p>;
        }

        if (reDirect) {
            return (<Redirect to='/news' />);
        }


        return (
            <div>
                <div className="hero-heading-wrapper">
                    <div className="hero-heading-col">
                        <h1><Link className="backlink" to={"/news"}>{""}</Link>News Item</h1>
                    </div>
                    <div className="hero-heading-col">
                        <div className="button-text-icon" onClick={this.delete}>Delete</div>
                        <div className="submit-button" onClick={this.handleSubmit}>Save & Publish</div>
                    </div>
                </div>
                {this.props.match.params.newsID !== "add" ?
                    autoSave ?
                        <img src="/images/uploading.gif" style={pStyle}/>
                        : <p className="subtext">{"Last updated: " + this.state.user_name + ", " + moment(this.state.last_updated).format('MM.DD.YYYY')}</p>
                    : null
                }
                <input type={"hidden"} name={"user"} defaultValue={this.props.user.id} id={"user"}/>
                <FormTextInput type="text"
                               name={"title"}
                               required={true}
                               data={this.state.title}
                               title={"Title"}
                               parentChange={this.onChange}/>
                <Dropdown title={"Program"}
                          name={"program"}
                          data={programs}
                          selected={this.state.program}
                          parentChange={this.onChange}/>
                <FormTextInput type="textarea"
                               name={"message"}
                               required={true}
                               title={"Message"}
                               data={this.state.message}
                               parentChange={this.onChange}/>
                <RepeatTextBoxes type={"linked_file"}
                                 name={"links"}
                                 view={"mdrFormInput"}
                                 data={this.state.links}
                                 parentChange={this.onChange}/>
            </div>
        );

    }
}

export default connect(mapStateToProps)(NewsEdit);