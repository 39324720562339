import React, {Component} from 'react';
import { connect } from 'react-redux';

import {Redirect, Link} from "react-router-dom";
import axios from "axios";
import {Alert} from "react-bootstrap";
import Cards from "../Cards";
import StandardInput from "../StandardInput";
import FollowLink from "../FollowLink";
import moment from 'moment';

import { API } from '../../util/api';
const pStyle = {
    margin: "0px 0px 0px 75%"
};

const mapStateToProps = (state) => ({
  user: state.auth.user,
});

class NewsItem extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: true,
            error: null
        };
    }

    componentWillUnmount() {
        document.body.classList.remove('news-item');
    };

    componentDidMount() {
        document.body.classList.toggle('news-item', true);
        axios.get(API + "/api/v1/news/" + this.props.match.params.newsID)
            .then((response) => {
                let news = response.data.data;
                axios.get(API + "/api/v1/user/" + news.user)
                    .then((response) => {
                        let user_name = "";
                        if( ! response.data.data.first_name){
                            user_name = "No user listed";
                        } else {
                            user_name = response.data.data.first_name +" "+ response.data.data.last_name;
                        }
                        this.setState({ user_name }, () =>{});
                        this.setState({isLoading: false});
                    })
                    .catch((err) => {
                        console.log(err);
                        this.setState({error: err});
                    });
                this.setState({ news });
            })
            .catch((err) => {
                console.log(err);
                this.setState({error: err});
            });
    }

    render() {
        const {isLoading, error, news} = this.state;

        if (error) {
            return <Alert bsStyle="danger">{error.message}</Alert>;
        }

        if (isLoading) {
            return <p>Loading ...</p>;
        }



        return (
            <div>
                <div className="hero-heading-wrapper">
                <div className="hero-heading-col">
                <h1><Link className="backlink" to={"/news"}>{""}</Link>News Item</h1>
                </div>
                <div className="hero-heading-col">
                  <div style={pStyle}>
                      {this.props.user.id === news.user ?
                          <Link to={"/news/edit/"+news.id} className="add-item button-text-icon icon-left">
                          <span className="plus-wrap">
                          <svg version="1.1" id="Layer_1" x="0px" y="0px" viewBox="0 0 15 15">
    <path id="Shape" className="st0" d="M0.1,11.8v3.1h3.1l9.1-9L9.2,2.8L0.1,11.8z M14.6,3.5c0.3-0.3,0.3-0.8,0-1.2l-1.9-1.9
    c-0.3-0.3-0.8-0.3-1.2,0l-1.5,1.5L13.1,5L14.6,3.5z"/>
    </svg>
                          </span>
                          Edit</Link>
                          : null
                      }
                  </div>
                </div>
                </div>
                <div className="subtext last-edited-news-item">{this.state.user_name}, {moment(news.updated_at).format('MM.DD.YYYY')}</div>
                <div>
                    <StandardInput  data={news.title} title={"Title"}/>
                    <StandardInput title={"Program"} data={news.program}/>
                    <StandardInput title={"Message"} data={news.message}/>
                    {news.links ?
                        news.links.map((data,index) =>
                            <FollowLink title={data.title} data={data.link} key={index} />
                        )
                        :<FollowLink title={"No data"} data={""} />
                    }
                </div>
            </div>
        );

    }
}

export default connect(mapStateToProps)(NewsItem);
