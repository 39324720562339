import { hubApi } from '../util/api';

export const UPLOADING_FILE = 'UPLOADING_FILE';
export const UPLOAD_SUCCESS = 'UPLOAD_SUCCESS';
export const UPLOAD_FAIL = 'UPLOAD_FAIL';

export const uploadingFile = () => ({
  type: UPLOADING_FILE
});

export const uploadSuccess = file => ({
  type: UPLOAD_SUCCESS,
  file,
  receivedAt: Date.now()
});

export const uploadFail = error => ({
  type: UPLOAD_FAIL,
  error
});

/*
 * Upload single file
 * @param {action} action [Required]
 * @param {file} file [Required]
 */
export const uploadFile = (action, data) => (dispatch) => new Promise((resolve, reject) => {
  dispatch(uploadingFile());

  const endpoint = hubApi().endPoints.upload;

  let formData = new FormData();
  formData.append("file", data, data.name);

  const options = {
    headers: {
      'Content-Type': 'multipart/form-data',
    }
  };

  console.log(endpoint + action);
  console.log(data);
  console.log(formData);


  if (data || action) {
    hubApi().post(endpoint + action, formData, options)
      .then((response) => {
        console.log(response);
        let file = response.data.data;
        dispatch(uploadSuccess(file));
        resolve(file);
      })
      .catch((error) => {
        reject(error);
        dispatch(uploadFail(error))
      })
  } else {
    console.log("error");
    reject("No data or endpoint delivered");
    dispatch(uploadFail("No data or endpoint delivered"))
  }

});