import React, {Component} from 'react';
import { WithContext as ReactTags } from 'react-tag-input';
const KeyCodes = {
    comma: 188,
    enter: 13,
};
const delimiters = [KeyCodes.comma, KeyCodes.enter];

class Tags extends Component {
    constructor(props) {
        super(props);

        this.state = {
            tags: [],
            suggestions: [],
        };
    }

    handleDelete = (i) => {
        const { tags } = this.state;
        this.setState({ tags: tags.filter((tag, index) => index !== i) }, () => {
            let e = {};
            e.name = this.state.name;
            e.value = this.state.tags;
            this.props.parentChange(e);
        });
    };

    handleAddition = (tag) => {
        this.setState({ tags: [...this.state.tags, tag] }, () => {
            let e = {};
            e.name = this.state.name;
            e.value = this.state.tags;
            this.props.parentChange(e);
        });
    };

    handleDrag = (tag, currPos, newPos) => {
        const tags = [...this.state.tags];
        const newTags = tags.slice();
        newTags.splice(currPos, 1);
        newTags.splice(newPos, 0, tag);
        this.setState({ tags: newTags }, () => {
            let e = {};
            e.name = this.state.name;
            e.value = this.state.tags;
            this.props.parentChange(e);
        });
    };

    handleTagClick = (index) => {
        let e = {};
        e.name = this.state.name;
        e.value = this.state.tags;
        this.props.parentChange(e);
    };

    componentDidMount () {
        const { name, title, caption } = this.props;
        this.setState({ name, title, caption });
        let tags = [];
        if(this.props.data !== undefined && this.props.data){
            const data = this.props.data.split("','");
            data.forEach(function (value) {
                tags.push({ id: value, name: value });
            });
            this.setState({ tags }, () =>{});
        } else {
            tags = [{id: "None", name: "None"}];
            this.setState({ tags }, () =>{});
        }

        let suggestions_out = [];
        let suggs = this.props.suggestions;
        if(suggs !== undefined) {
            if(suggs.indexOf("','") !== -1) {
                suggs = suggs.split("','");
            }
            suggs.forEach(function (value) {
                suggestions_out.push({ id: value, name: value });
            });
            this.setState({ suggestions: suggestions_out });
        } else {
            this.setState({ suggestions: [{ id: "None", name: "None" }] });
        }
        this.setState({ isLoading: false });
    };

    render() {
        const { tags, suggestions, name, title, caption} = this.state;

        return (
            <div className='view-element'>
                <label>{title}</label>
                <div className='caption-text'>{caption}</div>
                <ReactTags tags={tags}
                           suggestions={suggestions}
                           minQueryLength={1}
                           handleDelete={this.handleDelete}
                           handleAddition={this.handleAddition}
                           handleDrag={this.handleDrag}
                           delimiters={delimiters}
                           name={name}
                           labelField={"name"}/>
            </div>
        );
    }
}

export default Tags;