import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';
import { connect } from 'react-redux';

import { ConnectedRouter } from 'connected-react-router'

import 'antd/dist/antd.css';
import './assets/scss/style.scss';

import PrivateRoute from './Components/partials/PrivateRoute';

import Login from './Components/Login';
import Home from './Components/KIFeed';
import Header from './Components/partials/Header';
import Rallies from './Components/Rallies/Rallies';
import RallyEdit from './Components/Rallies/RallyEdit';
import Rally from './Components/Rallies/Rally';
import Studies from './Components/Studies/Studies';
import Study from './Components/Studies/Study';
import StudyEdit from './Components/Studies/StudyEdit';
import People from './Components/People/People';
import Person from './Components/People/Person';
import PeopleEdit from "./Components/People/PeopleEdit";
import News from './Components/News/News';
import NewsEdit from "./Components/News/NewsEdit";
import NewsItem from "./Components/News/NewsItem";
import Reset from './Components/Reset';
import ForgotEmail from './Components/ForgotEmail';
import FourOhFour from './Components/partials/404';

import { fetchUserViaToken } from './actions/auth';

const mapStateToProps = state => ({
  auth: state.auth,
  user: state.auth.user
});

const mapDispatchToProps = dispatch => ({
  login: (token) => (
    dispatch(fetchUserViaToken(token))
  ),
});

class App extends Component {

  componentDidMount() {
    let token = localStorage.getItem("token");
    this.props.login(token);
  }

  render() {
    return (
      <ConnectedRouter history={this.props.history}>
        <div className="App">
          <div id="page-wrapper">
            <div className="left-column">
              <Header auth={this.props.auth} user={this.props.user} />
            </div>
            <div className="right-column">
              <Switch>
                <PrivateRoute path="/" exact component={Home} />
                <PrivateRoute path="/rallies" exact component={Rallies} />
                <PrivateRoute path="/rally/:rallyID" exact component={Rally} />
                <PrivateRoute path="/rally/edit/:rallyID" exact component={RallyEdit} />
                <PrivateRoute path="/studies" exact component={Studies} />
                <PrivateRoute path="/study/:studyID" exact component={Study} />
                <PrivateRoute path="/study/edit/:studyID" exact component={StudyEdit} />
                <PrivateRoute path="/people" exact component={People} />
                <PrivateRoute path="/people/:userID" exact component={Person} />
                <PrivateRoute path="/people/edit/:userID" exact component={PeopleEdit} />
                <PrivateRoute path="/news/edit/:newsID" exact component={NewsEdit} />
                <PrivateRoute path="/news/:newsID" exact component={NewsItem} />
                <PrivateRoute path="/news" exact component={News} />
                <Route path="/forgot/password" exact component={ForgotEmail} />
                <Route path="/reset/password/:userID" exact component={Reset} />
                <Route path="/reset/token/:userID/:token" exact component={Reset} />
                <Route path="/login" exact component={Login} />
                <Route component={FourOhFour} />
              </Switch>
            </div>
          </div>
        </div>
      </ConnectedRouter>
    );
  }
}


export default connect(mapStateToProps, mapDispatchToProps)(App);