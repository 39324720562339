import { createSelector } from 'reselect';

const activeRallies = (state) => {
  return state.rallies.rallies.filter((rally) => {
    let today = new Date();
    let newDate = new Date(rally.timeline[1]);
    return newDate.getTime() >= today.getTime() && (rally.status && rally.status.toLowerCase()) != 'cancelled';
  });
};

const completedRallies = (state) => {
  return state.rallies.rallies.filter((rally) => {
    let today = new Date();
    let newDate = new Date(rally.timeline[1]);
    return newDate.getTime() < today.getTime() && (rally.status && rally.status.toLowerCase()) != 'cancelled';
  });
};

const cancelledRallies = (state) => {
  return state.rallies.rallies.filter((rally) => (rally.status && rally.status.toLowerCase()) === 'cancelled'); 
};

const rallyParticipants = (state) => {
  let participants = [];
  state.rallies.rallies.forEach((rally) => {
    rally.participants.forEach((p, i) => {
      p = p.split("-");
      if (participants.indexOf(p[0].toLowerCase()) === -1 && p[0] !== "") {
        participants.push(p[0]);
      }
    });
  });
  return [...new Set(participants)];
};

const rallyTags = (state) => {
  let tags = [];
  state.rallies.rallies.forEach((rally) => {
    let tagList = rally.tags.split("','");
    tagList.forEach((tag) => {
      if (tag !== "") tags.push(tag.toLowerCase());
    })
  });
  return [...new Set(tags)];
};

const rallyTitles = (state) => {
  let titles = [];
  state.rallies.rallies.forEach((rally) => {
   if (rally.rally_number) {
     titles.push(rally.rally_number)
   }
  });
  return [...new Set(titles)];
};
const rallyById = (rallies, props) => rallies.find(rally => rally.id === props);

export const getActiveRallies = createSelector(
  [activeRallies],
  rallies => rallies,
);

export const getCompletedRallies = createSelector(
  [completedRallies],
  rallies => rallies,
);

export const getCancelledRallies = createSelector(
  [cancelledRallies],
  rallies => rallies,
);

export const getRallyParticipants = createSelector(
  [rallyParticipants],
  participants => participants,
);

export const getRallyTags = createSelector(
  [rallyTags],
  tags => tags,
);

export const getRallyTitles = createSelector(
  [rallyTitles],
  titles => titles,
);

export const getRallyById =createSelector(
  [rallyById],
  rally => rally,
)