import React, { Component } from 'react';
import { connect } from 'react-redux';
import axios from "axios";
import Cards from '../Cards.js';
import {Link, Redirect} from "react-router-dom";
import { Dropdown } from '../partials/InputBlock';
import LoadingScreen from '../partials/Loading';

import { fetchSingleUser, deleteUser, editUser } from "../../actions/users";
import { fetchRallies } from "../../actions/rallies";
import { API } from '../../util/api';

import {
  getParticpatingIn
} from '../../selectors/users';

const mapStateToProps = (state) => ({
  auth: state.auth.user,
  level: state.auth.level,
  loading: state.users.single.loading,
  loaded: state.users.single.loaded,
  user: state.users.single.user,
  error: state.users.single.error,
  ralliesLoaded: state.rallies.loaded,
  participatingIn: getParticpatingIn(state) ? getParticpatingIn(state) : [],
});

class Person extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: {},
      rallies: [],
      loaded: false,
      error: null,
    };
  }

  updateState = (name, value) => {
    console.log(name + " | " + value);
    if (name) {
      this.setState({
        user: {
          ...this.state.user,
          [name]: value,
        }
      });
    }
  };

  accept = () => {
    this.setState({
      user: {
        ...this.state.user,
        added: 1
      }
      }, () => {
        this.props.editUser(this.state.user)
    });
  };

  componentWillUnmount() {
    document.body.classList.remove('person');
  };

  componentDidMount() {
    document.body.classList.toggle('person', true);
    this.props.fetchRallies();
    this.props.fetchSingleUser(this.props.match.params.userID);
  }

  componentDidUpdate(prevProps) {
    if (JSON.stringify(this.props.user) !==  JSON.stringify(prevProps.user)) {
      let user = this.props.user;

      if (user) {
        for (let key in user) {
          if (user[key] === null || user[key] === undefined) {
            user[key] = "";
          }
          this.setState((state) => {
            return {
              loaded: true,
              user: {
                ...state.user,
                [key]: user[key]
              }
            }
          });
        }
      }
    }
  }

  render() {
    const { user, error } = this.state;

    if (error) return <p className="error">{error.message}</p>;
    if (!this.state.loaded) return <LoadingScreen interior />;

    return (
      <div className="App">
        <div className="wrapper">
          <div className="hero-heading-wrapper">
            <div className="hero-heading-col">
              <h1>
                {user.active === 1 && user.added === 1 ?
                  <div className="h1 section-heading">
                    <Link className="backlink" to={"/people"}>{""}</Link>
                    Person
                  </div>
                :
                  null
                }
                {user.active === 1 && user.added === 0 ?
                  <div className="h1 section-heading">Account request</div>
                :
                  null
                }
                {user.active === 0 && user.added === 1 ?
                  <div className="h1 section-heading">Archived person</div>
                :
                  null
                }
              </h1>
            </div>
            <div className="hero-heading-col">
              {user.active === 1 && user.added === 0 && this.props.level > 0 ?
                <div className={"text-right"}>
                  <button
                    onClick={() => this.props.deleteUser(user.id)}
                    className="button-text-icon icon-right icon-arrow"
                  >
                    Deny
                  </button>
                  {this.state.user.role !== "" ?
                    <button onClick={this.accept} className="button-text-icon icon-right icon-arrow">Accept</button>
                    :
                    <button className="button-text-icon icon-right icon-arrow disabled" disabled>Accept</button>
                  }
                </div>
                :
                <div>
                  {this.props.level > 0 || this.props.auth.id === user.id ?
                    <Link to={"/people/edit/" + user.id}
                          className="button-text-icon edit-button rally-header-btn icon-left">
                      <div className="icon-wrap">
                        <svg version="1.1" id="Layer_1" x="0px" y="0px" viewBox="0 0 15 15">
                          <path id="Shape" className="st0"
                                d="M0.1,11.8v3.1h3.1l9.1-9L9.2,2.8L0.1,11.8z M14.6,3.5c0.3-0.3,0.3-0.8,0-1.2l-1.9-1.9 c-0.3-0.3-0.8-0.3-1.2,0l-1.5,1.5L13.1,5L14.6,3.5z"/>
                        </svg>
                      </div>
                      Edit
                    </Link>
                    :
                    null
                  }
                </div>
              }
            </div>
          </div>
          <div className="profile-info profile-seperated">
          <div className="profile-left-col profile-col">
            <div className="profile-pic">
              <img
                className="profile-img"
                src={API + "/public/uploads/user/"+user.id+"/profile_pic/"+ user.profile_pic}
                onError={(e)=>{e.target.src="/uploads/user/profile_pic/accountphoto.png"}} />
            </div>
          </div>
          <div className="profile-right-col profile-col">
            <div className="h3 profile-name">{user.first_name + " " + user.last_name}</div>
              <div className="profile-attribute-cols">
                <div className="profile-attribute-left-col profile-attribute-col">
                  {user.primary_organizational_affiliation ?
                    <div className="profile-organisation profile-attribute body-small">
                      {user.primary_organizational_affiliation}
                    </div>
                  :
                    <div className="profile-organisation profile-attribute body-small">Nothing listed</div>
                  }
                  {user.location ?
                    <div className="profile-location profile-attribute body-small">{user.location}</div>
                  :
                    <div className="profile-location profile-attribute body-small">Nothing listed</div>
                  }
                </div>
                <div className="profile-attribute-right-col profile-attribute-col">
                  {user.email ?
                    <div className="profile-email profile-attribute body-small">{user.email}</div>
                  :
                    <div className="profile-email profile-attribute body-small">Nothing listed</div>
                  }
                  {user.role ?
                    <div className="profile-role profile-attribute body-small">{user.role}</div>
                  :
                    <div className="profile-role profile-attribute body-small">Nothing listed</div>
                  }
                </div>
              </div>
            </div>
          </div>
          {user.active === 1 && user.added === 0 ?
            <div className="profile-info profile-seperated">
              <div className="profile-right-col profile-col">
                <div className="profile-attribute-cols">
                  <div className="profile-attribute-left-col profile-attribute-col">
                    <div className="attribute-spacing">
                      <label>Requested on</label>
                      {user.created_date}
                    </div>
                    <div>
                      <label>Requested access</label>
                      <div>{user.role}</div>
                    </div>
                    <div className="profile-attribute-right-col profile-attribute-col">
                      <label>Given access</label>
                      <div className="free-floating-dropdown">
                        <Dropdown
                          updateValue={(name, value) => this.updateState(name, value)}
                          name="role"
                          options={this.state.user.roles}
                          selected={this.state.user.role}
                        />
                      </div>
                    </div>
                    <div>
                      <label>Additional Comments</label>
                      <div>{user.additional_comments}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          :
            <div className="profile-info profile-seperated">
              <div className="profile-right-col profile-col">
                <div className="profile-attribute-cols">
                  <div className="profile-attribute-left-col profile-attribute-col">
                    {user.highest_degree_attained ?
                      <div className="attribute-spacing">
                        <label>Highest degree attained</label>
                        {user.highest_degree_attained}
                      </div>
                    :
                      <div className="attribute-spacing">
                        <label>Highest degree attained</label>
                        Nothing listed
                      </div>
                    }
                    <div>
                      <label>Additional contact information</label>
                      <div>Email: {user.email ? user.email: "Nothing listed"}</div>
                      <div>Mobile: {user.mobile ? user.mobile: "Nothing listed"}</div>
                      <div>Linkedin: {user.linkdin ? user.linkdin: "Nothing listed"}</div>
                    </div>
                  </div>
                  <div className="profile-attribute-right-col profile-attribute-col">
                    {user.disciplines ?
                      <div className="attribute-spacing">
                        <label>Discipline(s)</label>
                        {user.disciplines}
                      </div>
                    :
                      <div className="attribute-spacing">
                        <label>Discipline(s)</label>
                        Nothing listed
                      </div>
                    }
                    {user.interests ?
                      <div>
                        <label>Interests</label>
                        {user.interests}
                      </div>
                    :
                      <div>
                        <label>Interests</label>
                        Nothing listed
                      </div>
                    }
                  </div>
                </div>
              </div>
            </div>
          }
          {user.active !== 0 && user.added !== 0 ?
            <div>
              <div className="profile-info participant-in">
                <label>Participant in</label>
              </div>
              <div>
                <Cards view={"rally"} user={this.props.user} data={this.props.participatingIn}/>
              </div>
            </div>
          :
            null
          }
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps, { fetchSingleUser, fetchRallies, deleteUser, editUser })(Person);
