import React, { Component } from 'react';
import {AuthConsumer} from 'react-check-auth';
import {Redirect, Link} from "react-router-dom";
import axios from "axios";
import moment from 'moment';

import { API } from '../../util/api';
class StudyTable extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: true,
            error: null,
            spacing: "200",
        };
    }

    componentWillMount() {
        axios.get(API + "/api/v1/updates/study/10")
            .then((response) => {
                let studies = response.data.data;
                this.setState({studies:studies});
                studies.forEach((val, i) => {
                    let ret = [];
                    let user = "";
                    axios.get(API +"/api/v1/user/"+ val.user)
                        .then((response) => {
                            if(response.data.data && response.data.data.first_name !== undefined){
                                ret = response.data.data;
                                user = ret.first_name +" "+ ret.last_name;
                            }else{
                                user = "no user listed";
                            }
                            studies[i].user_name = user;
                            this.setState({ studies });
                        })
                        .catch((err) => {
                            console.log(err);
                        });
                    if(val.data_status === "Data released for use"){
                        studies[i].message = "Study released for use";
                    } else if (val.data_status === null || val.data_status === "Not Transmitted yet") {
                        studies[i].message = "Study created";
                    } else {
                        studies[i].message = "Study updated";
                    }
                });
                let countries = [];
                let data_s = [];
                let method_types = [];
                response.data.data.forEach(function (value) {
                    if(value.country){
                        let country = value.country.split("','");
                        country.forEach(function (c) {
                            if (countries.indexOf(c) === -1 && c !== "") {
                                countries.push(c);
                            }
                        });
                    }
                    if (value.method_type) {
                        if (method_types.indexOf(value.method_type) === -1) {
                            method_types.push(value.method_type);
                        }
                    }
                    if (data_s.indexOf(value.data_status) === -1) {
                        data_s.push(value.data_status);
                    }
                });
                this.setState({method_types: method_types});
                this.setState({countries: countries});
                this.setState({data_s: data_s});
                axios.get(API + "/api/v1/study")
                    .then((response) => {
                        let total = response.data.data;
                        let rel = total.filter(function (obj) {
                            return obj.data_status === 'Data released for use';
                        });
                        let pend = total.filter(function (obj) {
                            return obj.data_status !== 'Data released for use';
                        });
                        this.setState({released: rel});
                        this.setState({pending: pend});
                        this.setState({isLoading: false});
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            })
            .catch((err) => {
                console.log(err);
            });
    };

    componentDidCatch(error, info) {
        this.setState({ error: error });
        this.setState({ info: info });
    }

    render() {
        const { released, pending, isLoading, error, studies } = this.state;

        if (error) {
            return <p className="error">{error.message}</p>;
        }
        if (isLoading) {
            return <p>Loading ...</p>;
        }

        return (
          <div>
              <div className="feed-header">
                  <div className="feed-header-col feed-header-left-col">
                      <Link to={"/studies"}>Studies</Link>
                  </div>
                  <div className="feed-header-col feed-header-right-col">
                      <div className="feed-info">{released.length} data released</div>
                      <div className="feed-info">{pending.length} data pending</div>
                      <Link to={"/study/edit/add"} className="button-text-icon add-button add-icon-only float-right display-none">
                      <div className="icon-wrap">
                        <svg viewBox="0 0 12 12" version="1.1">
                            <defs/>
                            <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                <g id="buttons/addItem" transform="translate(-15.000000, -10.000000)" fillRule="nonzero" stroke="#4EB8C1" strokeWidth="2">
                                    <g id="Group-25" transform="translate(15.000000, 10.000000)">
                                        <path d="M6.0625,0 L6.0625,12" id="Path-4"/>
                                        <path d="M0,6.0625 L12,6.0625" id="Path-5"/>
                                    </g>
                                </g>
                            </g>
                        </svg>
                      </div>
                      </Link>
                  </div>
              </div>
            {/*//<div>*/}
            {/*//<h3>Studies {released.length} data released {pending.length} data pending </h3>*/}
            <div className="feed-content">
                {studies != null ?
                    studies.map((data,index) =>
                        // <div className={"view-element"} key={index}>
                        <div className="feed-item" key={index}>
                            <div className="status status-active"/>
                            <div className="feed-item-header-cols">
                              <div className="feed-item-header-col feed-item-left-col subtext">
                                Last updated: {data.user_name}, {moment(data.last_updated).format('MMM. Do')}
                              </div>
                              <Link to={"/study/edit/"+data.id} className="feed-item-header-col feed-item-right-col subtext">
                                {data.program}
                              </Link>
                            </div>
                            <div className="body-small">{data.message}</div>
                            <Link to={"/study/"+data.id} className="label">{data.short_id}</Link>
                            <div className="body-small">{data.study_title}
                            </div>
                        </div>
                    )
                    : <div className='view-element'>No data for this field</div>
                }
            </div>
          </div>
        );

    }

}
export default StudyTable;
