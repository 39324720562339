import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link, Redirect } from "react-router-dom";
import { Tab, Tabs, Alert } from "react-bootstrap";
import moment from 'moment';


import { BasicInput, TextArea, Dropdown, Tags, DateRange, RadioSelect, Repeater, ImportRally, ImportCheckbox } from '../partials/InputBlock';
import { SingleFile } from '../partials/FileUpload';
import { Modal } from "../partials/Modal";
import FollowLink from "../FollowLink";
import LoadingScreen from '../partials/Loading';

import { ReactComponent as Error } from '../../assets/images/error.svg'

import { fetchRallyById, updateRally, createRally, redirectToEdit, deleteRally, fetchRallyRoles } from '../../actions/rallies';
import { fetchUsers } from '../../actions/users';
import { fetchStudies } from "../../actions/studies";
import { createSynapse, updateSynapse } from "../../actions/synapse";
import { uploadFile } from "../../actions/upload";

import { getAvailableSprintLetter } from "../../util/helpers"

import {
  getSimpleUserList
} from '../../selectors/users';

const mapStateToProps = (state) => ({
  user: state.auth.user,
  level: state.auth.level,
  users: {
    list: getSimpleUserList(state) ? getSimpleUserList(state) : [],
  },
  rally: {
    selected: state.rallies.selected.rally,
    loading: state.rallies.selected.loading,
    loaded: state.rallies.selected.loaded,
    error: state.rallies.selected.error,
    all: state.rallies.rallies,
  },
  studies: {
    list: state.studies.studies,
    loaded: state.studies.loaded,
    loading: state.studies.loading,
    error: state.studies.error,
  },
  synapse: {
    inProgress: state.synapse.inProgress
  },
  upload: {
    loading: state.upload.loading,
    file: state.upload.file
  }
});

const final_impact_values = [
  "confirmed hypothesis",
  "generated new hypothesis",
  "integrated datasets",
  "facilitated a strategic program decision"
];

const rally_roles = [
  'data scientist',
  'domain expert',
  'rally master',
  'rally observer',
  'deputy director',
  'program officer',
  'product owner'
];


const eStyle = {
  margin: "0px 5px 0px 0px"
};

class RallyEdit extends Component {

  constructor(props) {
    super(props);

    this.state = {
      create: this.props.match.params.rallyID === "add",
      key: localStorage.getItem("current_tab") ? parseInt(localStorage.getItem("current_tab")) : 0,
      data: {},
      ogData: null,
      loaded: {
        rally: false,
        studies: false,
      },
      studies: null,
      rallyList: props.rally.all.length !== 0
        ? props.rally.all
        : (localStorage.getItem("rally_list") ? JSON.parse(localStorage.getItem("rally_list")) : []),
      alphabet: "abcdefghijklmnopqrstuvwxyz".split(""),
      statuses: ["pre-planning","planning","analysis","complete","cancelled"],
      saving: false,
      error: {
        ppt: null,
        synapse: null,
        default: null
      },
      formValidation: null,
      participantValidation: null,
      deleteModal: false,
      unsaved: {
        status: false,
        modal: false
      },
      importSprint: {
        loading: false,
        modal: false,
        confirmed: {
          open: false,
          error: false,
        },
        error: null,
        selected: null,
        label: null,
        importKeys: {
          rally_title: true,
          rally_number: true,
          participants: true,
          tags: true,
          background: true,
          motivation: true,
          ghap_repo: true,
        },
      }
    };
  }

  changeKey = (key) => {
    this.setState({key}, () => {
      localStorage.setItem("current_tab", key);
    });
    let el = document.getElementsByClassName("tab-content");
    el[0].scrollTo(0,0);
  };

  cacheRallyList = () => {
    if (this.props.rally.all.length !== 0) {
      localStorage.setItem("rally_list", JSON.stringify(this.props.rally.all))
    }
  };

  synapseUpdate = () => {
    this.props.updateSynapse(this.props.user.synapse_id, this.state.data)
      .then((id) => {
        // Set payload to synpase_id in state.data
        this.setState({
          data: {
            ...this.state.data,
            sprint_osf: 'https://www.synapse.org/#!Synapse:' + id,
          },
          error: {
            ...this.state.error,
            synapse: null,
          }
        })
      })
      .catch((err) => {
        this.setState({ error: {
            ...this.state.error,
            synapse: "Failed to create Synapse space"
          }
        })
      })
  };

  synapseCreate = () => {
    this.props.createSynapse(this.props.user.synapse_user, this.state.data)
      .then((id) => {
        this.setState({
          data: {
            ...this.state.data,
            sprint_osf: 'https://www.synapse.org/#!Synapse:' + id,
          },
          error: {
            ...this.state.error,
            synapse: null,
          }
        });
        this.updateRally(true);
      })
      .catch((err) => {
        this.setState({ error: {
          ...this.state.error,
          synapse: "Failed to create Synapse space"
          }
        })
      })
  };

  updateRally = (redirect) => {
    if ( this.state.data.participants.length > 0) {
      this.setState({ saving: true });
      this.props.updateRally(this.state.data.id, this.state.data, redirect);
    } else {
      this.setState({
        participantValidation: "Please fill out all fields"
      })
    }
  };

  handleImport = (selected, options) => {
    // Fetch rally since the backend doesnt send roles for whatever damn reason
    this.props.fetchRallyRoles(selected.id).then((res) => {

      this.setState({
        importSprint: {
          ...this.state.importSprint,
          loading: true,
        }
      });
      const formatBackground = (str) => {
        if (str !== null || str !== '') return str.replace(/^"(.*)"$/, '$1')
      };
      const getLetter = () => {
        const rallyNum = options.rally_number ? selected.rally_number : this.state.data.rally_number;
        const selectedLetter = this.state.data.sprint_letter ? this.state.data.sprint_letter : selected.sprint_letter
        return getAvailableSprintLetter(rallyNum, selectedLetter, this.state.rallyList)
      };

      const newImport = {
        ...this.state.data,
        rally_title: options.rally_title ? selected.rally_title : this.state.data.rally_title,
        rally_number: options.rally_number ? selected.rally_number : this.state.data.rally_number,
        participants: options.participants ? res.participants : this.state.data.participants,
        roles: options.participants ? res.roles : this.state.data.participants,
        tags: options.tags ? selected.tags : this.state.data.tags,
        background: options.background ? formatBackground(selected.background) : this.state.data.background,
        motivation: options.motivation ? selected.motivation : this.state.data.motivation,
        ghap_repo: options.ghap_repo ? selected.ghap_repo : this.state.data.ghap_repo,
        timeline: this.state.data.timeline ? this.state.data.timeline : selected.timeline,
        sprint_letter: getLetter(),
        sprint_title: this.state.data.sprint_title ? this.state.data.sprint_title : selected.sprint_title,
      };


      this.props.createRally(newImport)
        .then((id) => {
          this.setState({
            importSprint: {
              ...this.state.importSprint,
              loading: false,
              modal: false,
              confirmed: {
                ...this.state.importSprint.confirmed,
                open: true,
                id,
              }
            }
          })
        })
        .catch((err) => {
          this.setState({
            importSprint: {
              ...this.state.importSprint,
              loading: false,
              modal: false,
              confirmed: {
                open: true,
                error: true
              }
            }
          })
        })
    })
  };

  handleImportReload = (id) => {
    this.setState({
      create: false,
      loaded: {
        ...this.state.loaded,
        rally: false,
      }
    }, () => {
      this.props.fetchRallyById(id)
    })
  };

  createRally = () => {
    let data = this.state.data;
    if (data.rally_number === "" || data.sprint_letter === "" || data.timeline === "") {
      this.setState({
        formValidation: "Please fill out all fields"
      })
    } else {
      console.log(getAvailableSprintLetter(data.rally_number, data.sprint_letter, this.state.rallyList, true))
      if (!getAvailableSprintLetter(data.rally_number, data.sprint_letter, this.state.rallyList, true)) {
        this.setState({
          formValidation: "Sprint letter is use. Please select another"
        })
      } else {
        this.props.createRally(data)
          .then((id) => {
            this.setState({
              create: false,
              loaded: {
                ...this.state.loaded,
                rally: false,
              }
            }, () => {
              this.props.fetchRallyById(id)
            })
          })
          .catch((err) => {
            console.log("Couldn't create rally")
          })
      }
    }
  };

  uploadAnalysisSlide = (file) => {
    let endpoint = 'analysis/upload/' + this.state.data.id + "/data_description";
    this.props.uploadFile(endpoint, file)
      .then((fileName) => {
        console.log(fileName);
        this.setState({
          data: {
            ...this.state.data,
            data_description: fileName
          },
          error: {
            ...this.state.error,
            ppt: null,
          }
        })
      })
      .catch(() => {
        this.setState({
          error: {
            ...this.state.error,
            ppt: "ERROR: Please attach a PPT with the same template structure"
          }
        })
      })
  };

  updateState = (name, value) => {
    console.log(name + " | " + value);
    if (this.state.ogData === null) this.setState({ ogData: {...this.state.data} });
    if (name) {
      this.setState({
        data: {
          ...this.state.data,
          [name]: value,
        }
      }, () => {
        if (JSON.stringify(this.state.data) !== JSON.stringify(this.state.ogData)) {
          this.setState({ unsaved: {...this.state.unsaved, status: true } })
        } else {
          this.setState({ unsaved: {...this.state.unsaved, status: false } })
        }
      });
    }
  };

  componentDidMount() {
    document.body.classList.toggle('rally-edit', true);
    document.body.classList.toggle('tab-view', true);

    if (this.props.rally.loaded) {
      if (this.props.match.params.rallyID === 'add') {
        this.props.fetchRallyById(this.props.match.params.rallyID);
      } else {
        let rally = this.props.rally.selected;
        for (let key in rally) {
          if (rally[key] === null || rally[key] === undefined) {
            rally[key] = "";
          }
          this.setState((state) => {
            return {
              data: {
                ...state.data,
                [key]: rally[key]
              }
            }
          });
        }
        this.setState((state) => {
          let fd = [];
          if (!state.data.final_documentation === '') {
            fd = JSON.parse(state.data.final_documentation);
          }
          return {
            loaded: {
              ...state.loaded,
              rally: true,
            },
            data: {
              ...state.data,
              final_documentation: fd,
              user: this.props.user.id
            }
          }
        })
      }
    } else {
      this.props.fetchRallyById(this.props.match.params.rallyID);
    }

    // if (!this.props.users.loaded) {
    //   this.props.fetchUsers();
    // }

    if (!this.props.studies.loaded) {
      this.props.fetchStudies();
    } else {
      let studies = this.props.studies.list;
      let studyArray = [];
      if (studies) {
        studies.forEach((value) => {
          studyArray.push(value.study_id);
        });
        this.setState((state) => {
          return {
            studies: studyArray.join("','"),
            loaded: {
              ...state.loaded,
              studies: true,
            },
          }
        });
      }
    }
    this.cacheRallyList();
  };

  componentDidUpdate(prevProps) {
    if (JSON.stringify(this.props.rally.selected) !==  JSON.stringify(prevProps.rally.selected)) {
      let rally = this.props.rally.selected;

      if (rally) {
        if (this.props.match.params.rallyID === 'add') {
          rally.columns.forEach((col) => {
            this.setState((state) => {
              return {
                data: {
                  ...state.data,
                  [col.COLUMN_NAME]: ''
                }
              }
            })
          });

          this.setState((state) => {
            return {
              loaded: {
                ...state.loaded,
                rally: true,
                studies: true,
              },
              data: {
                ...state.data,
                rally_number: rally.values.rally_high,
                user: this.props.user.id
              }
            }
          })

        } else {

          for (let key in rally) {
            if (rally[key] === null || rally[key] === undefined) {
              rally[key] = "";
            }
            this.setState((state) => {
              return {
                data: {
                  ...state.data,
                  [key]: rally[key]
                }
              }
            });

            this.setState((state) => {
              let fd = [];
              if (!state.data.final_documentation === '') {
                fd = JSON.parse(state.data.final_documentation);
              }
              return {
                loaded: {
                  ...state.loaded,
                  rally: true,
                },
                data: {
                  ...state.data,
                  final_documentation: fd,
                  user: this.props.user.id
                }
              }
            })
          }
        }
      }
      this.cacheRallyList()
    }

    if (JSON.stringify(this.props.studies.list) !==  JSON.stringify(prevProps.studies.list)) {
      let studies = this.props.studies.list;
      let studyArray = [];
      if (studies) {
        studies.forEach((value) => {
          studyArray.push(value.study_id);
        });
        this.setState((state) => {
          return {
            studies: studyArray.join("','"),
            loaded: {
              ...state.loaded,
              studies: true,
            },
          }
        });
      }
    }
  }

  componentDidCatch(error) {
    this.setState({
      error: {
        ...this.state.error,
        default: error
      }
    });
  }

  componentWillUnmount() {
    //this.handleSubmit();
    this.cacheRallyList();
    document.body.classList.remove('rally-edit');
    document.body.classList.remove('tab-view');
    window.removeEventListener("beforeunload", this.onUnload)
  };

  render() {
    const {
      create,
      data,
      saving,
      error
    } = this.state;

    const {
      user
    } = this.props;


    if (error.default) return <Alert bsStyle="danger">{ error }</Alert>;

    if (this.props.level === 0 || this.props.level === 1) {
      return (<Redirect to={'/rallies'}/>);
    } else {

      if (!this.state.loaded.rally || !this.state.loaded.studies) return <LoadingScreen interior/>;

      if (create) {
        return (
          <div className="right-column-content">
            {this.state.importSprint.modal ?
              <Modal
                title={`Import data from Sprint ${this.state.importSprint.label ? this.state.importSprint.label : ''}`}
                caption="Select fields you'd like to import"
                secondaryAction={() => this.setState({importSprint: {
                  ...this.state.importSprint,
                  modal: false,
                }})}
                secondaryText="Cancel"
                primaryAction={() => this.handleImport(this.state.importSprint.selected, this.state.importSprint.importKeys)}
                primaryText={this.state.importSprint.loading
                  ? <img src="/images/uploading.gif" className="loading-icon"/>
                  : "Import Data"
                }
              >
                <ImportCheckbox
                  options={this.state.importSprint.importKeys}
                  update={(value, bool) => {
                    this.setState({
                      importSprint: {
                        ...this.state.importSprint,
                        importKeys: {
                          ...this.state.importSprint.importKeys,
                          [value]: bool,
                        }
                      }
                    })
                  }}
                />
              </Modal>
              :
              null
            }
            {this.state.importSprint.confirmed.open ?
              <Modal
                title={this.state.importSprint.confirmed.error
                  ? 'Could not import at this time. Try again'
                  : 'Data Successfully Imported!'}
                caption={!this.state.importSprint.confirmed.error
                  ? `This sprint has been saved with data from ${this.state.importSprint.label ? this.state.importSprint.label : ''}`
                  : null}
                primaryAction={!this.state.importSprint.confirmed.error
                  ? () => this.handleImportReload(this.state.importSprint.confirmed.id)
                  : () => this.setState({ importSprint: {
                      ...this.state.importSprint,
                      confirmed: { open: false, error: false }
                    }})
                }
                primaryText="Return to Sprint"
              />
              :
              null
            }
            <div className="interior-header">
              <div className="row rally-edit-header">
                <div className="col-sm-8 header-title">
                  <h1>
                    <Link className="backlink" to={"/rallies"}>{""}</Link>
                    {data.rally_number + data.sprint_letter}
                  </h1>
                  <p className="body-small">{data.sprint_title}</p>
                </div>
              </div>
            </div>

            <input type={"hidden"} id={"user"} defaultValue={this.props.user.id}/>
            <input type={"hidden"} id={"synapse_id"} value={this.props.user.synapse_id}/>

            <Tabs activeKey={0} defaultActiveKey={0} animation={true} onSelect={this.changeKey}
                  id={"tab-" + 0} className="tab-header">
              <Tab eventKey={0} title={data.rally_title}>
                <BasicInput
                  title="Rally Number"
                  caption="The rally number (e.g. 1, 2, etc)"
                  updateValue={(name, value) => this.updateState(name, value)}
                  name="rally_number"
                  data={data.rally_number}
                  type="number"
                  maxlength="255"
                  required={true}
                />
                <BasicInput
                  title="Rally Title"
                  caption="A brief title of the rally"
                  updateValue={(name, value) => this.updateState(name, value)}
                  name="rally_title"
                  data={data.rally_title}
                  type="text"
                  maxlength="255"
                />
                <Dropdown
                  title="Sprint Letter"
                  caption="Sprint letters should be sequential A to Z"
                  updateValue={(name, value) => this.updateState(name, value)}
                  name="sprint_letter"
                  options={this.state.alphabet}
                  selected={data.sprint_letter}
                  isSearchable
                  isClearable
                />
                <BasicInput
                  title="Sprint Title"
                  caption="A brief title of the sprint"
                  updateValue={(name, value) => this.updateState(name, value)}
                  name="sprint_title"
                  data={data.sprint_title}
                  type="text"
                  maxlength="255"
                />
                {this.state.rallyList.length !== 0 ?
                  <div>
                    <ImportRally
                      title="Import Sprint Data"
                      caption="Copy data from a previous sprint (Title, Number, Participants, Tags, Background, Value Hypothesis, and Analysis Git Repo)"
                      updateValue={(name, value, selected) => {
                        this.updateState(name, value);
                        this.setState({importSprint: {
                            ...this.state.importSprint,
                            selected: selected ? selected.rally : null,
                            label: selected ? selected.label : null,
                        }})
                      }}
                      name="import_sprint"
                      options={this.state.rallyList}
                      selected={data.import_sprint}
                      btnAction={() => this.setState({
                        importSprint: {
                          ...this.state.importSprint,
                          modal: true,
                        }
                      })}
                      isSearchable
                      isClearable
                    />
                  </div>
                : null}
                <DateRange
                  title="Start and End Date"
                  caption1="Start Date"
                  caption2="End Date"
                  updateValue={(name, value) => this.updateState(name, value)}
                  name="timeline"
                  data={data.timeline}
                  type="date"
                />
                {this.state.formValidation ?
                  <p className="error">{this.state.formValidation}</p>
                :
                  null
                }
                <div
                  onClick={() => this.createRally()}
                  className="button-text-icon icon-right icon-arrow">
                  Create sprint
                </div>
              </Tab>
            </Tabs>
          </div>
        );
      }

      return (
        <div className="right-column-content">
          {this.state.deleteModal ?
            <Modal
              title="Delete Sprint"
              caption="This will permanently delete all metadata input for this sprint."
              secondaryAction={() => this.setState({deleteModal: false})}
              secondaryText="Cancel"
              primaryAction={() => this.props.deleteRally(data.id)}
              primaryText="Delete Sprint"
            />
            :
            null
          }
          {this.state.unsaved.modal ?
            <Modal
              title="Submit Changes"
              caption="Do you want to submit the changes made to this sprint?"
              secondaryAction={() => this.setState({unsaved: {...this.state.unsaved, modal: false }})}
              secondaryText="Cancel"
              primaryAction={() => { this.setState({unsaved: {...this.state.unsaved, modal: false }}, () => this.updateRally(false) ) } }
              primaryText="Submit"
              tertiaryAction={() => this.props.history.push("/rally/" + data.id)}
              tertiaryText="Don't Submit"
            />
            :
            null
          }
          <div className="interior-header">
            <div className="row rally-edit-header">
              <div className="col-sm-8 header-title">
                <h1>
                  <div className="backlink" onClick={() => {
                    if (this.state.unsaved.status) {
                      this.setState({unsaved: {...this.state.unsaved, modal: true }})
                    } else {
                      this.props.history.push("/rally/" + data.id);
                    }
                  }}>{""}</div>
                  {data.rally_number + "" + data.sprint_letter}
                </h1>
                <p className="body-small">{data.sprint_title}</p>
              </div>
              <div className="col-sm-4 button-controls">
                <Dropdown
                  updateValue={(name, value) => this.updateState(name, value)}
                  name="status"
                  options={this.state.statuses}
                  selected={data.status}
                />
                <div className="button-text-icon submit-button" onClick={() => this.updateRally(false)}
                     style={eStyle}>Submit
                </div>
              </div>

              {saving && user ?
                <div className="row last-updated-box">
                  <p className="subtext">
                    <img src="/images/uploading.gif" className="loading-icon"/>
                  </p>
                </div>
                :
                <div className="row last-updated-box">
                  {data.username ?
                    <p className="subtext">
                      {this.state.participantValidation ?
                        <span className="error">Participants required. Please add participants and try again.</span>
                        : "Last updated: " + data.username + ", " + moment(data.updated_date).format('LT')}
                    </p>
                    :
                    <p className="subtext">Creating new sprint</p>
                  }
                </div>
              }
            </div>
            <input type={"hidden"} id={"user"} defaultValue={this.props.user.id}/>
          </div>

          <Tabs
            activeKey={this.state.key}
            defaultActiveKey={0}
            animation={true}
            onSelect={this.changeKey}
            id={"tab-" + 0}
            className="tab-header"
          >
            <Tab eventKey={0} title="Title">
              <BasicInput
                title="Rally Number"
                caption="The rally number (e.g. 1, 2, etc)"
                updateValue={(name, value) => this.updateState(name, value)}
                name="rally_number"
                data={data.rally_number}
                type="number"
                maxlength="255"
                required={true}
              />
              <BasicInput
                title="Rally Title"
                caption="A brief title of the rally"
                updateValue={(name, value) => this.updateState(name, value)}
                name="rally_title"
                data={data.rally_title}
                type="text"
                maxlength="255"
              />
              <Dropdown
                title="Sprint Letter"
                caption="Sprint letters should be sequential A to Z"
                updateValue={(name, value) => this.updateState(name, value)}
                name="sprint_letter"
                options={this.state.alphabet}
                selected={data.sprint_letter}
                isSearchable
              />
              <BasicInput
                title="Sprint Title"
                caption="A brief title of the sprint"
                updateValue={(name, value) => this.updateState(name, value)}
                name="sprint_title"
                data={data.sprint_title}
                type="text"
                maxlength="255"
              />
              <div className="footer-buttons">
                <div onClick={() => this.changeKey(1)} className="button-text-icon icon-right icon-arrow">Planning</div>
                <div onClick={() => this.setState({deleteModal: true})} className="button-text-icon">
                  Delete Sprint
                </div>
              </div>
            </Tab>

            <Tab eventKey={1} title="Planning">
              <DateRange
                title="Start and End Date"
                caption1="Start Date"
                caption2="End Date"
                updateValue={(name, value) => this.updateState(name, value)}
                name="timeline"
                data={data.timeline}
                type="date"
              />

              <Repeater
                block="multi-dropdown"
                title="Participants"
                caption="List of the participants and roles."
                updateValue={(name, value) => this.updateState(name, value)}
                name1="participants"
                name2="roles"
                data={data.participants}
                data2={data.roles}
                dropdown1={this.props.users.list}
                dropdown2={rally_roles}
              />

              {/*<label>Create / Update Synapse space</label>*/}
              {/*<div*/}
                {/*className='caption-text'>{"Update Synapse space with sprint title and participants information."}</div>*/}
              {/*{data.sprint_osf ?*/}
                {/*<button className={"button-text-icon add-item icon-left"} onClick={this.synapseUpdate}>Update*/}
                  {/*Space</button>*/}
                {/*:*/}
                {/*<button className={"button-text-icon add-item icon-left icon-plus"} onClick={this.synapseCreate}>Create*/}
                  {/*Space</button>*/}
              {/*}*/}
              {/*{this.props.synapse.inProgress ?*/}
                {/*<div>In Progress. Please wait</div>*/}
                {/*:*/}
                {/*null*/}
              {/*}*/}
              {/*{error.synapse ?*/}
                {/*<p className="error">*/}
                  {/*<Error/>*/}
                  {/*{this.state.error.synapse}*/}
                {/*</p>*/}
                {/*:*/}
                {/*null*/}
              {/*}*/}

              {/*<FollowLink data={data.sprint_osf} url={data.sprint_osf}/>*/}

              <BasicInput
                title="Synapse Space URL"
                caption="URL for this sprint's Synapse Space"
                updateValue={(name, value) => this.updateState(name, value)}
                name="sprint_osf"
                data={data.sprint_osf}
                type="text"
                maxlength="255"
              />

              <Tags
                title="Tags"
                caption="Enter keywords that reflect the research area, methods, and key variables."
                updateValue={(name, value) => this.updateState(name, value)}
                name="tags"
                data={data.tags}
                suggestions={data.tags_all}
              />
              <Repeater
                block="textarea"
                title="Sprint Questions/Focus"
                caption="Research Question(s) Refined by product owner so that questions represent the body of work being addressed in this sprint."
                updateValue={(name, value) => this.updateState(name, value)}
                name="sprint_question"
                data={data.sprint_question}
              />
              <TextArea
                title="Background"
                caption="In a sentence or two, please describe what has been done before and any deficiencies that are prompting the primary objectives for this work."
                updateValue={(name, value) => this.updateState(name, value)}
                name="background"
                data={data.background}
              />
              {/*<BasicInput*/}
                {/*title="Analysis Plan"*/}
                {/*caption="If this rally has an analysis plan, add Synapse ID here."*/}
                {/*updateValue={(name, value) => this.updateState(name, value)}*/}
                {/*name="analysis_plan"*/}
                {/*data={data.analysis_plan}*/}
                {/*type="text"*/}
                {/*maxlength="255"*/}
              {/*/>*/}
              <TextArea
                title="Value Hypothesis"
                caption="What is the primary intended benefit of this work?"
                caption2="(i.e. What is the ‘so what’?. For example, will this work enable a decision? How would you make this decision without this work?)"
                updateValue={(name, value) => this.updateState(name, value)}
                name="motivation"
                data={data.motivation}
              />
              <Repeater
                block="input"
                title="Deliverables"
                caption="In addition to the final data story, what other deliverables are planned?"
                caption2="This may be an algorithm, a publication manuscript, a Grand Challenges presentation, etc."
                updateValue={(name, value) => this.updateState(name, value)}
                name="deliverables"
                data={data.deliverables}
                type="text"
                maxlength="255"
              />
              {/*<RadioSelect*/}
                {/*title="Sufficient data to continue"*/}
                {/*caption="Is data available necessary to answering the questions - if not available, rally cannot begin."*/}
                {/*updateValue={(name, value) => this.updateState(name, value)}*/}
                {/*name="is_data_available"*/}
                {/*data={data.is_data_available}*/}
              {/*/>*/}
              <div className="footer-buttons">
                <div onClick={() => this.changeKey(2)} className="button-text-icon icon-right icon-arrow">
                  Analysis
                </div>
                <div onClick={() => this.setState({deleteModal: true})} className="button-text-icon">
                  Delete Sprint
                </div>
              </div>
            </Tab>

            <Tab eventKey={2} title="Analysis">
              {/*<Repeater*/}
                {/*block="card"*/}
                {/*title="Value"*/}
                {/*caption="What is the value of this work? What does it mean for day-to-day operations?"*/}
                {/*updateValue={(name, value) => this.updateState(name, value)}*/}
                {/*name="final_impact_value"*/}
                {/*data={data.final_impact_value}*/}
                {/*options={["Generated New Hypothesis", "Integrated Datasets", "Facilitated a Strategic Program Decision"]}*/}
                {/*placeholder="Select Final Impact Value"*/}
              {/*/>*/}
              <BasicInput
                title="Analysis Git Repo"
                caption="Provide a link to the git repository where the code for this sprint is housed."
                updateValue={(name, value) => this.updateState(name, value)}
                name="ghap_repo"
                data={data.ghap_repo}
                type="text"
                maxlength="255"
              />
              <SingleFile
                title="Upload Analysis Slides"
                caption="Download the analysis powerpoint template to work from, then upload the updated file"
                data={data.data_description}
                id={data.id}
                name="data_description"
                upload={(e) => this.uploadAnalysisSlide(e)}
                deleteFile={() => this.updateState("data_description", "")}
                loading={this.props.upload.loading}
                error={this.state.error.ppt}
                mime=".ppt,.pptx"
              />
              {/*<Tags*/}
                {/*title="Datasets"*/}
                {/*caption="Select the IDs of the studies used for this rally."*/}
                {/*updateValue={(name, value) => this.updateState(name, value)}*/}
                {/*name="dataset_list"*/}
                {/*data={data.dataset_list}*/}
                {/*suggestions={this.state.studies}*/}
                {/*placeholder="Add new study"*/}
              {/*/>*/}
              <Repeater
                block="textarea"
                title="Key Findings"
                caption="The final result; why we should care about it, and what potential future transformations arise from the result."
                updateValue={(name, value) => this.updateState(name, value)}
                name="key_findings"
                data={data.key_findings}
              />
              <Repeater
                block="textarea"
                title="Next Steps"
                caption="Include the next steps for this rally."
                updateValue={(name, value) => this.updateState(name, value)}
                name="next_steps"
                data={data.next_steps}
              />
              {/*<Tags*/}
                {/*title="Outcome Variables"*/}
                {/*caption="What are the outcome variables in the datasets being used that we are interested in for this sprint?"*/}
                {/*updateValue={(name, value) => this.updateState(name, value)}*/}
                {/*name="outcome_variables"*/}
                {/*data={data.outcome_variables}*/}
                {/*suggestions={data.tags_all}*/}
                {/*placeholder="Add new variable"*/}
              {/*/>*/}
              {/*<Tags*/}
                {/*title="Predictor Variables"*/}
                {/*caption="What are the predictor variables in the datasets being used that we are interested in for this sprint?"*/}
                {/*updateValue={(name, value) => this.updateState(name, value)}*/}
                {/*name="predictor_variables"*/}
                {/*data={data.predictor_variables}*/}
                {/*suggestions={data.tags_all}*/}
                {/*placeholder="Add new variable"*/}
              {/*/>*/}
              {/*<Tags*/}
                {/*title="Method (Keywords)"*/}
                {/*caption="What are the methods used for this sprint?"*/}
                {/*updateValue={(name, value) => this.updateState(name, value)}*/}
                {/*name="methods"*/}
                {/*data={data.methods}*/}
                {/*suggestions={data.tags_all}*/}
                {/*placeholder="Add new method"*/}
              {/*/>*/}
              <div className="footer-buttons flex-end">
                <div onClick={() => this.setState({deleteModal: true})} className="button-text-icon">
                  Delete Sprint
                </div>
              </div>
            </Tab>

          </Tabs>
        </div>
      );

    }
  }
}

export default connect(mapStateToProps, {
  fetchRallyById,
  fetchUsers,
  fetchStudies,
  createSynapse,
  updateSynapse,
  updateRally,
  createRally,
  deleteRally,
  uploadFile,
  redirectToEdit,
  fetchRallyRoles,
})(RallyEdit);
