import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from "react-router-dom";

import Cards from '../Cards.js';
import { Tab, Tabs } from "react-bootstrap";

import { Dropdown } from '../partials/InputBlock';
import LoadingScreen from '../partials/Loading';

import { fetchUsers } from "../../actions/users";

import {
  getActiveUsers,
  getRequestUsers,
  getInactiveUsers,
  getUserLocations,
  getUserOrganizations,
  getUserRoles,
} from '../../selectors/users';

const mapStateToProps = (state) => ({
  user: state.auth.user,
  level: state.auth.level,
  users: {
    loading: state.users.loading,
    loaded: state.users.loaded,
    list: state.users.users,
    active: getActiveUsers(state) ? getActiveUsers(state) : [],
    requested: getRequestUsers(state) ? getRequestUsers(state) : [],
    inactive: getInactiveUsers(state) ? getInactiveUsers(state) : [],
  },
  locations: getUserLocations(state) ? getUserLocations(state) : [],
  organizations: getUserOrganizations(state) ? getUserOrganizations(state) : [],
  roles: getUserRoles(state) ? getUserRoles(state) : [],
});

const mapDispatchToProps = (dispatch) => ({
  getUsers: () => (
    dispatch(fetchUsers())
  ),
});

class People extends Component {
  constructor(props) {
    super(props);
    this.state = {
      users: {
        full: this.props.users.list ? this.props.users.list : [],
        active: this.props.users.active ? this.props.users.active : [],
        requested: this.props.users.requested ? this.props.users.requested : [],
        inactive: this.props.users.inactive ? this.props.users.inactive : [],
      },
      sort: {
        list: ["Most Recent","Oldest","A-Z","Z-A"],
        selected: null
      },
      filter: {
        locations: null,
        organizations: null,
        roles: null
      },
      error: null,
      spacing: "100",
      search: ""
    };
  }

  getActive = (list) => {
    return list.filter((user) => user.active === "Active" && user.added === 1);
  };

  getRequested = (list) => {
    return list.filter((user) => user.active === "Active" && user.added === 0);
  };

  getInactive = (list) => {
    return list.filter((user) => user.active === "Inactive" && user.added === 1);
  };

  handleSort = (value) => {
    let filtered = [];
    let revert = false;
    if (value) {
      this.setState({
        sort: {
          ...this.state.sort,
          selected: value,
        }
      });
      switch (value.toLowerCase()) {
        case "":
          filtered = this.props.users.list;
          revert = true;
          break;
        case "most recent":
          filtered = this.state.users.full.sort(function(a, b){
            if(parseInt(a.id) > parseInt(b.id)) return -1;
            if(parseInt(a.id) < parseInt(b.id)) return 1;
            return 0;
          });
          break;
        case "oldest":
          filtered = this.state.users.full.sort(function(a, b){
            if(parseInt(a.id) < parseInt(b.id)) return -1;
            if(parseInt(a.id) > parseInt(b.id)) return 1;
            return 0;
          });
          break;
        case "a-z":
          filtered = this.state.users.full.sort(function(a, b){
            if(a.last_name < b.last_name) return -1;
            if(a.last_name > b.last_name) return 1;
            return 0;
          });
          break;
        case "z-a":
          filtered = this.state.users.full.sort(function(a, b){
            if(a.last_name > b.last_name) return -1;
            if(a.last_name < b.last_name) return 1;
            return 0;
          });
          break;
        default:
          filtered = this.props.users.list;
          revert = true;
      }
    }

    if (revert) {
      this.setState({
        users: {
          ...this.state.users,
          active: this.getActive(this.state.users.full),
          requested: this.getRequested(this.state.users.full),
          inactive: this.getInactive(this.state.users.full)
        }
      });

    } else {
      this.setState({
        users: {
          ...this.state.users,
          active: this.getActive(filtered),
          requested: this.getRequested(filtered),
          inactive: this.getInactive(filtered)
        }
      });
    }
  };

  handleFilter = (name, value) => {
    let filtered = [];
    if (value) {
      this.setState({
        filter: {
          ...this.state.filter,
          [name]: value,
        }
      });
      let filterText = value.split("','");
      filterText = filterText.join("|");

      filtered = this.state.users.full.filter((user) => {
        let query = new RegExp(filterText.toLowerCase(), 'i');
        let match = false;
        if (user.location) match = match || user.location.toLowerCase().match(query);
        if (user.primary_organizational_affiliation) match = match || user.primary_organizational_affiliation.toLowerCase().match(query);
        if (user.role) match = match || user.role.toLowerCase().match(query);
        return match;
      });
      this.setState({
        users: {
          ...this.state.users,
          active: this.getActive(filtered),
          requested: this.getRequested(filtered),
          inactive: this.getInactive(filtered)
        }
      })
    } else {
      this.setState({
        filter: {
          ...this.state.filter,
          [name]: value,
        },
        users: {
          ...this.state.users,
          active: this.getActive(this.state.users.full),
          requested: this.getRequested(this.state.users.full),
          inactive: this.getInactive(this.state.users.full)
        }
      })
    }
  };

  handleSearch = (e) => {
    let filtered = [];
    if (e) {
      let searchText = e.target.value;
      if (searchText === "") {
        this.setState({
          search: searchText,
          users: {
            ...this.state.users,
            active: this.getActive(this.state.users.full),
            requested: this.getRequested(this.state.users.full),
            inactive: this.getInactive(this.state.users.full)
          }
        })
      } else {
        filtered = this.state.users.full.filter((user) => {
          let query = new RegExp(searchText.toLowerCase(), 'i');
          let match = false;
          if (user.first_name) match = match || user.first_name.toLowerCase().match(query);
          if (user.last_name) match = match || user.last_name.toLowerCase().match(query);
          if (user.primary_organizational_affiliation) match = match || user.primary_organizational_affiliation.toLowerCase().match(query);
          if (user.location) match = match || user.location.toLowerCase().match(query);
          if (user.email) match = match || user.email.toLowerCase().match(query);
          return match;
        });
        this.setState({
          search: searchText,
          users: {
            ...this.state.users,
            active: this.getActive(filtered),
            requested: this.getRequested(filtered),
            inactive: this.getInactive(filtered)
          }
        })
      }
    }
  };

  componentWillUnmount() {
      document.body.classList.remove('people');
  };

  componentDidMount() {
    document.body.classList.toggle('people', true);
    this.props.getUsers();
  }

  componentDidUpdate(prevProps) {
    if (JSON.stringify(this.props.users) !== JSON.stringify(prevProps.users)) {
      if (this.props.users) {
        this.setState({
          users: {
            full: this.props.users.list,
            active: this.props.users.active,
            requested: this.props.users.requested,
            inactive: this.props.users.inactive
          }
        })
      }
    }
  }

  render() {
    console.log(this.state);

    if (this.state.error) return <p className="error">{this.state.error.message}</p>;
    if (this.props.users.loading) return <LoadingScreen interior />;

    return(
      <div className="cards">
        <div className="hero-heading-wrapper rallies-heading-wrapper">
          <div className="hero-heading-col">
            <h1>People</h1>
          </div>
          <div className="hero-heading-col">
          {this.props.level > 0 ?
            <Link to={"/people/edit/add"} className="button-text-icon add-item icon-left">
              <span className="plus-wrap">
                <svg version="1.1" id="Layer_1"  x="0px" y="0px" viewBox="0 0 12 12">
                  <g id="Symbols">
                    <g id="buttons_x2F_addItem" transform="translate(-15.000000, -10.000000)">
                      <g id="Group-25" transform="translate(15.000000, 10.000000)">
                        <g id="Path-4">
                          <rect x="5.1" className="st0" width="2" height="12"/>
                        </g>
                        <g id="Path-5">
                          <rect y="5.1" className="st0" width="12" height="2"/>
                        </g>
                      </g>
                    </g>
                  </g>
                  </svg>
                </span>
              Person
            </Link>
          :
            null
          }
        </div>
        </div>

        <div className="filter-bar">
          <Dropdown
            updateValue={(name, value) => this.handleSort(value)}
            options={this.state.sort.list}
            name="sort"
            type="Sort"
            selected={this.state.sort.selected}
          />
          <Dropdown
            updateValue={(name, value) => this.handleFilter(name, value)}
            options={this.props.locations}
            name="locations"
            type={"Location ("+ this.props.locations.length +")"}
            selected={this.state.filter.locations}
            isMulti
          />
          <Dropdown
            updateValue={(name, value) => this.handleFilter(name, value)}
            options={this.props.organizations}
            name="organizations"
            type={"Organization ("+ this.props.organizations.length +")"}
            selected={this.state.filter.organizations}
            isMulti
          />
          <Dropdown
            updateValue={(name, value) => this.handleFilter(name, value)}
            options={this.props.roles}
            name="roles"
            type={"Role ("+ this.props.roles.length +")"}
            selected={this.state.filter.roles}
            isMulti
          />
          <input
            className="search-input"
            type="text"
            placeholder="Search"
            value={this.state.search}
            onChange={this.handleSearch}
          />
        </div>

        <Tabs defaultActiveKey={1} id={"tabs-1"}>
          <Tab eventKey={1} title={"Active (" + this.state.users.active.length + ")"}>
            {/*<div className="h1 section-heading section-heading-border">{this.state.users.active.length} Active</div>*/}
            <Cards data={this.state.users.active} user={this.props.user} view={"people"}/>
          </Tab>
          {this.props.level > 0 ?
            <Tab eventKey={2} title={"Requests (" + this.state.users.requested.length + ")"}>
              {/*<div className="h1 section-heading section-heading-border">{this.state.users.requested.length} requests</div>*/}
              <Cards data={this.state.users.requested} user={this.props.user} view={"people"}/>
            </Tab>
            :
            null
          }
          {this.props.level > 0 ?
            <Tab eventKey={3} title={"Inactive (" + this.state.users.inactive.length + ")"}>
              {/*<div className="h1 section-heading section-heading-border">{this.state.users.inactive.length} inactive</div>*/}
              <Cards data={this.state.users.inactive} user={this.props.user} view={"people"}/>
            </Tab>
            :
            null
          }
        </Tabs>
      </div>
    );

  }
}

export default connect(mapStateToProps, mapDispatchToProps)(People);
