import { createStore, compose, applyMiddleware } from 'redux';
import { createBrowserHistory } from 'history';
import {routerMiddleware } from 'connected-react-router';
import thunk from 'redux-thunk';
import createRootReducer from '../reducers/all';
import logger from 'redux-logger';

export const history = createBrowserHistory();

//const store = createStore(rootReducer, applyMiddleware(thunk, logger));

//export default store;

export default function configureStore(preloadedState) {
  const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
  return createStore(
    createRootReducer(history),
    preloadedState,
    composeEnhancer(
      applyMiddleware(routerMiddleware(history), thunk, logger)
    ),
  );

}