import React from 'react';

export const Modal = (props) => {
  return (
    <div className="delete-modal">
      <div className="delete-modal-content">
        <label>{props.title}</label>
        <p>{props.caption}</p>
        {props.children}
        {props.tertiaryAction ?
          <div className="buttons space-between">
            <button onClick={() => props.tertiaryAction()} className="button-text-icon">{props.tertiaryText}</button>
            <div>
              <button onClick={() => props.secondaryAction()} className="button-text-icon">{props.secondaryText}</button>
              <button onClick={() => props.primaryAction()} className="button-text-icon primary-btn">{props.primaryText}</button>
            </div>
          </div>
          :
          <div className="buttons">
            {props.secondaryAction
              ? <button onClick={() => props.secondaryAction()}
                        className="button-text-icon">{props.secondaryText}</button>
              : null
            }
            <button onClick={() => props.primaryAction()} className="button-text-icon primary-btn">{props.primaryText}</button>
          </div>
        }
      </div>
    </div>
  )
}