import React, { Component } from 'react';
import Textarea from 'react-textarea-autosize';
import {Link} from "react-router-dom";
import FormTextInput from "./FormTextInput";
import StandardInput from "./Cards";
import Dropdown from "./Dropdown";
import {Tab} from "react-bootstrap";

const pStyle = {
    width: '75%'
};
class RepeatTextBoxes extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: true,
            data: [],
            error: null,
        };
    }

    linkAddBox = () => {
        let data = [{title: "", link: ""}];
        this.setState({
            data: [...this.state.data, data]
        }, () => {});
    };

    linkChange = (e) => {
        let data = this.state.data;
        if(e.target.name === "link"){
            data[e.target.id] = [{link: e.target.value, title: data[e.target.id]['title']}];
        } else {
            data[e.target.id] = [{link: data[e.target.id]['link'], title:  e.target.value}];
        }
        this.setState({ data }, () => {
            e.value = this.state.data;
            this.props.parentChange(e);
        });
    };

    addBox = () => {
        let data = [""];
        this.setState({
            data: [...this.state.data, data]
        }, () => {});
    };

    deleteBox = (e) => {
        console.log(e);
        let data = [...this.state.data];
        data.splice(e.target.id, 1);
        this.setState({ data }, () => {});
    };

    onChange = (e, name) => {
        let data = this.state.data;
        data[e.target.id] = e.target.value;
        this.setState({ data }, () => {
           e.name = name;
           e.value = this.state.data;
           this.props.parentChange(e);
        });
    };

    onImpactChange = (e) => {
        if(e.target.name === "value") {
            let participants = this.state.participants;
            participants[e.target.id] = e.target.value;
            let name = e.target.name;
            this.setState({ participants }, () => {
                e.name = name;
                e.value = this.state.participants;
                this.props.parentChange(e);
            });
        }
        if(e.target.name === "text") {
            let roles = this.state.roles;
            roles[e.target.id] = e.target.value;
            let name = e.target.name;
            this.setState({ roles }, () => {
                e.name = name;
                e.value = this.state.roles;
                this.props.parentChange(e);
            });
        }
    };

    componentDidMount() {
        window.addEventListener("beforeunload", this.onUnload);
        const { title, caption, placeholder, type, name, maxlength } = this.props;
        let ret = this.props.data;
        let data = [];
        if(ret !== undefined){
            if(ret.constructor === Array ){
                data[0] = ret;
                data = data[0];
            }else {
                data = ret.toString().split("','");
            }
        }
        this.setState({ title, caption, placeholder, type, name, maxlength, data });
        this.setState({ isLoading: false });
    }

    render() {
        const {data, error, isLoading, caption, title, name, placeholder, type, maxlength} = this.state;

        if (error) {
            return <p className="error">{error.message}</p>;
        }
        if (isLoading) {
            return <p>Loading ...</p>;
        }
        if( this.props.view === "final_impact_value") {
          return (
            <div className="form-question">
              <div className="heading-3">{title}</div>
              <div className="caption-text">{caption}</div>
              <div className="container data">
                  <ul>
                      {data.map((value, index) => (
                          <li className="sprint_question repeater-field" key={index}>
                              <Dropdown parentChange={this.onImpactChange}
                              name={"value"}
                              caption={""}
                              id={index}
                              title={"Final impact value"}
                              data={this.props.values}
                              selected={data.value}/>
                              <input type={"text"}
                                     onBlur={this.onImpactChange}
                                     id={index}
                                     name={"text"}
                                     className="study-methods-reference"
                                     placeholder={placeholder}
                                     defaultValue={data.text}
                                     maxLength={maxlength}
                                     style={pStyle}/>
                              <div onClick={this.deleteBox} id={index} name={name} className="remove-margin close-icon">
                                  <svg version="1.1" x="0px" y="0px"
                                       viewBox="0 0 34 34">
                                      <g id="Layer_2">
                                          <circle className="st0" cx="17" cy="17.1" r="16.4"/>
                                      </g>
                                      <g id="Layer_1">
                                          <g id="Symbols">
                                              <g id="Content-Block_x2F_Actions"
                                                 transform="translate(-179.000000, 0.000000)">
                                                  <g id="Icons_x2F_Remove"
                                                     transform="translate(179.000000, 0.000000)">
                                                      <g id="_x2B_-Button">
                                                          <g id="Oval-2">
                                                              <path className="st1" d="M17,34C7.6,34,0,26.4,0,17S7.6,0,17,0s17,7.6,17,17S26.4,34,17,34z M17,1C8.2,1,1,8.2,1,17s7.2,16,16,16
                                            s16-7.2,16-16S25.8,1,17,1z"/>
                                                          </g>

                                                          <g id="Group-2"
                                                             transform="translate(17.500000, 17.500000) rotate(-45.000000) translate(-17.500000, -17.500000) translate(12.000000, 12.000000)">
                                                              <g id="Line-2">

                                                                  <rect x="-0.1" y="4.9"
                                                                        transform="matrix(0.7071 -0.7071 0.7071 0.7071 -2.1919 5.5012)"
                                                                        className="st1" width="11.3"
                                                                        height="1"/>
                                                              </g>
                                                              <g id="Line-2_1_">

                                                                  <rect x="5.4" y="-0.3"
                                                                        transform="matrix(0.7071 -0.7071 0.7071 0.7071 -2.0888 5.7499)"
                                                                        className="st1" width="1"
                                                                        height="11.4"/>
                                                              </g>
                                                          </g>
                                                      </g>
                                                  </g>
                                              </g>
                                          </g>
                                      </g>
                                  </svg>
                              </div>
                          </li>
                      ))}
                  </ul>
              </div>
              <div className="button-text-icon add-item icon-left icon-plus" onClick={this.addBox}>Add</div>
          </div>
          );
        }
        if (this.props.view === "mdrFormInput") {
            if (this.props.type === "linked_file") {
                return (
                    <ul className="sq-list1">
                        {data.map((value, index) => (
                            <li className="sprint_question" key={index}>
                                <div className="card-wrapper">
                                    <Link to={"#"} className="card profile-card">
                                        <label>Linked file</label>
                                        <FormTextInput defaultValue={data.title}
                                                       type={"text"}
                                                       id={index}
                                                       name={"title"}
                                                       parentChange={this.linkChange}
                                                       title={"Title"}/>
                                        <FormTextInput defaultValue={data.link}
                                                       type={"url"}
                                                       title={"Link"}
                                                       id={index}
                                                       name={"link"}
                                                       parentChange={this.linkChange}/>
                                    </Link>
                                </div>
                                <div onClick={this.deleteBox} id={index} name={name} className="remove-margin close-icon">
                                    <svg version="1.1" x="0px" y="0px"
                                         viewBox="0 0 34 34">
                                        <g id="Layer_2">
                                            <circle className="st0" cx="17" cy="17.1" r="16.4"/>
                                        </g>
                                        <g id="Layer_1">
                                            <g id="Symbols">
                                                <g id="Content-Block_x2F_Actions"
                                                   transform="translate(-179.000000, 0.000000)">
                                                    <g id="Icons_x2F_Remove"
                                                       transform="translate(179.000000, 0.000000)">
                                                        <g id="_x2B_-Button">
                                                            <g id="Oval-2">
                                                                <path className="st1" d="M17,34C7.6,34,0,26.4,0,17S7.6,0,17,0s17,7.6,17,17S26.4,34,17,34z M17,1C8.2,1,1,8.2,1,17s7.2,16,16,16
                                    							s16-7.2,16-16S25.8,1,17,1z"/>
                                                            </g>

                                                            <g id="Group-2"
                                                               transform="translate(17.500000, 17.500000) rotate(-45.000000) translate(-17.500000, -17.500000) translate(12.000000, 12.000000)">
                                                                <g id="Line-2">

                                                                    <rect x="-0.1" y="4.9"
                                                                          transform="matrix(0.7071 -0.7071 0.7071 0.7071 -2.1919 5.5012)"
                                                                          className="st1" width="11.3"
                                                                          height="1"/>
                                                                </g>
                                                                <g id="Line-2_1_">

                                                                    <rect x="5.4" y="-0.3"
                                                                          transform="matrix(0.7071 -0.7071 0.7071 0.7071 -2.0888 5.7499)"
                                                                          className="st1" width="1"
                                                                          height="11.4"/>
                                                                </g>
                                                            </g>
                                                        </g>
                                                    </g>
                                                </g>
                                            </g>
                                        </g>
                                    </svg>
                                </div>
                            </li>
                        ))}
                        <div className="button-text-icon add-item icon-left icon-plus" onClick={this.linkAddBox}>Linked File</div>
                    </ul>
                );
            }
        }
        if (this.props.type === "textarea") {
            return (
                <div className="view-element">
                    <div className="heading-3">{title}</div>
                    <div className="caption-text">{caption}</div>
                    <ul className="sq-list2 ">
                        {data.map((value, index) => (
                            <li className="sprint_question" key={index}>
                                <textarea onBlur={(e) => this.onChange(e, name)}
                                          name={name}
                                          className={name}
                                          placeholder={placeholder}
                                          defaultValue={value}
                                          id={index}
                                          style={pStyle}/>
                                <div onClick={this.deleteBox} id={index}  name={name} className="remove-margin close-icon close-icon-adjust">
                                    <svg version="1.1" x="0px" y="0px"
                                         viewBox="0 0 34 34">
                                        <g id="Layer_2">
                                            <circle className="st0" cx="17" cy="17.1" r="16.4"/>
                                        </g>
                                        <g id="Layer_1">
                                            <g id="Symbols">
                                                <g id="Content-Block_x2F_Actions"
                                                   transform="translate(-179.000000, 0.000000)">
                                                    <g id="Icons_x2F_Remove"
                                                       transform="translate(179.000000, 0.000000)">
                                                        <g id="_x2B_-Button">
                                                            <g id="Oval-2">
                                                                <path className="st1" d="M17,34C7.6,34,0,26.4,0,17S7.6,0,17,0s17,7.6,17,17S26.4,34,17,34z M17,1C8.2,1,1,8.2,1,17s7.2,16,16,16
                                    							s16-7.2,16-16S25.8,1,17,1z"/>
                                                            </g>

                                                            <g id="Group-2"
                                                               transform="translate(17.500000, 17.500000) rotate(-45.000000) translate(-17.500000, -17.500000) translate(12.000000, 12.000000)">
                                                                <g id="Line-2">
                                                                    <rect x="-0.1" y="4.9"
                                                                          transform="matrix(0.7071 -0.7071 0.7071 0.7071 -2.1919 5.5012)"
                                                                          className="st1" width="11.3"
                                                                          height="1"/>
                                                                </g>
                                                                <g id="Line-2_1_">
                                                                    <rect x="5.4" y="-0.3"
                                                                          transform="matrix(0.7071 -0.7071 0.7071 0.7071 -2.0888 5.7499)"
                                                                          className="st1" width="1"
                                                                          height="11.4"/>
                                                                </g>
                                                            </g>
                                                        </g>
                                                    </g>
                                                </g>
                                            </g>
                                        </g>
                                    </svg>
                                </div>
                            </li>
                        ))}
                    </ul>
                    <div className="button-text-icon add-item icon-left icon-plus" onClick={this.addBox}>Add</div>
                </div>
            );
        } else {
            return (
                <div className="form-question">
                    <div className="heading-3">{title}</div>
                    <div className="caption-text">{caption}</div>
                    <div className="container data">
                        <ul>
                            {data.map((value, index) => (
                                <li className="sprint_question repeater-field" key={index}>
                                    <input type={type}
                                           onBlur={this.onChange}
                                           id={index}
                                           name={name}
                                           className="study-methods-reference"
                                           placeholder={placeholder}
                                           defaultValue={value}
                                           maxLength={maxlength}
                                           style={pStyle}/>
                                    <div onClick={this.deleteBox} id={index} name={name} className="remove-margin close-icon">
                                        <svg version="1.1" x="0px" y="0px"
                                             viewBox="0 0 34 34">
                                            <g id="Layer_2">
                                                <circle className="st0" cx="17" cy="17.1" r="16.4"/>
                                            </g>
                                            <g id="Layer_1">
                                                <g id="Symbols">
                                                    <g id="Content-Block_x2F_Actions"
                                                       transform="translate(-179.000000, 0.000000)">
                                                        <g id="Icons_x2F_Remove"
                                                           transform="translate(179.000000, 0.000000)">
                                                            <g id="_x2B_-Button">
                                                                <g id="Oval-2">
                                                                    <path className="st1" d="M17,34C7.6,34,0,26.4,0,17S7.6,0,17,0s17,7.6,17,17S26.4,34,17,34z M17,1C8.2,1,1,8.2,1,17s7.2,16,16,16
                                    							s16-7.2,16-16S25.8,1,17,1z"/>
                                                                </g>

                                                                <g id="Group-2"
                                                                   transform="translate(17.500000, 17.500000) rotate(-45.000000) translate(-17.500000, -17.500000) translate(12.000000, 12.000000)">
                                                                    <g id="Line-2">

                                                                        <rect x="-0.1" y="4.9"
                                                                              transform="matrix(0.7071 -0.7071 0.7071 0.7071 -2.1919 5.5012)"
                                                                              className="st1" width="11.3"
                                                                              height="1"/>
                                                                    </g>
                                                                    <g id="Line-2_1_">

                                                                        <rect x="5.4" y="-0.3"
                                                                              transform="matrix(0.7071 -0.7071 0.7071 0.7071 -2.0888 5.7499)"
                                                                              className="st1" width="1"
                                                                              height="11.4"/>
                                                                    </g>
                                                                </g>
                                                            </g>
                                                        </g>
                                                    </g>
                                                </g>
                                            </g>
                                        </svg>
                                    </div>
                                </li>
                            ))}
                        </ul>
                    </div>
                    <div className="button-text-icon add-item icon-left icon-plus" onClick={this.addBox}>Add</div>
                </div>
            );
        }
    }
}

export default RepeatTextBoxes;