import React, {Component} from 'react';
import ShortId from 'shortid';

import { ReactComponent as RightArrow } from '../../assets/images/arrow_right.svg';

/*
 * Basic view element
 */
export class BasicView extends Component {
  render() {
    return (
      <div className={`basic-view-element ${this.props.class}`}>
        <label>{this.props.title}</label>
        {this.props.type === 'header' ? <h3>{this.props.content ? this.props.content : "-"}</h3> : null}
        {this.props.type === 'p' ? <p>{this.props.content ? this.props.content : "-"}</p> : null}
      </div>
    );
  }
}


/*
 * Status Chart element
 */
export class StatusChart extends Component {
  render() {
    return (
      <div className={`basic-view-element ${this.props.class}`}>
        <label>{this.props.title}</label>
        <div className="status-chart">
          <hr />
          {this.props.levels.map((level) => {
            return (
              <div className={`status ${level === this.props.current ? 'active' : ''}`} key={ShortId.generate()}>
                <div className="marker"><div className="dot" /></div>
                <p>{level}</p>
              </div>
            )
          })}
        </div>
      </div>
    );
  }
}


/*
 * Single Link element
 */
export class SingleLink extends Component {
  render() {
    return (
      <div className={`basic-view-element ${this.props.class}`}>
        <label>{this.props.title}</label>
        {this.props.url ?
          <div className="link-button-wrap">
            <a href={this.props.url} target="_blank" className="link-button btn" key={ShortId.generate()}>
              {this.props.text}
              <RightArrow/>
            </a>
          </div>
          :
          <p>-</p>
        }
      </div>
    );
  }
}


/*
 * Link List element
 */
export class LinkList extends Component {
  render() {
    console.log(this.props.list);
    return (
      <div className={`basic-view-element ${this.props.class}`}>
        <label>{this.props.title}</label>
        {this.props.list && this.props.list.length > 0 ?
          <div className="link-button-wrap">
            {this.props.list.map((item) => {
              return (
                <a href="#" target="_blank" className="link-button btn" key={ShortId.generate()}>
                  {item}
                  <RightArrow/>
                </a>
              )
            })}
          </div>
          :
          <p>-</p>
        }
      </div>
    );
  }
}


/*
 * Text List element
 */
export class TextList extends Component {
  render() {
    let hasItems;
    if (this.props.list) {
      if (this.props.list === "" || this.props.list.length === 0 || this.props.list[0] === "") {
        hasItems = false;
      } else {
        hasItems = true;
      }
    } else {
      hasItems = false;
    }

    return (
      <div className={`basic-view-element ${this.props.class}`}>
        <label>{this.props.title}</label>
        {hasItems ?
          <ul className={`text-list ${this.props.isBulleted ? 'bulleted' : 'normal'}`}>
            {this.props.list.map((item) => {
              return (
                <li key={ShortId.generate()}>
                  {this.props.isBulleted ? <p>{item}</p> : item}
                </li>
              )
            })}
          </ul>
          :
          <p>-</p>
        }
      </div>
    );

  }
}


/*
 * Final Impact element
 */
export class FinalImpact extends Component {
  render() {
    return (
      <div className={`basic-view-element ${this.props.class}`}>
        <label>{this.props.title}</label>
        {this.props.list ?
          <div className="two-col">
            {this.props.list.map((item) => {
              return (
                <div className="text-col" key={ShortId.generate()}>
                  <p className="fi-label"><strong>{item.dd}</strong></p>
                  <p>{item.ta}</p>
                </div>
              )
            })}
          </div>
          :
          <p>-</p>
        }
      </div>
    );
  }
}