import React, {Component} from 'react';
import * as d3 from 'd3';
import LoadingScreen from './Loading';

export class Gantt extends Component {
  constructor() {
    super();
    this.state = {
      loaded: false,
    }
  }

  formatData = (data) => {
    let tasks = [];
    let startDate = null;
    let endDate = null;

    let setColor = (start) => {
      let now = new Date();
      return now >= start ? 'blue' : 'yellow';
    };

    let setDates = (start, end) => {
      if (!startDate && !endDate) {
        startDate = start;
        endDate = end;
      } else {
        if (startDate.getTime() > start.getTime()) startDate = start;
        if (endDate.getTime() < end.getTime()) endDate = end;
      }
    };

    data.forEach((value, index) => {
      setDates(new Date(value.timeline[0]), new Date(value.timeline[1]));
      tasks.push({
        id: value.id,
        number: value.rally_number,
        letter: value.sprint_letter,
        name: value.sprint_title,
        start: new Date(value.timeline[0]),
        end: new Date(value.timeline[1]),
        color: setColor(new Date(value.timeline[0])),
      });
    });

    // Add some day padding for the axis range
    startDate.setDate(startDate.getDate() - 15);
    endDate.setDate(endDate.getDate() + 15);

    // Sort array by date
    tasks.sort((a, b) => a.start.getTime() - b.start.getTime());

    return {tasks, startDate, endDate};
  };

  build = (props) => {
    const { payload, onClick } = props;

    if (payload.length === 0) return null;

    // Build container
    const container = d3.select('.gantt');
    const data = this.formatData(payload);
    const width = this.refs.ganttChart.clientWidth;
    // const height = this.refs.ganttChart.clientHeight;
    const height = (data.tasks.length * 35 ) + 60;
    const scaleFactor = (1/(data.endDate.getTime() - data.startDate.getTime())) * (width);

    // Build axis
    const x = d3.scaleTime([data.startDate, data.endDate], [0, width]);
    const xAxis = d3.axisBottom(x)
      .ticks(d3.timeMonth)
      .tickSize(height)
      .tickFormat(d3.timeFormat('%b'));

    let customXAxis = (g) => {
      g.call(xAxis);
      g.attr('class', 'x-axis');
      g.attr('font-family', '"Roboto Condensed",sans-serif');
      g.attr('text-anchor', 'right');
      g.attr('font-size', 14);
      g.select('.domain').remove();
      g.selectAll('.tick line').attr('stroke', '#bababa').attr('stroke-dasharray', '2,7');
      g.selectAll('.tick text').attr('y', 25).attr('x', 10).attr('dy', -4).attr('fill', '#999');
    };

    // Build current line/shaded area
    let shadedArea = (a) => {
      a.attr('height', height);
      a.attr('width', () => {
        let now = new Date(Date.now());
        return (now.getTime() - data.startDate.getTime()) * scaleFactor
      });
      a.attr('fill', 'url(#diagonalHatch)');
      a.attr('stroke', '#eaeaea');
    };

    // Build rectangles
    let formatRect = (r) => {
      r.attr('height', 25);
      r.attr('width', (d, i) => {
        return (d.end.getTime() - d.start.getTime()) * scaleFactor
      });
      r.attr('class', (d) => {return ( "rally-gantt " + d.color)});
      r.attr('rx', 2);
      r.attr('ry', 2);
      r.attr('y', (d, i) => {
        return i * 32 + 45
      });
      r.attr('x', (d, i) => {
        return (d.start.getTime() - data.startDate.getTime()) * scaleFactor
      });
    };

    // Build labels
    let truncateText = (g, i, j) => {
      let width = j[i].firstElementChild.getAttribute('width');
      let textWidth = j[i].lastElementChild.getComputedTextLength();
      let text = j[i].lastElementChild;
      let truncated = false;

      while (textWidth > width - 20 && text.textContent.length > 0) {
        text.textContent = text.textContent.slice(0, -1);
        textWidth = text.getComputedTextLength();
        truncated = true;
      }
      if (truncated) text.textContent = text.textContent + '...';
    };

    let formatLabels = (t) => {
      t.attr('class', 'rally-gantt gantt-text');
      t.text((d) => {
        return (d.number + d.letter.toUpperCase() + ": " + d.name);
      });
      t.attr('y', (d, i) => {
        return (i * 32 + 45) + 17
      });
      t.attr('x', (d, i) => {
        return ((d.start.getTime() - data.startDate.getTime()) * scaleFactor) + 5
      });
      t.attr('font-family', '"Roboto Condensed",sans-serif');
      t.attr('text-anchor', 'right');
      t.attr('font-weight', 'bold');
      t.attr('font-size', 14);
      t.attr('fill', '#ffffff');
    };


    // Let's do this. LEEROY JENKINS!
    const svg = container
      .append('svg')
      .attr('viewBox', `0 0 ${width} ${height}`)
      .attr('height', height);

    svg.append('defs')
      .append('pattern')
      .attr('id', 'diagonalHatch')
      .attr('patternUnits', 'userSpaceOnUse')
      .attr('width', 4)
      .attr('height', 4)
      .append('path')
      .attr('d', 'M-1,1 l2,-2 M0,4 l4,-4 M3,5 l2,-2')
      .attr('stroke', '#eaeaea')
      .attr('stroke-width', 1);

    svg.append('g')
      .attr('id', 'shaded-area')
      .append('rect')
      .call(shadedArea);

    svg.append('g')
      .call(customXAxis);

    svg.append('g')
      .attr('id', 'gantt-data')
      .attr('font-family', '"Roboto Condensed",sans-serif')
      .attr('font-size', 14);

    const ganttData = d3.select('#gantt-data')
      .selectAll('g')
      .data(data.tasks)
      .enter()
      .append('g')
      .attr('class', 'gantt-bar')
      .on('click', (d) => { onClick(d.id) });

    ganttData.append('rect').call(formatRect);
    ganttData.append('text').call(formatLabels);

    d3.selectAll('.gantt-bar').each(truncateText);

    this.setState({loaded: true})

  };

  componentDidMount() {
    this.build(this.props);
  }

  componentDidUpdate(prevProps) {
    if (JSON.stringify(this.props.payload) !== JSON.stringify(prevProps.payload)) {
      this.build(this.props);
    }
  }

  componentWillUnmount() {
    this.setState({loaded: false});
    d3.select('.gantt').remove();
  }

  render() {
    return (
      <div className="gantt" ref="ganttChart">
        {!this.state.loaded && <LoadingScreen interior />}
      </div>
    )
  }
}
