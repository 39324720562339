import React, { Component } from 'react';
import {Link} from "react-router-dom";
import axios from "axios";

const eStyle = {
    margin: "0px 5px 0px 0px"
};
const pStyle = {
    height: '15px'
};

class ListView extends Component {
    constructor(props) {
        super(props);

        this.state = {
            spacing: "70"
        };
    }

    render() {
        const data = this.props.data;
        if (this.props.view === 'rally') {
            return (
                <div className="hub-table">
                    <div className="hub-table-row hub-table-headers">
                        <div className="hub-table-cell hub-table-program">NUM.</div>
                        <div className="hub-table-cell hub-table-study">Title</div>
                        <div className="hub-table-cell hub-table-status">Status</div>
                        <div className="hub-table-cell hub-table-priority"/>
                        <div className="hub-table-cell hub-table-years">Timeline</div>
                        <div className="hub-table-cell hub-table-intervention"/>
                        <div className="hub-table-cell hub-table-controls"/>
                    </div>
                    {data.map(data =>
                        <div className="hub-table-row" key={data.id}>
                            <div className="hub-table-cell hub-table-program"><Link to={"/rally/"+data.id}>{data.rally_number}{data.sprint_letter.toUpperCase()}</Link></div>
                            <div className="hub-table-cell hub-table-study"><Link title={data.sprint_title} to={"/rally/"+data.id}>{data.sprint_title}</Link></div>
                            <div className="hub-table-cell hub-table-status">{data.status}</div>
                            <div className="hub-table-cell hub-table-priority"/>
                            <div className="hub-table-cell hub-table-years">{data.timeline[0]}-{data.timeline[1]}</div>
                            <div className="hub-table-cell hub-table-intervention"/>
                            <div className="hub-table-cell hub-table-controls">
                                {data.ppt_slides ?
                                    <a href={data.ppt_slides} className="button-text-icon icon-right rally-header-btn" style={eStyle} download>PPT
                                        <span className="right-align-icon">
                                                <svg width="12px" height="12px" viewBox="0 0 12 12" version="1.1">
                                                <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                                    <g id="AccessDataBtn" transform="translate(-57.000000, -10.000000)" fill="#3A4652" fillRule="nonzero">
                                                        <polygon id="Shape" points="63 10 61.9425 11.0575 66.1275 15.25 57 15.25 57 16.75 66.1275 16.75 61.9425 20.9425 63 22 69 16"/>
                                                    </g>
                                                </g>
                                              </svg></span>
                                    </a>
                                    :<div className="button-text-icon icon-right icon-arrow no-data">PPT</div>
                                }
                                {((data.sprint_osf) !== null) ?
                                    <a href={data.sprint_osf} target={"_blank"} className="data-link button-text-icon icon-right">Synapse
                                        <span className="right-align-icon">
                                        <svg width="12px" height="12px" viewBox="0 0 12 12" version="1.1">
                                        <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                            <g id="AccessDataBtn" transform="translate(-57.000000, -10.000000)" fill="#3A4652" fillRule="nonzero">
                                                <polygon id="Shape" points="63 10 61.9425 11.0575 66.1275 15.25 57 15.25 57 16.75 66.1275 16.75 61.9425 20.9425 63 22 69 16"/>
                                            </g>
                                        </g>
                                      </svg></span>
                                    </a>
                                    :<div className="button-text-icon icon-right icon-arrow no-data">Synapse</div>
                                }
                                <Link to={"/rally/edit/" + data.id} className="button-text-icon edit-button edit-icon-only">
                                    <div className="icon-wrap">
                                        <svg viewBox="0 0 16 16" version="1.1">
                                            <g id="Symbols" stroke="none" strokeWidth="1" fill="none"
                                               fillRule="evenodd">
                                                <g id="Buttons/Edit/Large" transform="translate(-14.000000, -7.000000)"
                                                   fillRule="nonzero">
                                                    <g id="Group-3">
                                                        <g id="baseline-edit-24px"
                                                           transform="translate(12.391304, 5.000000)">
                                                            <polygon id="Path"
                                                                     points="0 0 19.1304348 0 19.1304348 20 0 20"/>
                                                            <path
                                                                d="M2.39130435,14.375 L2.39130435,17.5 L5.38043478,17.5 L14.1963768,8.28333333 L11.2072464,5.15833333 L2.39130435,14.375 Z M16.507971,5.86666667 C16.8188406,5.54166667 16.8188406,5.01666667 16.507971,4.69166667 L14.6427536,2.74166667 C14.3318841,2.41666667 13.8297101,2.41666667 13.5188406,2.74166667 L12.0601449,4.26666667 L15.0492754,7.39166667 L16.507971,5.86666667 Z"
                                                                id="Shape" fill="#3A4652"/>
                                                        </g>
                                                    </g>
                                                </g>
                                            </g>
                                        </svg>
                                    </div>
                                </Link>
                            </div>
                        </div>
                    )}
                </div>
                );
        }
        if (this.props.view === 'study') {
            return (
                <div className="hub-table">
                    <div className="hub-table-row hub-table-headers">
                        <div className="hub-table-cell hub-table-program">Program</div>
                        <div className="hub-table-cell hub-table-study">Study</div>
                        <div className="hub-table-cell hub-table-status">Data Status</div>
                        <div className="hub-table-cell hub-table-priority">Priority</div>
                        <div className="hub-table-cell hub-table-years">Years</div>
                        <div className="hub-table-cell hub-table-intervention">Intervention</div>
                        <div className="hub-table-cell hub-table-controls">
                        </div>
                    </div>
                    {data.map(data =>
                        <div className="hub-table-row" key={data.id}>
                            <div className="hub-table-cell hub-table-program"><Link to={"/study/"+data.id}>{data.program}</Link></div>
                            <div className="hub-table-cell hub-table-study"><Link to={"/study/"+data.id}>{data.study_id}</Link></div>
                            <div className="hub-table-cell hub-table-status"><Link to={"/study/"+data.id}>{data.data_status}</Link></div>
                            <div className="hub-table-cell hub-table-priority">{data.priority}</div>
                            <div className="hub-table-cell hub-table-years">{data.stop_year}</div>
                            <div className="hub-table-cell hub-table-intervention">{data.interventional_type}</div>
                            <div className="hub-table-cell hub-table-controls">
                                {data.data_location != null ?
                                    <a href={data.data_location} className="button-text-icon icon-right data-link">Data
                                        <span className="right-align-icon">
                                      <svg width="12px" height="12px" viewBox="0 0 12 12" version="1.1">
                                        <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                            <g id="linkBoxSmall" transform="translate(-171.000000, -10.000000)"
                                               fill="#FFFFFF" fillRule="nonzero">
                                                <g id="Group-8">
                                                    <polygon id="Shape-Copy-2"
                                                             points="177 10 175.9425 11.0575 180.1275 15.25 171 15.25 171 16.75 180.1275 16.75 175.9425 20.9425 177 22 183 16"/>
                                                </g>
                                            </g>
                                        </g>
                                    </svg>
                                    </span>
                                    </a>
                                    :
                                    <div className="button-text-icon icon-right icon-arrow no-data">No Data</div>
                                }
                                <Link to={"/study/edit/" + data.id} className="button-text-icon edit-button edit-icon-only">
                                    <div className="icon-wrap">
                                        <svg viewBox="0 0 16 16" version="1.1">
                                            <g id="Symbols" stroke="none" strokeWidth="1" fill="none"
                                               fillRule="evenodd">
                                                <g id="Buttons/Edit/Large" transform="translate(-14.000000, -7.000000)"
                                                   fillRule="nonzero">
                                                    <g id="Group-3">
                                                        <g id="baseline-edit-24px"
                                                           transform="translate(12.391304, 5.000000)">
                                                            <polygon id="Path"
                                                                     points="0 0 19.1304348 0 19.1304348 20 0 20"/>
                                                            <path
                                                                d="M2.39130435,14.375 L2.39130435,17.5 L5.38043478,17.5 L14.1963768,8.28333333 L11.2072464,5.15833333 L2.39130435,14.375 Z M16.507971,5.86666667 C16.8188406,5.54166667 16.8188406,5.01666667 16.507971,4.69166667 L14.6427536,2.74166667 C14.3318841,2.41666667 13.8297101,2.41666667 13.5188406,2.74166667 L12.0601449,4.26666667 L15.0492754,7.39166667 L16.507971,5.86666667 Z"
                                                                id="Shape" fill="#3A4652"/>
                                                        </g>
                                                    </g>
                                                </g>
                                            </g>
                                        </svg>
                                    </div>
                                </Link>
                            </div>
                        </div>
                    )}
                </div>
            );

        }
    }
}

export default ListView;
