import React, { Component } from 'react';
import FollowLink from "../FollowLink";
import { ReactComponent as Close } from '../../assets/images/close.svg'
import { ReactComponent as Error } from '../../assets/images/error.svg'

import { API } from '../../util/api';

export class SingleFile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      file: null,
      action: null,
    }
  }


  render() {
    return (
      <div className='view-element input-form'>
        <div className="file-upload-header">
          <div>
            <label>{this.props.title}</label>
            <div className='caption-text'>
              <span>{this.props.caption}</span>
              {this.props.caption2 ? <span>{this.props.caption2}</span> : null}
            </div>
          </div>
          <FollowLink data={"Analysis Slide Template"} url={"/uploads/template_ki.pptx"} />
        </div>
        <div className="file-upload-content">
          <form className="single-upload">
            <label htmlFor="file-upload" className="upload-btn button-text-icon">
              Attach file
            </label>
            <input
              type="file"
              id="file-upload"
              accept={this.props.mime}
              onChange={(e) => this.props.upload(e.target.files[0])}
            />
          </form>
          {this.props.loading ?
            <img src="/images/uploading.gif" className="loading-icon" />
          :
            <div>
              {this.props.data ?
                <div className="flex">
                  <FollowLink data={"Download Current Version"} url={API + "/public/uploads/analysis/"+ this.props.id +"/"+ this.props.name +"/"+ this.props.data} />
                  <div onClick={() => this.props.deleteFile()} className="close-icn">
                    <Close />
                  </div>
                </div>
                :
                null
              }
            </div>
          }
        </div>
        {this.props.error ?
          <p className="error">
            <Error/>
            {this.props.error}
          </p>
          :
          null
        }
      </div>
    )
  }
}


export class ProfilePic extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      file: null,
      action: null,
    }
  }


  render() {
    return (
      <div className='view-element input-form'>
        <div className="profile-image">
          <img
            className="profile_pic"
            src={API + "/public/uploads/user/" + this.props.data.id + "/profile_pic/" + this.props.data.profile_pic}
            onError={(e)=>{ e.target.src = API +"/public/uploads/accountphoto.png" }}
          />
        </div>
        <div className="form-wrap">
          <label>{this.props.title}</label>
          <span>{this.props.caption}</span>
          <div id="approvedFiles"/>
          <form className="single-upload">
            <label htmlFor="file-upload" className="upload-btn button-text-icon">
              Upload Image
            </label>
            <input
              type="file"
              id="file-upload"
              accept={this.props.mime}
              onChange={(e) => this.props.upload(e.target.files[0])}
            />
          </form>
        </div>
      </div>
    )
  }
}