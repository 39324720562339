import React, {Component} from 'react';
import Dropdown from './Dropdown';
import Textarea from 'react-textarea-autosize';

class VisitsEpochsArms extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: true,
            data: [],
            error: false,
        };
    }

    componentDidMount() {
        let data = this.props.data;
        if(data !== undefined && data.length < 1){
            switch (this.props.type){
                case "epochs":
                    this.setState({ epochs: { arm_code: "", arm_description: "", arm_description_short: "", number_per_arm: ""}} );
                    break;
                case "arms":
                    this.setState({ arms: {epoch_label: "", epoch_name: ""} });
                    break;
                case "visits":
                    this.setState({ visits: {visit_number: "", visit_day: "", visit_description: "", visit_epoch: ""} });
                    break;
            }
        }
        this.setState({ data: data });
        this.setState({ title: this.props.title });
        this.setState({ caption: this.props.caption });
        this.setState({ name: this.props.name });
        this.setState({ epochs: this.props.epochs });
        this.setState({ isLoading: false });
    }

    componentDidCatch(error, info) {
        this.setState({ error: error, info: info });
    }

    addBox = () => {
        let data = [""];
        this.setState({
            data: [...this.state.data, data]
        });
    };

    deleteBox = (idx) => () => {
        let data = [...this.state.data];
        data.splice(idx, 1);
        this.setState({data});
    };

    onChange = (e) => {
        let data = this.state.data;
        switch (this.props.type){
            case "epochs":
                data['epochs'][e.target.id][e.target.name] =  e.target.value;
                this.setState({ data });
                break;
            case "arms":
                data['arms'][e.target.id][e.target.name] =  e.target.value;
                this.setState({ data });
                break;
            case "visits":
                data['visits'][e.target.id][e.target.name] =  e.target.value;
                this.setState({ data });
                break;
        }
        e.target.value = this.state.data;
        e.name = e.target.name;
        this.props.parentChange(e);
    };

    render() {
        const {epochs, data, error, isLoading, title, caption } = this.state;

        if (error) {
            return <p className="error">{error.message}</p>;
        }
        if (isLoading) {
            return <p>Loading ...</p>;
        }

        if (this.props.type === "epochs"){
            return (
                <div className="form-question">
                    <label>{title}</label>
                    <div className='caption-text'>{caption}</div>
                    <ul>
                        {data.map((value, index) => (
                            <li className="form-repeat epoch-card" key={index}>
                                <div className="heading-4 epoch-card-title">epoch</div>
                                <div className="epoch-card-content">
                                    <div className="epoch-half">
                                        <div className="heading-3">Epoch number</div>
                                        <div className="caption-text">Specify a number for the epoch</div>
                                        <input type="number"
                                               className="epoch_number"
                                               name={"epoch_number"}
                                               defaultValue={value.epoch_number}
                                               id={index}
                                               onBlur={this.onChange}/>
                                    </div>
                                    <div className="epoch-half">
                                        <div className="heading-3">Epoch label</div>
                                        <div className="caption-text">Specify a label for the epoch</div>
                                        <input type="text"
                                               name={"epoch_label"}
                                               className="epoch_label"
                                               id={index}
                                               maxLength="200"
                                               defaultValue={value.epoch_label}
                                               onBlur={this.onChange}/>
                                    </div>
                                </div>
                            </li>
                        ))}
                    </ul>
                    <button type="button" onClick={this.addBox} className="ki-btn"> + ADD epoch</button>
                    <button type="button" onClick={this.deleteBox(data.length -1)} className="ki-btn"> - REMOVE</button>
                </div>
            );
        }
        if (this.props.type === "arms"){
            return (
                <div className="form-question">
                    <ul id="arms_form">
                        <label>{title}</label>
                        <div className='caption-text'>{caption}</div>
                        {data.map((value, index) => (
                            <li key={index} className="form-repeat">
                                <div className="heading-3">Arm code</div>
                                <div className="caption-text">Enter the code for the intervention arm (e.g, 1,2 or A, B, etc.)</div>
                                <input type="text"
                                       className="arm_code"
                                       name={"arm_code"}
                                       defaultValue={value.arm_code}
                                       id={index}
                                       onBlur={this.onChange}
                                       maxLength="2"/>
                                <div className="heading-3">Arm description</div>
                                <Textarea name={"arm_description"}
                                          maxLength="1000"
                                          onBlur={this.onChange}
                                          id={index}
                                          className="arm_description"
                                          defaultValue={value.arm_description}/>
                                <div className="heading-3">Arm description - short</div>
                                <input type="text"
                                       name={"arm_description_short"}
                                       className="arm_description_short"
                                       maxLength="200"
                                       id={index}
                                       defaultValue={value.arm_description_short}
                                       onBlur={this.onChange}/>
                                <div className="heading-3">Number per arm</div>
                                <input type="number"
                                       id={index}
                                       name={"number_per_arm"}
                                       maxLength="200"
                                       className="number_per_arm"
                                       defaultValue={value.number_per_arm}
                                       onBlur={this.onChange}/>
                            </li>
                        ))}
                    </ul>
                    <button type="button" onClick={this.addBox} className="ki-btn"> + ADD arm</button>
                    <button type="button" onClick={this.deleteBox(data.length -1)} className="ki-btn"> - REMOVE</button>
                </div>
            );
        }
        if (this.props.type === "visits"){
            return (
                <div className="form-question">
                    <label>{title}</label>
                    <div className='caption-text'>{caption}</div>
                    <ul>
                        <li>
                            <div className="tc-table-row tc-table-header">
                                <div className="tc-col tc-col-1">Visit #</div>
                                <div className="tc-col tc-col-2">Visit Day</div>
                                <div className="tc-col tc-col-3">Visit Description</div>
                                <div className="tc-col tc-col-4">Visit Epoch Number</div>
                                <div className="tc-col tc-col-5"/>
                            </div>
                        </li>
                        {data.map((value, index) => (
                            <li className="tc-table" key={index}>
                                <input type="hidden" name="arm_epoch_id" value={value.id}/>
                                <div className="tc-table-row">
                                    <div className="tc-col tc-col-1">
                                        <input type="text"
                                               name={"visit_number"}
                                               defaultValue={value.visit_number}
                                               placeholder="None"/>
                                    </div>
                                    <div className="tc-col tc-col-2">
                                        <input type="text"
                                               name={"visit_day"}
                                               className="visit_day"
                                               maxLength="200"
                                               defaultValue={value.visit_day}
                                               placeholder="None"/>
                                    </div>
                                    <div className="tc-col tc-col-3">
                                        <input type="text"
                                               name={"visit_description"}
                                               className="visit_description"
                                               maxLength="40"
                                               defaultValue={value.visit_description}
                                               placeholder="Month 1"/>
                                    </div>
                                    <div className="tc-col tc-col-4">
                                        <Dropdown name={"visit_epoch"}
                                                  type={"visits"}
                                                  placeholder={"Select Epoch"}
                                                  data={epochs}
                                                  selected={value.visit_epoch} />
                                    </div>
                                    <div className="tc-col tc-col-5 tc-close-col close-icon" onClick={this.deleteBox(index)}>
                                        <svg version="1.1" x="0px" y="0px"
                                       viewBox="0 0 34 34">

                                      <g id="Layer_2">
                                          <circle className="st0" cx="17" cy="17.1" r="16.4"/>
                                      </g>
                                      <g id="Layer_1">
                                          <g id="Symbols">
                                              <g id="Content-Block_x2F_Actions" transform="translate(-179.000000, 0.000000)">
                                                  <g id="Icons_x2F_Remove" transform="translate(179.000000, 0.000000)">
                                                      <g id="_x2B_-Button">
                                                          <g id="Oval-2">
                                                              <path className="st1" d="M17,34C7.6,34,0,26.4,0,17S7.6,0,17,0s17,7.6,17,17S26.4,34,17,34z M17,1C8.2,1,1,8.2,1,17s7.2,16,16,16
                                                    s16-7.2,16-16S25.8,1,17,1z"/>
                                                          </g>

                                                          <g id="Group-2" transform="translate(17.500000, 17.500000) rotate(-45.000000) translate(-17.500000, -17.500000) translate(12.000000, 12.000000)">
                                                              <g id="Line-2">

                                                                  <rect x="-0.1" y="4.9" transform="matrix(0.7071 -0.7071 0.7071 0.7071 -2.1919 5.5012)" className="st1" width="11.3" height="1"/>
                                                              </g>
                                                              <g id="Line-2_1_">

                                                                  <rect x="5.4" y="-0.3" transform="matrix(0.7071 -0.7071 0.7071 0.7071 -2.0888 5.7499)" className="st1" width="1" height="11.4"/>
                                                              </g>
                                                          </g>
                                                      </g>
                                                  </g>
                                              </g>
                                          </g>
                                      </g>
                                      </svg>
                                    </div>
                                </div>
                            </li>
                        ))}
                        <div className="button-text-icon add-item icon-left icon-plus" onClick={this.addBox}>Add</div>
                    </ul>
                </div>
            );
        }
    }
}

export default VisitsEpochsArms;