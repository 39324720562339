import { hubApi } from '../util/api';

export const LOGGING_IN = 'LOGGING_IN';
export const LOGGING_IN_SUCCESS = 'LOGGING_IN_SUCCESS';
export const LOGGING_IN_FAIL = 'LOGGING_IN_FAIL';
export const CLEAR_ERROR = 'CLEAR_ERROR';
export const COMPLETE_AUTH_CHECK = 'COMPLETE_AUTH_CHECK';

export const fetchingUser = () => ({
  type: LOGGING_IN
});

export const receiveUser = (user, level) => ({
  type: LOGGING_IN_SUCCESS,
  user,
  level,
  receivedAt: Date.now()
});

export const fetchingFailed = error => ({
  type: LOGGING_IN_FAIL,
  error
});

export const clearError =() => ({
  type: CLEAR_ERROR
});

export const updateAuthStatus = () => ({
  type: COMPLETE_AUTH_CHECK
});

/*
 * Fetch user via login credentials
 * @param {object} credentials [Required]
 */
export const fetchUser = (username, password) => (dispatch) => {
  dispatch(fetchingUser());

  const endpoint = hubApi().endPoints.auth;

  if (username) {
    if (password) {
      dispatch(clearError());
      const credentials = {
        'user': username,
        'pass': password
      };

      hubApi().post(endpoint, credentials, true)
        .then((response) => {
          let userData = response.data.data;

          let authLevel = 0;
          switch(userData.role) {
            case 'super admin':
              authLevel = 3;
              break;
            case 'rally admin/master':
              authLevel = 2;
              break;
            case 'data admin':
              authLevel = 1;
              break;
            case 'rally participant':
              authLevel = -2;
              break;
            default:
              authLevel = 0;
          }

          dispatch(receiveUser(userData, authLevel));

          if (!userData.token) throw new Error('Token not delivered!');

          localStorage.setItem('token', userData.token);
          //dispatch(push('/home'));

        })
        .catch((error) => {
          dispatch(fetchingFailed('Invalid login'))
        })

    } else {
      dispatch(fetchingFailed('Password is required'))
    }
  } else {
    dispatch(fetchingFailed('Username is required'));
  }
};

/*
 * Fetch user via token
 * @param {string} token [Required]
 */
export const fetchUserViaToken = (token) => (dispatch, getState) => {
  dispatch(fetchingUser());

  // const state = getState();
  // const url = state.router.location.pathname;
  const endpoint = hubApi().endPoints.authToken + token;

  if (token) {
    hubApi().get(endpoint)
      .then((response) => {
        let userData = response.data.data;

        let authLevel = 0;
        switch(userData.role) {
          case 'super admin':
            authLevel = 3;
            break;
          case 'rally admin/master':
            authLevel = 2;
            break;
          case 'data admin':
            authLevel = 1;
            break;
          default:
            authLevel = 0;
        }

        dispatch(receiveUser(userData, authLevel));
      })
      .catch((error) => {
        console.log(error)
        dispatch(updateAuthStatus())
      })
  } else {
    dispatch(updateAuthStatus())
  }

};