import React, {Component} from 'react';

import Select from 'react-select';
import Textarea from 'react-textarea-autosize';
import ShortId from 'shortid';
import { WithContext as ReactTags } from 'react-tag-input';
import { Tag, Input, Tooltip, Icon, AutoComplete, Checkbox } from 'antd';
// import {formatDate,parseDate,} from 'react-day-picker/moment';
import 'react-day-picker/lib/style.css';

import { ReactComponent as Close } from '../../assets/images/close.svg';
import { ReactComponent as UpArrow } from '../../assets/images/upArrow.svg';
import { ReactComponent as DownArrow } from '../../assets/images/downArrow.svg';
import { ReactComponent as ArrowLine } from '../../assets/images/arrowLine.svg';

const KeyCodes = {
  comma: 188,
  enter: 13,
};
const delimiters = [KeyCodes.comma, KeyCodes.enter];



/*
 * Input component
 * @param {string} title
 * @param {string} caption
 * @param {string} caption2
 * @param {string} name [Required]
 * @param {string} type [Required]
 * @param {int} id
 * @param {function} updateValue(name, value) [Required]
 * @param {object} data [Required]
 * @param {string} placeholder
 * @param {string} maxlength
 * @param {bool} isRequired
 */
export class BasicInput extends Component {
  constructor(props) {
    super(props);
    this.state = { error: null }
  }

  componentDidCatch(error) { this.setState({ error }); }

  render() {
    if (this.state.error) return <p className="error">{this.state.error}</p>;
    return (
      <div className='view-element input-form'>
        <label>{this.props.title}</label>
        {this.props.caption ?
          <div className='caption-text'>
            <span>{this.props.caption}</span>
            {this.props.caption2 ? <span>{this.props.caption2}</span> : null}
          </div>
          :
          null
        }
        <input
          name={this.props.name}
          type={this.props.type}
          id={this.props.id}
          onBlur={(e) => this.props.updateValue(e.target.name, e.target.value)}
          defaultValue={this.props.data}
          placeholder={this.props.placeholder}
          maxLength={this.props.maxlength}
          autoComplete="off"
          required={this.props.isRequired}
        />
      </div>
    );
  }
}



/*
 * Textarea component
 * @param {int} id [Required]
 * @param {object} payload [Required]
 */
export class TextArea extends Component {
  constructor(props) {
    super(props);
    this.state = { error: null }
  }

  componentDidCatch(error) { this.setState({ error }); }

  render() {
    if (this.state.error) return <p className="error">{this.state.error}</p>;
    return (
      <div className='view-element input-form'>
        <label htmlFor={this.props.name}>{this.props.title}</label>
        <div className='caption-text'>
          <span>{this.props.caption}</span>
          {this.props.caption2 ? <span>{this.props.caption2}</span> : null}
        </div>
        <Textarea
          name={this.props.name}
          id={this.props.id}
          onBlur={(e) => this.props.updateValue(e.target.name, e.target.value)}
          defaultValue={this.props.data}
          maxLength={this.props.maxlength}
          rows="4"
          autoComplete="off"
        />
      </div>
    );
  }
}



/*
 * Dropdown component
 * @param {int} id [Required]
 * @param {object} payload [Required]
 */
export class Dropdown extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dropdown: {
        options: [],
        selected: [],
      },
      error: null
    }
  }

  formatSelected = (value) => {
    let selected = [];
    if (value) {
      let data = value;
      if (data === undefined || data === null) {
        selected = {value: "",label: ""};
      }
      if (data.id) {
        selected = {value: data.id, label: data.name}
      } else {
        if (data.constructor === Array) {
          data.forEach(d => {
            selected.push({value: d, label: d});
          });
        } else {
          selected = {value: data, label: data};
        }
      }
    }
    return selected;
  };

  handleChange = (e) => {
    if (this.props.isMulti) {
      let multiSelect = [];
      e.forEach((option) => {
        multiSelect.push(option.value);
      });
      this.props.updateValue(this.props.name, multiSelect.join("','"));
      this.setState({
        dropdown: {
          ...this.state.dropdown,
          selected: this.formatSelected(multiSelect)
        }
      })
    } else {
      this.props.updateValue(this.props.name, e ? e.value : '');
      this.setState({
        dropdown: {
          ...this.state.dropdown,
          selected: this.formatSelected(e ? e.value : '')
        }
      })
    }
  };

  componentDidMount() {
    let options = [];
    if (this.props.options) {
      this.props.options.forEach((value) => {
        if (value !== null) {
          if (value.id) {
            options.push({
              value: value,
              label: value.name
            })
          } else {
            value = value.toString().trim();
            options.push({
              value: value.toString().toLowerCase(),
              label: value.charAt(0) + value.slice(1)
            });
          }
        }
      });
    }
    let selected = this.formatSelected(this.props.selected);
    this.setState({dropdown: {options, selected}})
  }

  componentDidCatch(error) { this.setState({ error }); }

  render () {
    if (this.state.error) return <p className="error">{this.state.error}</p>;
    return (
      <div className={this.props.title ? "view-element" : "single-dropdown"}>
        {this.props.title ? <label htmlFor={this.props.name}>{this.props.title}</label> : null}
        {this.props.caption ? <div className='caption-text'>{this.props.caption}</div> : null}
        <Select
          options={this.state.dropdown.options}
          className={"dynamic-select-box"}
          placeholder={this.props.type}
          onChange={(e) => this.handleChange(e)}
          name={this.props.name}
          isMulti={this.props.isMulti}
          isClearable={this.props.isClearable}
          isSearchable={this.props.isSearchable}
          defaultValue={""}
          value={this.state.dropdown.selected}
        />
      </div>
    );
  }
}


/*
 * Import Rally component
 * @param {int} id [Required]
 * @param {object} payload [Required]
 */
export class ImportRally extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dropdown: {
        options: [],
        selected: [],
      },
      error: null
    }
  }

  formatSelected = (selected) => {
    if (!selected) return [];
    let selectedItem = [];
    const rally = this.props.options.find((r) => r.id === selected)
    if (rally) {
      let formattedLabel = rally.rally_number + rally.sprint_letter.toUpperCase() + " " + rally.sprint_title;
      selectedItem.push({
        label: formattedLabel,
        value: rally.id
      })
    }
    return selectedItem;
  };

  handleChange = (e) => {
    let selected = null;
    const rally = e ? this.props.options.find((r) => r.id === e.value) : null;
    if (rally) {
      selected = {
        rally,
        label: rally.rally_number + rally.sprint_letter.toUpperCase()
      }
    }
    this.props.updateValue(this.props.name, e ? e.value : '', selected);
    this.setState({
      dropdown: {
        ...this.state.dropdown,
        selected: this.formatSelected(e ? e.value : '')
      }
    })
  };

  componentDidMount() {
    let options = [];
    if (this.props.options) {
      this.props.options.forEach((rally) => {
        let formattedLabel = rally.rally_number + rally.sprint_letter + " " + rally.sprint_title;
        options.push({
          label: formattedLabel,
          value: rally.id
        })
      })
    }
    let selected = this.formatSelected(this.props.selected);
    this.setState({dropdown: {options, selected}})
  }

  componentDidCatch(error) { this.setState({ error }); }

  render () {
    if (this.state.error) return <p className="error">{this.state.error}</p>;
    return (
      <div className={this.props.title ? "view-element" : "single-dropdown"}>
        {this.props.title ? <label htmlFor={this.props.name}>{this.props.title}</label> : null}
        {this.props.caption ? <div className='caption-text'>{this.props.caption}</div> : null}
        <div className="flex">
          <Select
            options={this.state.dropdown.options}
            className={"dynamic-select-box import-rally"}
            placeholder={this.props.type}
            onChange={(e) => this.handleChange(e)}
            name={this.props.name}
            isClearable={this.props.isClearable}
            isSearchable={this.props.isSearchable}
            defaultValue={""}
            value={this.state.dropdown.selected}
          />
          <button
            disabled={!this.props.selected}
            onClick={this.props.btnAction}
            className="primary-btn rounded-btn">
            Import Data
          </button>
        </div>
      </div>
    );
  }
}

/*
 * Import Checkbox component
 */
export const ImportCheckbox = ({options, update}) => {
  return (
    <div className="checkbox-group">
      <Checkbox
        className="import-checkbox"
        checked={options.rally_title}
        onChange={() => update('rally_title', !options.rally_title)}
      >
        Rally Title
      </Checkbox>
      <Checkbox
        className="import-checkbox"
        checked={options.rally_number}
        onChange={() => update('rally_number', !options.rally_number)}
      >
        Rally Number
      </Checkbox>
      <Checkbox
        className="import-checkbox"
        checked={options.participants}
        onChange={() => update('participants', !options.participants)}
      >
        Participants
      </Checkbox>
      <Checkbox
        className="import-checkbox"
        checked={options.tags}
        onChange={() => update('tags', !options.tags)}
      >
        Tags
      </Checkbox>
      <Checkbox
        className="import-checkbox"
        checked={options.background}
        onChange={() => update('background', !options.background)}
      >
        Background
      </Checkbox>
      <Checkbox
        className="import-checkbox"
        checked={options.motivation}
        onChange={() => update('motivation', !options.motivation)}
      >
        Value Hypothesis
      </Checkbox>
      <Checkbox
        className="import-checkbox"
        checked={options.ghap_repo}
        onChange={() => update('ghap_repo', !options.ghap_repo)}
      >
        Analysis Git Repo
      </Checkbox>
    </div>
  )
};

/*
 * Tags component
 * @param {int} id [Required]
 * @param {object} payload [Required]
 */
export class Tags extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tags: {
        list: [],
        payload: [],
        suggestions: [],
      },
      active: [],
      inputVisible: false,
      inputValue: '',
      error: null,
    };
  }

  handleClose = (removedTag) => {
    const list = this.state.tags.list.filter(tag => tag !== removedTag);
    this.setState({ tags: {...this.state.tags, list} }, () => {
      this.props.updateValue(this.props.name, list)
    });
  };

  showInput = () => {
    this.setState({ inputVisible: true }, () => this.input.focus());
  };

  handleInputChange = (e) => {
    let active = [];
    this.setState({ inputValue: e });
    if (e.length > 2) {
      active = this.state.tags.suggestions.filter((sug) => {
        let query = new RegExp(e.toLowerCase(), 'i');
        return sug.toLowerCase().match(query);
      });
    }
    this.setState({ active })
  };

  handleInputConfirm = (e) => {
    const { inputValue } = this.state;
    let { list } = this.state.tags;
    if (e) {
      list = [...list, e];
    } else {
      if (inputValue && list.indexOf(inputValue) === -1) {
        list = [...list, inputValue];
      }
    }
    this.setState({
      tags: {
        ...this.state.tags,
        list
      },
      inputVisible: false,
      inputValue: '',
      active: []
    }, () => {
      this.props.updateValue(this.props.name, this.state.tags.list)
    });
  };

  saveInputRef = input => this.input = input;

  componentDidMount() {
    let list = [];
    let suggestions = [];
    if (this.props.data) {
      const data = this.props.data.split("','");
      data.forEach(function (value) {
        list.push(value);
      });
    }

    if (this.props.suggestions) {
      let suggs = this.props.suggestions;
      if (suggs.indexOf("','") !== -1) {
        suggs = suggs.split("','");
      }
      suggs.forEach(function (value) {
        if (value !== '') {
          suggestions.push(value);
        }
      });

    }

    this.setState({ tags: { ...this.state.tags, list, suggestions: [...new Set(suggestions)] } });
  }

  componentDidCatch(error) { this.setState({ error }); }

  render () {
    return (
      <div className='view-element input-form'>
        <label htmlFor={this.props.name}>{this.props.title}</label>
        <div className='caption-text'>
          <span>{this.props.caption}</span>
          {this.props.caption2 ? <span>{this.props.caption2}</span> : null}
        </div>

        <div>
          {this.state.tags.list.map((tag, index) => {
            const isLongTag = tag.length > 20;
            const tagElem = (
              <Tag key={tag} closable={true} onClose={() => this.handleClose(tag)}>
                {isLongTag ? `${tag.slice(0, 20)}...` : tag}
              </Tag>
            );
            return isLongTag ? <Tooltip title={tag} key={tag}>{tagElem}</Tooltip> : tagElem;
          })}
          {this.state.inputVisible && (
            <div onKeyPress={(e) => {if(e.key === 'Enter') this.handleInputConfirm()}} className="inline-block">
              <AutoComplete
                ref={this.saveInputRef}
                dataSource={this.state.active}
                style={{ width: 200 }}
                onChange={this.handleInputChange}
                onSelect={this.handleInputConfirm}
                onBlur={this.handleInputConfirm}
                placeholder="Search or free type"
                defaultActiveFirstOption={false}
                onKeyPress={(e) => {if(e.key === 'Enter') this.handleInputConfirm()}}
                filterOption={(inputValue, option) =>
                  option.props.children.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                }
              />
            </div>
          )}
          {!this.state.inputVisible && (
            <Tag
              onClick={this.showInput}
              style={{ background: '#fff', borderStyle: 'dashed' }}
            >
              <Icon type="plus" /> New Tag
            </Tag>
          )}
        </div>

      </div>
    );
  }
}




/*
 * Radio Select component
 * @param {int} id [Required]
 * @param {object} payload [Required]
 */
export class RadioSelect extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: 0,
      error: null
    }
  }

  componentDidCatch(error) { this.setState({ error }); }

  componentDidMount() {
    if (this.props.data) {
      this.setState({
        value: this.props.data
      })
    }
  }

  render() {
    if (this.state.error) return <p className="error">{this.state.error}</p>;
    return (
      <div className='view-element input-form'>
        <label>{this.props.title}</label>
        <div className='caption-text'>
          <span>{this.props.caption}</span>
          {this.props.caption2 ? <span>{this.props.caption2}</span> : null}
        </div>
        <label className="radio-inline">
          <input
            type='radio'
            className='radio_t_f'
            name={this.props.name}
            onChange={() => {
              this.setState({value: 1});
              this.props.updateValue(this.props.name, 1)
            }}
            checked={this.state.value === 1}
          />
          Yes
        </label>
        <label className="radio-inline">
          <input
            type='radio'
            className='radio_t_f'
            name={this.props.name}
            onChange={() => {
              this.setState({value: 0}, () => {
                this.props.updateValue(this.props.name, this.state.value)
              });
            }}
            checked={this.state.value === 0}
          />
          No
        </label>
      </div>
    );
  }
}



/*
 * Date Range component
 * @param {int} id [Required]
 * @param {object} payload [Required]
 */
export class DateRange extends Component {
  constructor(props) {
    super(props);
    this.state = { error: null }
  }

  handleValidation = (value, i) => {
    if (!value || value === '') {
      console.log(value);
      console.log('INVALID!')
      this.setState({ error: true })
    } else {
      if (this.props.data[0] !== '' && this.props.data[1] !== '') {
        this.setState({error: null});
        if (i === 0) this.props.updateValue(this.props.name, [value, this.props.data[1]]);
        else this.props.updateValue(this.props.name, [this.props.data[0], value]);
      }
    }
  };

  componentDidCatch(error) { this.setState({ error }); }

  render() {
    return (
      <div className='view-element input-form'>
        <label>{this.props.title}</label>
        {this.state.error ? <label className="error">Dates are required</label> : null}
        <div className="date-range">
          <div className="start">
            <div className='caption-text'>{this.props.caption1}</div>
            <input
              name={this.props.name}
              type={this.props.type}
              id={this.props.id}
              // onBlur={(e) => this.props.updateValue(e.target.name, [e.target.value, this.props.data[1]])}
              onBlur={(e) => this.handleValidation(e.target.value, 0)}
              defaultValue={this.props.data[0]}
              required={this.props.isRequired}
            />
          </div>
          <div className="end">
            <div className='caption-text'>{this.props.caption2}</div>
            <input
              name={this.props.name}
              type={this.props.type}
              id={this.props.id}
              //onBlur={(e) => this.props.updateValue(e.target.name, [this.props.data[0], e.target.value])}
              onBlur={(e) => this.handleValidation(e.target.value, 1)}
              defaultValue={this.props.data[1]}
              required={this.props.isRequired}
            />
          </div>
        </div>
      </div>
    );
  }
}



/*
 * Repeater component
 * @param {int} id [Required]
 * @param {object} payload [Required]
 */
export class Repeater extends Component {
  constructor(props) {
    super(props);
    this.state = {
      repeatData: [],
      repeatData2: [],
      error: null,
    };
  }

  addBox = () => {
    this.setState({repeatData: [...this.state.repeatData, '']});
  };

  deleteBox = (index) => {
    if (this.props.data2) {
      let repeatData = [...this.state.repeatData];
      repeatData.splice(index, 1);
      this.setState({repeatData}, () => {
        this.props.updateValue(this.props.name1, this.state.repeatData);
      });

      let repeatData2 = [...this.state.repeatData2];
      repeatData2.splice(index, 1);
      this.setState({repeatData2});
      this.props.updateValue(this.props.name2, repeatData2);

    } else {
      let repeatData = [...this.state.repeatData];
      repeatData.splice(index, 1);
      this.setState({repeatData});
      if (this.props.block === 'card') {
        this.props.updateValue(this.props.name, JSON.stringify(repeatData));
      } else {
        this.props.updateValue(this.props.name, repeatData);
      }
    }
  };

  moveItem = (from, to) => {
    let repeatData = [...this.state.repeatData];
    if (to >= 0 && to < repeatData.length) {
      let f = repeatData.splice(from, 1)[0];
      repeatData.splice(to, 0, f);
      this.setState({repeatData});
      if (this.props.block === 'card') {
        this.props.updateValue(this.props.name, JSON.stringify(repeatData));
      } else {
        this.props.updateValue(this.props.name, repeatData);
      }
    }
  };


  componentDidMount() {

    if (this.props.data.constructor === Array) {
      this.setState({repeatData: this.props.data})
    }
    if (this.props.data.length > 0) {
      if (this.props.data.constructor === String) {
        this.setState({repeatData: JSON.parse(this.props.data)})
      }
    }
    if (this.props.data2) {
      if (this.props.data2.constructor === Array) {
        this.setState({
          repeatData2: this.props.data2
        })
      }
    }

  }

  componentDidCatch(error) { this.setState({ error }); }

  render() {
    if (this.state.error) return <p className="error">{this.state.error}</p>;
    return (
      <div className='view-element input-form'>
        <label htmlFor={this.props.name}>{this.props.title}</label>
        <div className='caption-text'>
          <span>{this.props.caption}</span>
          {this.props.caption2 ? <span>{this.props.caption2}</span> : null}
        </div>
        <div className="data">
          <ul>
            {this.state.repeatData.map((value, index) => (
              <li className="repeater-field" key={value.id}>

                {this.props.block === 'input' ?
                  <input
                    type={this.props.type}
                    onBlur={(e) => {
                      let repeatData = [...this.state.repeatData];
                      repeatData[index] = e.target.value;
                      this.setState({repeatData});
                      this.props.updateValue(e.target.name, repeatData);
                    }}
                    id={'r-input-' + index}
                    name={this.props.name}
                    className="study-methods-reference"
                    placeholder={this.props.placeholder}
                    defaultValue={value}
                  />
                  :
                  null
                }

                {this.props.block === 'textarea' ?
                  <Textarea
                    name={this.props.name}
                    id={'r-textarea-' + index}
                    onBlur={(e) => {
                      let repeatData = [...this.state.repeatData];
                      repeatData[index] = e.target.value;
                      this.setState({repeatData});
                      this.props.updateValue(e.target.name, repeatData);
                    }}
                    defaultValue={value}
                    maxLength={this.props.maxlength}
                    autoComplete="off"
                  />
                  :
                  null
                }

                {this.props.block === 'card' ?
                  <div className="card repeater-card" id={'r-card-' + index}>
                    <div className="card-header">
                      <label>{this.props.title}</label>
                      <div onClick={() => this.deleteBox(index)} id={index} className="close-icn">
                        <Close />
                      </div>
                    </div>
                    <div className="card-content">
                      <form>
                        <Dropdown
                          updateValue={(name, value) => {
                            let repeatData = [...this.state.repeatData];
                            repeatData[index] = {ta: repeatData[index].ta, dd: value};
                            this.setState({repeatData}, () => {
                              this.props.updateValue(this.props.name, JSON.stringify(this.state.repeatData))
                            });
                          }}
                          name="card-dropdown"
                          key={value.id}
                          options={this.props.options}
                          selected={this.state.repeatData[index].dd}
                        />
                        <label>Explanation</label>
                        <Textarea
                          name={this.props.name}
                          id={'r-textarea-' + index}
                          onBlur={(e) => {
                            let repeatData = [...this.state.repeatData];
                            repeatData[index] = {ta: e.target.value, dd: repeatData[index].dd};
                            this.setState({repeatData}, () => {
                              this.props.updateValue(this.props.name, JSON.stringify(this.state.repeatData))
                            });
                          }}
                          defaultValue={value.ta}
                          maxLength={this.props.maxlength}
                          autoComplete="off"
                        />

                      </form>
                    </div>

                  </div>
                  :
                  null
                }


                {this.props.block === 'multi-dropdown' ?
                  <div className="two-dropdown">
                    <Dropdown
                      updateValue={(name, value) => {
                        let repeatData = [...this.state.repeatData];
                        repeatData[index] = value;
                        this.setState({repeatData});
                        this.props.updateValue(this.props.name1, repeatData)
                      }}
                      name="card-dropdown"
                      options={this.props.dropdown1}
                      selected={this.state.repeatData[index]}
                      key={value.id}
                    />
                    <Dropdown
                      updateValue={(name, value) => {
                        let repeatData2 = [...this.state.repeatData2];
                        repeatData2[index] = value;
                        this.setState({repeatData2});
                        this.props.updateValue(this.props.name2, repeatData2)
                      }}
                      name="card-dropdown"
                      options={this.props.dropdown2}
                      selected={this.state.repeatData2[index]}
                      key={value.id}
                    />
                  </div>
                  :
                  null
                }

                {this.state.repeatData.length > 1 && this.props.block !== 'multi-dropdown' ?
                  <div className="position-arrow">
                    <UpArrow
                      className={index === 0 ? 'inactive' : 'active'}
                      onClick={() => this.moveItem(index, index - 1)}
                    />
                    <ArrowLine/>
                    <DownArrow
                      className={index === this.state.repeatData.length - 1 ? 'inactive' : 'active'}
                      onClick={() => this.moveItem(index, index + 1)}
                    />
                  </div>
                  :
                  null
                }


                {this.props.block !== 'card' ?
                  <div onClick={() => this.deleteBox(index)} id={index} className="close-icn">
                    <Close />
                  </div>
                :
                  null
                }
              </li>
            ))}
          </ul>
        </div>
        <div
          className="button-text-icon add-item icon-left icon-plus"
          onClick={() => this.addBox()}
        >
          Add
        </div>
      </div>
    );
  }
}
