import {
  LOADING_STUDIES,
  STUDIES_SUCCESS,
  STUDIES_FAIL
} from "../actions/studies";

const initialState = {
  loading: false,
  loaded: false,
  studies: [],
  error: null,
};

export default function studyReducer(state = {...initialState}, action) {
  switch (action.type) {
    case LOADING_STUDIES:
      return {
        ...state,
        loading: true,
      };
    case STUDIES_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        studies: action.studies,
        error: null,
      };
    case STUDIES_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    default:
      return state;
  }
};